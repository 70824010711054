/**
 * Mock-серии игры "Сокровища судьбы"
 */
export const TOD_SERIES = [{
	code: 39,
	letters: 'А',
	default: false,
	params: '{"w":5,"h":5,"n":0,"k":10,"o":[10,10,9,9,8,8]}',
	koefs: ['1500.00', '200.00', '20.00', '5.00', '2.00', '1.25'],
	is_demo: false
}, {
	code: 601,
	letters: 'Б',
	default: true,
	params: '{"w":17,"h":1,"n":1,"k":7,"o":[7,7,6,6,5,5]}',
	koefs: ['1000.00', '150.00', '23.00', '10.00', '4.00', '1.50'],
	is_demo: false
}];
