import { Action } from '@app/core/services/network/enums/action.enum';
import { environment } from '@app/environments/environment';

/**
 * Mock-ответ на запрос версии
 */
export const Version = {
	request: {
		action: Action.Version
	},
	response: {
		action: Action.Version,
			R: 0,
			version: environment.version
	}
};
