import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { StoreService } from '@app/core/services/store/store.service';

/**
 * Компонент-обертка над таблицами ставок в мобильном разрешении
 */
@Component({
	selector: 'app-win-table-mobile-wrapper',
	templateUrl: './win-table-mobile-wrapper.component.html',
	styleUrls: ['./win-table-mobile-wrapper.component.scss']
})
export class WinTableMobileWrapperComponent implements OnInit, OnDestroy {

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная для уничтожения всех подписок
	 */
	private readonly unsubscribe$$ = new Subject<never>();

	/**
	 * Конструктор компонента
	 *
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 */
	constructor(
		readonly storeService: StoreService
	) {}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.storeService.isTabletAndDesktop$
			.pipe(
				filter(f => !!f),
				takeUntil(this.unsubscribe$$)
			)
			.subscribe(() => {
				this.storeService.isWinTableVisible$$.next(false);
			});
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}
}
