import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { IFrameEventsService } from '@app/core/services/external/iframe/iframe-events.service';
import { StoreService } from '@app/core/services/store/store.service';
import { AbstractDialogComponent } from '@dialogs/components/abstract-dialog.component';
import { DIALOG_DATA_TOKEN } from '@dialogs/constants/game-dialog-constants.service';
import { IDialogConfig } from '@dialogs/interfaces/idialog-config.service';

/**
 * Диалог для отображения информационных сообщений.
 */
@Component({
	selector: 'app-info-dialog',
	templateUrl: './info-dialog.component.html',
	styleUrls: ['./info-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoDialogComponent extends AbstractDialogComponent {

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {IDialogConfig} dialogConfig Конфигурация диалога
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 * @param iframeEventsService Сервис взаимодействия с сайтом через iframe
	 */
	constructor(
		@Inject(DIALOG_DATA_TOKEN)
		readonly dialogConfig: IDialogConfig,
		protected storeService: StoreService,
		readonly iframeEventsService: IFrameEventsService
	) {
		super(iframeEventsService);
	}
}
