import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Inject,
	OnInit,
	ViewChild
} from '@angular/core';
import { TOOLTIP_DATA_TOKEN } from '@toolTip/constants/tool-tip-constants';
import { TooltipPosition } from '@toolTip/enums/tool-tip-position';
import { ITooltipConfig } from '@toolTip/interfaces/itool-tip-config';

/**
 * Компонент всплывающей подсказки
 */
@Component({
	selector: 'app-tool-tip',
	templateUrl: './tool-tip.component.html',
	styleUrls: ['./tool-tip.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolTipComponent implements OnInit {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Ссылка на контейнер.
	 */
	@ViewChild('tooltipWrapper', { static: true })
	tooltipWrapper: ElementRef;

	/**
	 * Класс для стилизации подсказки
	 */
	styleClass: string;

	/**
	 * Текст, отображаемый внутри tooltip.
	 */
	text: string;

	/**
	 * Расположение tooltip относительно хост-элемента.
	 */
	tooltipPosition: TooltipPosition;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {ITooltipConfig} data Конфигурация подсказки
	 * @param {ElementRef} el Ссылка на собственный DOM-элемент
	 */
	constructor(
		@Inject(TOOLTIP_DATA_TOKEN)
		private readonly data: ITooltipConfig,
		private readonly el: ElementRef
	) {}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------

	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.text = this.data.text;
		this.tooltipPosition = this.data.tooltipPosition;
		this.styleClass = this.data.styleClass;
	}
}
