<div class="win-table"
	 data-cy="win-table"
>
	<div class="win-table__row win-table__row_header win-table__row_live"
		 data-cy="win-table-row-header"
	>
		<ng-container *ngIf="winFeedType === WinFeedType.Promo">
			<div class="win-table__cell win-table__cell_place"
				 data-cy="win-table-cell-place"
			>{{ 'win-feed.place' | translate }}</div>
			<div class="win-table__cell win-table__cell_player"
				 data-cy="win-table-cell-player"
			>{{ 'win-feed.player2' | translate }}</div>
		</ng-container>
		<ng-container *ngIf="winFeedType !== WinFeedType.Promo">
			<div class="win-table__cell win-table__cell_game"
				 [innerHTML]="'win-feed.game' | translate"
				 data-cy="win-table-cell-game"
			></div>
	
			<div *ngIf="winFeedType !== WinFeedType.My"
				 class="win-table__cell win-table__cell_player"
				 [innerHTML]="'win-feed.player' | translate"
				 data-cy="win-table-cell-player"
			></div>			
		</ng-container>

		<ng-container *ngIf="winFeedType === WinFeedType.Promo">
			<div class="win-table__cell table-cell win-table__cell_score"
				 data-cy="win-table-cell-score"
			>
				{{ 'win-feed.your' | translate }}
				<span class="star-pic table-cell__star"
					  data-cy="win-table-cell-star"
				></span>
			</div>
		</ng-container>
		
		<ng-container *ngIf="winFeedType !== WinFeedType.Promo">
			<div class="win-table__cell win-table__cell_time"
				 [innerHTML]="'win-feed.time' | translate"
				 data-cy="win-table-cell-time"
			></div>
			<div class="win-table__cell win-table__cell_bet"
				 [innerHTML]="'win-feed.bet' | translate"
				 data-cy="win-table-cell-bet"
			></div>

			<div class="win-table__cell win-table__cell_odds"
				 [innerHTML]="'win-feed.odds' | translate"
				 data-cy="win-feed-cell-odds"
			></div>
			<div class="win-table__cell win-table__cell_win"
				 [innerHTML]="'win-feed.win' | translate"
				 data-cy="win-feed-cell-win"
			></div>
		</ng-container>
	</div>
	
	<!-- ТАБЛИЦА -->
	<div class="win-table-wrap"
		 data-cy="win-table-wrap"
	>
		<div *ngIf="winFeedType === WinFeedType.Promo"
			 class="win-table-content win-table-content_promo">
			<!-- ЛИНК -->
			<div *ngIf="!storeService.sid" class="win-table__row win-table__row_link">
				<a class="win-table__link"
				   [innerHTML]="'actions.code-3-rules' | translate"
				   (click)="onLinkClickHandler()"
				></a>
			</div>

			<!-- СТРОКА С ДАННЫМИ -->
			<ng-container *ngFor="let row of winTableService.promoTable; let i = index; trackBy: trackByFeedFn">
				<div *ngIf="row"
					 class="win-table__row win-table__row_{{ row.league }}"
					 [class.win-table__row_self]="storeService.sid && row.isSelf && i === 0"
				>
					<div class="win-table__cell win-table__cell_place">{{ row.stars ? row.place : '-' }} </div>

					<div class="win-table__cell win-table__cell_player">{{ row.login || '*****' }}</div>

					<div class="win-table__cell win-table__cell_score">
						<span class="score-text">{{ row.stars }}</span>
						<span class="score-pic star-pic star-pic_{{ row.league }}{{ storeService.sid && row.isSelf && i === 0 ? '-stroke' : '' }}"></span>
					</div>
				</div>
			</ng-container>
		</div>
		<div *ngIf="winFeedType !== WinFeedType.Promo"
			 class="win-table-content win-table-content_{{channel}}"
			 [class.win-table-content_animated]="isAnimated"
			 data-cy="win-table-content"
		>
				<ng-container *ngFor="let row of winTableService.winFeedTable[channel]; let i = index; trackBy: trackByFeedFn">
					<div class="win-table__row win-table__row_{{channel}}"
						 [class.win-table__row_gray]="row.gray"
						 [class.win-table__row_animated]="row.animated"
						 data-cy="win-table-row"
					>
						<!-- НАЗВАНИЕ ИГРЫ -->
						<div class="win-table__cell win-table__cell_game"
							 [innerHTML]="('common.game-' + row?.gameCode) | translate"
							 data-cy="win-table-cell-game"
						></div>
	
						<!-- УЧАСТНИК -->
						<div class="win-table__cell win-table__cell_player"
							 *ngIf="channel !== Channel.WinFeedMy && !!row"
							 [innerHTML]="row?.userName || '*****'"
							 data-cy="win-table-cell-player"
						></div>
	
						<ng-container *ngIf="(storeService.isMobileScreen$ | async) === false">
							<!-- ВРЕМЯ -->
							<div class="win-table__cell win-table__cell_time"
								 [innerHTML]="row?.timeStamp | date:'HH:mm:ss'"
								 data-cy="win-table-cell-time"
							></div>
	
							<!-- СУММА СТАВКИ -->
							<div class="win-table__cell win-table__cell_bet"
								 [innerHTML]="row?.betAmount / 100 | currency:'UAH':'symbol-narrow':null:'uk'"
								 data-cy="win-table-cell-bet"
							></div>
						</ng-container>
	
						<!-- КОЭФФИЦИЕНТ -->
						<div class="win-table__cell win-table__cell_odds"
							 [class.win-table__cell_green]="channel === Channel.WinFeedAll && row?.winOdds >= 10"
							 [innerHTML]="row?.winOdds | number:'.2-2'"
							 data-cy="win-table-cell-odds"
						></div>
	
						<!-- СУММА ВЫИГРЫША -->
						<div
							class="win-table__cell win-table__cell_win"
							[class.win-table__cell_green]="channel === Channel.WinFeedAll && row?.winAmount >= 100000"
							[innerHTML]="row?.winAmount / 100 | currency:'UAH':'symbol-narrow':null:'uk'"
							data-cy="win-table-cell-win"
						></div>
					</div>
				</ng-container>
		</div>
	</div>
</div>
