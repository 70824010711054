import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';

import { ErrorDialogComponent } from '@dialogs/components/error-dialog/error-dialog.component';
import { InfoDialogComponent } from '@dialogs/components/info-dialog/info-dialog.component';
import { GameDialogsService } from '@dialogs/services/game-dialogs.service';

/**
 * Модуль диалогов: ошибки, предупреждения, информация и т.д.
 */
@NgModule({
	imports: [
		CommonModule,
		SharedModule
	],
	declarations: [
		ErrorDialogComponent,
		InfoDialogComponent
	],
	providers: [
		GameDialogsService
	]
})
export class GameDialogsModule {}
