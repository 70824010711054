/**
 * Объект конфигурации для mock-окружения и окружения unit-тестов
 */
export const environment = {

	/**
	 * Признак продуктивной сборки.
	 */
	production: true,

	/**
	 * Версия системы.
	 */
	version: '2.12.11-DEV',

	/**
	 * Признак приложения, запускаемого из WebView мобильного приложения (true).
	 * В противном случае, приложение запущено из iframe или самостоятельно.
	 */
	isMobile: false,

	/**
	 * Режим отладки. Выводит логи в консоль.
	 */
	debug: true,
	
	/**
	 * Ссылка на подключение к общему сервису регистрации игр.
	 */
	// registryServiceUrl: 'wss://dev.msl.emict.net/ws-fg/',
	// registryServiceUrl: 'wss://test.msl.emict.net/ws-fg/',
	registryServiceUrl: 'wss://msl.ua/ws-fg-mines/',

	/**
	 * Ссылка на страницу "Правила игры Сапер".
	 */
	urlRulesMines: 'https://dev.msl.emict.net/fast/ru/static/game-mines-rule',

	/**
	 * Ссылка на страницу "Правила игры Башня".
	 */
	urlRulesTower: 'https://dev.msl.emict.net/fast/ru/static/game-tower-rule',

	/**
	 * Ссылка на страницу "Правила игры Смайл".
	 */
	urlRulesSmile: 'https://dev.msl.emict.net/fast/ru/static/game-smile-rule',

	/**
	 * Ссылка на страницу "Правила игры Crash".
	 */
	urlRulesCrash: 'https://dev.msl.emict.net/fast/ru/static/game-crash-rule',

	/**
	 * Ссылка на страницу "Правила игры Сокровища судьбы".
	 */
	urlRulesTod: 'https://dev.msl.emict.net/fast/ru/static/game-gems-rule',

	/**
	 * Ссылка на страницу "Правила игры Бухта".
	 */
	urlRulesBuhta: 'https://dev.msl.emict.net/fast/ru/static/game-bukhta-rule',

	/**
	 * Ссылка на страницу "Правила игры Кальмар".
	 */
	urlRulesSquid: 'https://dev.msl.emict.net/fast/ru/static/game-squid-rule',

	/**
	 * Ссылка на страницу "Правила игры Цвет".
	 */
	urlRulesColor: 'https://dev.msl.emict.net/fast/ru/static/game-color-rule',

	/**
	 * Ссылка на страницу "Условия проведения игры".
	 */
	urlTermsAndConditions: 'https://dev.msl.emict.net/fast/ru/static/fastgames-umovy',

	/**
	 * Ссылка на страницу с большим выигрышем.
	 */
	urlBigWin: 'https://dev.msl.emict.net/profile/uk/bigwin?macCode=',

	/**
	 * Интервал опроса для определения времени ответа от сервера,
	 */
	pingRequestInterval: 1000,

	/**
	 * Пороговое значение, по которому определяется "плохое" время ответа.
	 */
	pingThreshold: 400,

	/**
	 * Размер буфера с последними данным по анализу времени отклика.
	 */
	pingBuffer: 10,

	/**
	 * Количество замеров, превышающих пороговое значение.
	 */
	countBadPings: 5,

	/**
	 * Демо-баланс
	 */
	demoBalance: 100000,

	/**
	 * Использовать ли mock-данные?
	 */
	mocks: true,

	/**
	 * Ссылка на кабинет пользователя
	 */
	cabinet: 'https://dev.msl.emict.net/profile/uk/common',

	/**
	 * Ссылка на страницу с перечнем ставок
	 */
	bets: 'https://dev.msl.emict.net/profile/uk/bets#fast',

	/**
	 * Ссылка на тех. поддержку
	 */
	techSupport: 'https://dev.msl.emict.net/profile/uk/message',

	/**
	 * Ссылка на страницу пополнения баланса
	 */
	fillBalanceLink: 'https://dev.msl.emict.net/profile/uk/deposit'
};
