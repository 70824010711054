import { OverlayRef } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';
import { ITooltipConfig } from '@toolTip/interfaces/itool-tip-config';

/**
 * Интерфейс данных с конфигурацией всплывающей подсказки
 */
export interface ITooltipTokenData {
	/**
	 * Конфигурация всплывающей подсказки
	 */
	data: ITooltipConfig;
}

/**
 * Токен с конфигурацией всплывающей подсказки
 */
export const TOOLTIP_DATA_TOKEN: InjectionToken<ITooltipTokenData> = new InjectionToken<ITooltipTokenData>(
	'TOOLTIP_DATA_TOKEN'
);

/**
 * Класс оверлея для всплывающей подсказки
 */
export class TooltipOverlayRef {

	/**
	 * Конструктор класса
	 *
	 * @param {OverlayRef} overlayRef Ссылка на оверлей для всплывающей подсказки
	 */
	constructor(
		private readonly overlayRef: OverlayRef
	) {}

	/**
	 * Скрыть всплывающую подсказку
	 */
	close(): void {
		this.overlayRef.dispose();
	}
}
