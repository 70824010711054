<button
	[ngClass]="iconType"
	[attr.disabled]="disabled || undefined"
	(click)="onClickHandler()"
	data-cy="plus-minus-button"
	>
	<span
		*ngIf="iconType === PlusMinusIconType.Minus; else plusButtonContainer"
		[innerHTML]="'-'"
		[ngClass]="iconType"
		data-cy="minus-button"
	></span>
	<ng-template #plusButtonContainer>
		<span [innerHTML]="'+'"
			  data-cy="plus-button"
		></span>
	</ng-template>
</button>
