<div data-cy="dialog-inner">
	<label
		class="info-message"
		[innerHTML]="dialogConfig?.message | translate"
		data-cy="info-message"
	></label>
	
	<button data-cy="continue-button"
		(click)="onCloseButtonClickHandler()"
		>
		{{ 'dialogs.continue' | translate }}
	</button>
</div>
