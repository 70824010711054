import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationEnd, NavigationStart, ResolveEnd, Router, RoutesRecognized, UrlTree } from '@angular/router';

import { Subject, Subscription, timer } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';

import { TranslateService } from '@ngx-translate/core';

import { ExternalCommunicationService } from '@app/core/services/external/external-communication.service';
import { MobileService } from '@app/core/services/external/mobile/mobile.service';
import { Logger, LogService } from '@app/core/services/log/log.service';
import { Languages, StoreService } from '@app/core/services/store/store.service';

import { MiniGamesCodes, MiniGamesUrls } from '@app/core/enums/mini-games-list.enum';
import { environment } from '@app/environments/environment';
import { ModuleRouteId } from '@app/utils/route-utils';
import { adjustIFrameToContent, getRandomString } from '@app/utils/utils';
import { WinTableDesktopWrapperComponent } from '@winTable/components/win-table-desktop-wrapper/win-table-desktop-wrapper.component';
import { WinTableService } from '@winTable/services/win-table.service';
import { CookieService } from 'ngx-cookie-service';

/**
 * Тег для логирования
 */
const TAG = 'AppComponent';

/**
 * Интерфейс DOM-окна, дополненный методами с сайта
 */
interface Window2 extends Window {
	/**
	 * Устанавливает последний посещенный маршрут в приложении
	 * @param url Маршрут в приложении
	 */
	setLastVisitedURL(url: string): void;
}

/**
 * Корневой компонент приложения
 */
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Ссылка на обертку над таблицами ставок
	 */
	@ViewChild(WinTableDesktopWrapperComponent, { static: true })
	winTableElementRef: WinTableDesktopWrapperComponent;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная для уничтожения всех подписок
	 */
	private readonly unsubscribe$$ = new Subject<never>();

	/**
	 * Модуль, в котором находимся сейчас
	 */
	currentModuleId: ModuleRouteId;

	/**
	 * Предыдущий модуль
	 */
	previousModuleId: ModuleRouteId;

	/**
	 * Подписка на таймер ожидания ресайза родительского окна
	 */
	waitToResize: Subscription;

	/**
	 * Видимая ли таблица ставок на странице?
	 */
	isWinTableVisibleOnPage = false;

	/**
	 * Список URL'ов к модулям игр
	 */
	private readonly games = Object.keys(MiniGamesUrls)
		.filter(k => !(parseInt(k, 10) >= 0))
		.map(key => MiniGamesUrls[key]);

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {LogService} logService Сервис логирования
	 * @param {TranslateService} translateService Сервис переводов
	 * @param {Router} router Объект класса внутренней маршрутизации приложения
	 * @param {Location} location Браузерный объект нахождения на каком-либо URL'е
	 * @param {ExternalCommunicationService} externalCommunicationService Сервис взаимодействия с внешним окном или WebView
	 * @param {MobileService} mobileService Сервис по взаимодействию с мобильным приложением
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 * @param cookieService Сервис по работе с куками
	 * @param winTableService Сервис таблиц выигрыша
	 */
	constructor(
		private readonly logService: LogService,
		private readonly translateService: TranslateService,
		private readonly router: Router,
		private readonly location: Location,
		private readonly externalCommunicationService: ExternalCommunicationService,
		private readonly mobileService: MobileService,
		readonly storeService: StoreService,
		private readonly cookieService: CookieService,
		private readonly winTableService: WinTableService
	) {}

	/**
	 * Обработчик событий маршрутизации в приложении
	 * @param data Данные о маршрутизации
	 * @private
	 */
	private routerEventsAction(data: NavigationEnd | RoutesRecognized | NavigationStart | ResolveEnd): void {
		if (data instanceof NavigationStart) {
			this.currentModuleId = undefined;
		}

		if (data instanceof RoutesRecognized) {
			this.currentModuleId = data.state.root.firstChild.data as ModuleRouteId;
		}

		if (data instanceof NavigationEnd) {
			this.externalCommunicationService.changeLocation(this.previousModuleId, this.currentModuleId);
			this.previousModuleId = this.currentModuleId;
			this.isWinTableVisibleOnPage = !!this.games?.includes(this.currentModuleId?.moduleUrl);
			if (window.parent && (typeof (window.parent as Window2).setLastVisitedURL !== 'undefined')) {
				const cleanRoute = this.router.url.charAt(0) === '/' ? this.router.url.substr(1) : this.router.url;
				const qPos = cleanRoute.indexOf('?');
				const cleanRoute2 =  qPos > -1 ? cleanRoute.substr(0, qPos) : cleanRoute;
				(window.parent as Window2).setLastVisitedURL(cleanRoute2);
			}
		}
	}

	/**
	 * Метод для инициализации всего, чего нет в ngOnInit
	 * @private
	 */
	private appOtherInit(): void {
		const demoModes = this.storeService.demoMode$$.value;
		demoModes[MiniGamesCodes.Smile] = !this.storeService.sid;
		this.storeService.demoMode$$.next(demoModes);

		//
		this.router.events
			.pipe(
				filter(event => (event instanceof NavigationEnd) || (event instanceof RoutesRecognized)),
				takeUntil(this.unsubscribe$$)
			)
			.subscribe(this.routerEventsAction.bind(this));

		for (const gameUrl of this.games) {
			if (this.router.routerState.snapshot.url.includes(gameUrl)) {
				this.isWinTableVisibleOnPage = true;
				break;
			}
		}

		// подписаться на клик на кнопке LIVE
		this.storeService.scrollToWinTable$
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe(() => {
				Logger.Log.d(TAG, `ngOnInit => activated scroll to WIN TABLE`)
					.console();

				this.winTableElementRef.elementRef.nativeElement.scrollIntoView({ behavior: 'smooth' });
			});
		this.winTableService.initOtherFeeds();
	}

	/**
	 * Обработчик, вызываемый при изменении размера родительского окна
	 * @private
	 */
	private resizeHandler(): void {
		const anchor = window.parent.document.getElementById('fast-frame') as HTMLIFrameElement;
		if (anchor) {
			if (this.waitToResize) {
				this.waitToResize.unsubscribe();
				this.waitToResize = undefined;
			}
			this.waitToResize = timer(500)
				.subscribe(() => {
					anchor.style.height = `${anchor.contentWindow.document.getElementsByTagName('app-root')[0]?.scrollHeight + 20}px`;
					anchor.style.overflow = 'hidden';
					this.waitToResize.unsubscribe();
					this.waitToResize = undefined;
				});
		}
	}
	
	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		Logger.Log.i(TAG, `ngOnInit => Application started, parse URL parameters...`)
			.console();

		this.storeService.sid = undefined;

		const languages = Object.values(Languages);
		this.translateService.addLangs(languages);
		this.translateService.defaultLang = Languages.Ua;

		const thisIframe = parent?.document.getElementById('fast-frame') as HTMLIFrameElement;
		let parsedUrl: UrlTree;
		if (thisIframe) {
			const urlObj = new URL(thisIframe.src);
			parsedUrl = this.router.parseUrl(`${urlObj.pathname}${urlObj.search}`);
		} else {
			parsedUrl = this.router.parseUrl(this.location.path());
		}

		// проверить признак нативного приложения с мобильного телефона
		if (parsedUrl.queryParamMap.has('mobile') && parsedUrl.queryParamMap.get('mobile')) {
			Logger.Log.d(TAG, `ngOnInit => it's MOBILE phone application`)
				.console();

			environment.isMobile = true;
			
			if (parsedUrl.queryParamMap.has('term_code')) {
				this.storeService.termCode = +parsedUrl.queryParamMap.get('term_code');
			}
			
			this.storeService.sid = this.mobileService.getAuthToken();
		} else {
			Logger.Log.d(TAG, `ngOnInit => it's DESKTOP application`)
				.console();

			environment.isMobile = false;
		}

		// использовать язык из URL
		if (parsedUrl.queryParamMap.has('lang')) {
			let lang = parsedUrl.queryParamMap
				.get('lang')
				.toLowerCase();

			// на сайте в качестве локального "UK" - меняем на "UA"
			if (lang === 'uk') {
				lang = Languages.Ua;
			}

			this.translateService.use(lang.toLowerCase());
		} else {
			this.translateService.use(Languages.Ua);
		}

		Logger.Log.d(TAG, `ngOnInit => will use language: [${this.translateService.currentLang}]`)
			.console();

		// проверить токен в URL
		const token = parsedUrl.queryParamMap.has('token') ?
			parsedUrl.queryParamMap.get('token') : environment.mocks ? 'mock_sid' : '';
		if (token) {
			Logger.Log.d(TAG, `ngOnInit => found user token inside URL: [${token}]`)
				.console();
			this.storeService.sid = token;

			Logger.Log.d(TAG, `ngOnInit => used token: [${this.storeService.sid}]`)
				.console();
			this.appOtherInit();
		} else {
			let _ga = this.cookieService.get('_ga');
			if (!!_ga) {
				this.storeService.demoSID = `$demo|${_ga}`;
				Logger.Log.d(TAG, `ngOnInit => used token: [${this.storeService.demoSID}]`)
					.console();
				this.appOtherInit();
			} else {
				const tmr = timer(1000)
					.subscribe(() => {
						tmr.unsubscribe();
						_ga = this.cookieService.get('_ga');
						if (!_ga) {
							let frontUID = localStorage.getItem('front_uid');
							if (!frontUID) {
								frontUID = getRandomString(32);
								localStorage.setItem('front_uid', frontUID);
							}
							this.storeService.demoSID = `$demo|${frontUID}`;
						} else {
							this.storeService.demoSID = `$demo|${_ga}`;
						}
						Logger.Log.d(TAG, `ngOnInit => used token: [${this.storeService.demoSID}]`)
							.console();
						this.appOtherInit();
					});
			}
		}

		if (window.parent) {
			window.parent.onresize = this.resizeHandler.bind(this);
		}
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

}
