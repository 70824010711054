import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Logger } from '@app/core/services/log/log.service';
import { TranslateService } from '@ngx-translate/core';

/**
 * Ориентация ссылок, которые ведут на правила и условия игры
 */
export enum InfoLinksOrientation {
	/**
	 * Горизонтальная ориентация
	 */
	Horizontal  = 'horizontal',
	/**
	 * Вертикальная ориентация
	 */
	Vertical    = 'vertical'
}

/**
 * Действие по клику на ссылку
 */
export enum LinkAction {
	/**
	 * Переход на правила
	 */
	Rules       = 'rules',
	/**
	 * Переход на условия
	 */
	Terms       = 'terms',
	/**
	 * Переход на статистику
	 */
	Statistics  = 'statistics'
}

/**
 * Интерфейс ccылки на правила или условия игры
 */
export interface IInfoLinkItem {
	/**
	 * Текст ссылки
	 */
	label: string;
	/**
	 * URL иконки
	 */
	iconUrl?: string;
	/**
	 * Видимые ли иконки
	 */
	isVisibleIcons: boolean;
	/**
	 * Действие при переходе (куда переходить)
	 */
	action: LinkAction;
	/**
	 * URL для перехода
	 */
	url?: string;
	/**
	 * Скрыть ли ссылку?
	 */
	hide?: boolean;
}

/**
 * Тег для логирования
 */
const TAG = 'InfoLinksComponent';

/**
 * Компонент со списком ссылок на информационные ресурсы.
 */
@Component({
	selector: 'app-info-links',
	templateUrl: './info-links.component.html',
	styleUrls: ['./info-links.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoLinksComponent {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Ориентация списка.
	 */
	@Input()
	orientation: InfoLinksOrientation = InfoLinksOrientation.Horizontal;

	/**
	 * Признак видимости иконок в списке.
	 */
	@Input()
	isVisibleIcons = true;

	/**
	 * Список ссылок.
	 */
	@Input()
	linkList: Array<IInfoLinkItem> = [];

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие клика по выбранной ссылке.
	 */
	@Output()
	readonly linkClick: EventEmitter<IInfoLinkItem> = new EventEmitter<IInfoLinkItem>();

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Вспомогательная функция для ускорения вывода информации в шаблон
	 * @param index Индекс элемента
	 * @param item Элемент
	 */
	readonly trackByLinkItems = (index, item: IInfoLinkItem) => item.label;

	/**
	 * Конструктор компонента
	 * @param translateService Сервис переводов
	 */
	constructor(private readonly translateService: TranslateService) {
	}

	/**
	 * Обработчик клика на контейнере.
	 *
	 * @param {MouseEvent} event Передаваемое событие
	 */
	onClickLinkItemHandler(event: MouseEvent): void {
		Logger.Log.i(TAG, `onClickLinkItemHandler`)
			.console();

		const index = +(event.target as HTMLElement).getAttribute('index');
		if (Number.isInteger(index)) {
			const action = this.linkList[index];
			let correctURL = action.url;
			if (this.translateService.currentLang === 'ua') {
				correctURL = correctURL.replace('/ru/', '/uk/');
			}
			window.open(correctURL, '_parent ');
			this.linkClick.emit(action);
		}
	}
}
