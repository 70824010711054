/**
 * Mock-серии игры "Бухта"
 */
export const BUHTA_SERIES = [
	{
		"code": 501,
		"letters": "А",
		"default": true,
		"params": "{\"w\":15,\"h\":1,\"b\":4,\"s\":0}",
		"koefs": [
			"1.03",
			"1.45",
			"2.10",
			"3.10",
			"4.90",
			"8.20",
			"15.00",
			"30.00",
			"69.00",
			"205.00",
			"1000.00"
		],
		"is_demo": false
	},
	{
		"code": 502,
		"letters": "Б",
		"default": false,
		"params": "{\"w\":15,\"h\":1,\"b\":5,\"s\":20}",
		"koefs": [
			"1.13",
			"1.75",
			"2.85",
			"4.90",
			"9.00",
			"18.00",
			"40.00",
			"108.00",
			"380.00",
			"2250.00"
		],
		"is_demo": false
	},
	{
		"code": 503,
		"letters": "В",
		"default": false,
		"params": "{\"w\":15,\"h\":1,\"b\":6,\"s\":40}",
		"koefs": [
			"1.26",
			"2.20",
			"4.10",
			"8.20",
			"18.00",
			"45.00",
			"135.00",
			"540.00",
			"3800.00"
		],
		"is_demo": false
	},
	{
		"code": 504,
		"letters": "Г",
		"default": false,
		"params": "{\"w\":20,\"h\":1,\"b\":5,\"s\":20}",
		"koefs": [
			"1.01",
			"1.37",
			"1.90",
			"2.65",
			"3.90",
			"5.90",
			"9.10",
			"15.00",
			"25.00",
			"46.00",
			"93.00",
			"210.00",
			"560.00",
			"1950.00",
			"11750.00"
		],
		"is_demo": false
	},
	{
		"code": 505,
		"letters": "Ґ",
		"default": false,
		"params": "{\"w\":20,\"h\":1,\"b\":6,\"s\":40}",
		"koefs": [
			"1.08",
			"1.55",
			"2.40",
			"3.70",
			"5.90",
			"10.00",
			"17.00",
			"32.00",
			"63.00",
			"140.00",
			"350.00",
			"1050.00",
			"4200.00",
			"29000.00"
		],
		"is_demo": false
	},
	{
		"code": 506,
		"letters": "Д",
		"default": false,
		"params": "{\"w\":20,\"h\":1,\"b\":7,\"s\":60}",
		"koefs": [
			"1.16",
			"1.85",
			"3.05",
			"5.10",
			"9.10",
			"17.00",
			"34.00",
			"74.00",
			"175.00",
			"490.00",
			"1600.00",
			"7300.00",
			"59000.00"
		],
		"is_demo": false
	}
];
