/**
 * Список типов выигрышных каналов:
 * - {@link My} - Собственные ставки и выигрыши. Наполняется в процессе игры приложением.
 * - {@link All} - Все ставки и выигрыши игроков.
 * - {@link Hot} - Большие выигрыши или высокие коэффициенты.
 * - {@link Promo} - Несуществующий канал для отображения данных по акциям.
 */
export enum WinFeedType {

	My		= 1,

	All		= 2,

	Hot		= 3,

	Promo   = 4

}
