import { GlobalPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';

import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';

import { Logger } from '@app/core/services/log/log.service';
import { WinFeedType } from '@app/core/services/network/enums/win-feed-type';
import { IAbstractResponse, ServerTickType } from '@app/core/services/network/interfaces/responses/iabstract-response';
import { IFinishGameResponse } from '@app/core/services/network/interfaces/responses/ifinish-game-response';
import {
	IFinishExtraData,
	INextGameStepResponse
} from '@app/core/services/network/interfaces/responses/inext-game-step-response';
import {
	IWinFeedEventData,
	IWinFeedHistoryResponse
} from '@app/core/services/network/interfaces/responses/iwin-feed-history-response';
import { StoreService } from '@app/core/services/store/store.service';

import { CommunicationService } from '@app/core/services/network/communication.service';
import { Channel, ChannelOperation, ChannelType } from '@app/core/services/network/enums/channel.enum';
import {
	IGetPromoProgressResponse,
	IPromoScoreTable
} from '@app/core/services/network/interfaces/responses/iget-promo-progress-response';
import { WinTableMobileWrapperComponent } from '@winTable/components/win-table-mobile-wrapper/win-table-mobile-wrapper.component';
import { WinFeedTableItem, WIN_TABLE_DATA_TOKEN } from '@winTable/constants/win-table-constants';
import { IWinFeedEvent } from '@winTable/interfaces/iwin-feed-event';
import { IWinTableConfig } from '@winTable/interfaces/iwin-table-config.service';
import { WinTableOverlayRef } from '@winTable/services/win-table-overlay-ref.service';
import { filter, finalize, switchMap, take, tap } from 'rxjs/operators';

// let ccc = 0;

/**
 * Тег для логирования
 */
const TAG = 'WinTableService';

/**
 * Сервис таблиц ставок на разные лотереи
 */
@Injectable({
	providedIn: 'root'
})
export class WinTableService {

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Таблицы событий по ставкам в разных лотереях
	 */
	winFeedTable: {[channel: string]: Array<WinFeedTableItem>} = {
		[Channel.WinFeedMy]: [],
		[Channel.WinFeedAll]: [],
		[Channel.WinFeedHot]: []
	};
	
	/**
	 * Таблица с промо-акциями
	 */
	promoTable: Array<IPromoScoreTable> = [];
	
	/**
	 * Текущий тип лидеров по акции
	 */
	leadersType$$ = new BehaviorSubject<string>('blueLeaders');
	
	/**
	 * Первая ли загрузка компонента-переключателя выигрышных таблиц
	 */
	firstTime = true;
	
	/**
	 * Добавленные строки в таблицы ставок 
	 */
	addedRows: {[channel: string]: Subject<Array<IWinFeedEventData>>} = {
		[Channel.WinFeedMy]: new Subject<Array<IWinFeedEventData>>(),
		[Channel.WinFeedAll]: new Subject<Array<IWinFeedEventData>>(),
		[Channel.WinFeedHot]: new Subject<Array<IWinFeedEventData>>()
	};
	
	/**
	 * Самое свежее время из строк в таблицах ставок
	 */
	maxTimeStamp = 0;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Ссылка на оверлей 
	 */
	private _componentRef: WinTableOverlayRef;

	/**
	 * В мобильном ли мы разрешении?
	 */
	private _isMobile = false;

	/**
	 * Подписка на канал всех ставок
	 */
	private activeListenSubscriptionAll: Subscription;

	/**
	 * Подписка на канал горячих ставок (от 1000 грн)
	 */
	private activeListenSubscriptionHot: Subscription;

	/**
	 * Конструктор сервиса.
	 *
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 * @param {Injector} injector Инжектор для подключения токена с конфигурацией таблиц ставок
	 * @param {Overlay} overlay Ссылка на оверлей таблиц ставок при открытии в мобайле
	 * @param communicationService Сервис взаимодействия с бекендом
	 */
	constructor(
		private readonly storeService: StoreService,
		private readonly injector: Injector,
		private readonly overlay: Overlay,
		private readonly communicationService: CommunicationService
	) {
		storeService.isMobileScreen$
			.subscribe((value: boolean) => {
				this._isMobile = value;
			});

		// console.log('~~~ WinTableService', ccc++);
	}

	/**
	 * Показать таблицу выигрышей как popup диалог (например, для мобильного телефона).
	 * Диалог будет открыт на весь экран.
	 */
	showWinTablePopUp(): void {
		Logger.Log.i(TAG, `showWinTablePopUp`)
			.console();

		this._componentRef?.close();
		this._componentRef = this.createEinTableOverlayRef({});
	}

	/**
	 * Закрыть popup диалог с таблицей выигрышей.
	 */
	hideWinTablePopUp(): void {
		Logger.Log.i(TAG, `hideWinTablePopUp`)
			.console();

		this._componentRef?.close();
	}

	/**
	 * Обновить личную таблицу ставок на основе ответов {@link INextGameStepResponse} или {@link IFinishGameResponse}
	 *
	 * @param {INextGameStepResponse | IFinishGameResponse} response Ответ от сервера.
	 * @param {(response: (INextGameStepResponse | IFinishGameResponse)) => number} parseFn Кастомная функция определения
	 * выигрышного коэффициента.
	 */
	updateWinTable(
		response: INextGameStepResponse | IFinishGameResponse,
		parseFn: (response: IFinishExtraData | INextGameStepResponse) => number
	): void {
		const result: IFinishExtraData | INextGameStepResponse = response.extra ?? response;

		Logger.Log.i(TAG, `updateWinTable => data:`, result)
			.console();

		let winOdds: number;
		winOdds = !!parseFn
			? parseFn({ ...result })
			: Number.isFinite(result.odds)
			? result.odds
			: result?.sum > 0
			? parseFloat(this.storeService.selectedSeries.koefs[result.step - 1])
			: 0;

		Logger.Log.d(TAG, `updateWinTable => parsed ODDS: [${winOdds}]`)
			.console();

		this.addItemToMyBetsTable(result.sum, winOdds);
	}

	/**
	 * Обновить личную таблицу ставок на основе данных, полученных с потока.
	 *
	 * @param {ServerTickType} serverTick Данные из потока
	 */
	updateWinTableByStream(serverTick: ServerTickType): void {
		Logger.Log.i(TAG, `updateWinTableByStream => server tick:`, serverTick)
			.console();

		this.addItemToMyBetsTable(serverTick.sum ?? serverTick.extra.sum, serverTick.odds ?? serverTick.extra.odds);
	}

	/**
	 * Инициировать подписки на все каналы, кроме канала собственных ставок
	 */
	initOtherFeeds(): void {
		if (!this.activeListenSubscriptionAll) {
			this.activeListenSubscriptionAll = this.storeService.hasServiceConnection$$
				.pipe(
					filter(f => !!f),
					switchMap(() => this.subscribe(Channel.WinFeedAll)),
					switchMap(() => this.listen(Channel.WinFeedAll)),
					finalize(() => this.unsubscribe(Channel.WinFeedAll)
						.subscribe()
					)
				)
				.subscribe();
		}
		if (!this.activeListenSubscriptionHot) {
			this.activeListenSubscriptionHot = this.storeService.hasServiceConnection$$
				.pipe(
					filter(f => !!f),
					switchMap(() => this.subscribe(Channel.WinFeedHot)),
					switchMap(() => this.listen(Channel.WinFeedHot)),
					finalize(() => this.unsubscribe(Channel.WinFeedHot)
						.subscribe()
					)
				)
				.subscribe();
		}
	}

	/**
	 * Обновить таблицу чемпионата при участии в промо-акции
	 * @param progress Прогресс по промо-акции
	 */
	updateChampTable(progress: IGetPromoProgressResponse): void {
		if (this.leadersType$$.value === 'orangeLeaders') {
			this.promoTable = [ progress.self, ...progress.orangeLeaders.slice(0, 9) ];
		} else {
			let orangeList = [progress.self];
			let blueList = [progress.self];

			if ((progress.blueLeaders.length === 0) && (progress.orangeLeaders.length === 0)) {
				// Нет игроков в синей лиге, нет в оранжевой - (зеленая строка для авториз / ссылка для неавториз.)
			} else if ((progress.blueLeaders.length > 0) && (progress.orangeLeaders.length === 0)) {
				// Есть игроки в синей, нет в оранжевой - заполняем полностью синюю лигу (топ -9), оранжева лига пустая
				blueList = [...blueList, ...progress.blueLeaders.slice(0, 9)];
			} else if ((progress.blueLeaders.length === 0) && (progress.orangeLeaders.length > 0)) {
				// Нет в синей, есть в оранжевой  - заполняем полностью оранжевую лигу (топ - 9),
				// синяя лига пустая. НО если в оранжевой лиге больше 9-ти игроков, то показываем
				// в синей лиге 2 игрока оранжевой лиги (из orangeOutsiders)
				orangeList = [...orangeList, ...progress.orangeLeaders.slice(0, 9)];
				if (progress.orangeLeaders.length > 9) {
					if (progress.orangeOutsiders.length === 1) {
						this.promoTable = [
							progress.self,
							...progress.orangeOutsiders,
							...progress.blueLeaders
								.slice(0, 8)
						];
					} else {
						this.promoTable = [
							progress.self,
							...progress.orangeOutsiders,
							...progress.blueLeaders
								.slice(0, 7)
						];
					}
				} else {
					this.promoTable = [
						progress.self,
						...progress.blueLeaders
							.slice(0, 9)
					];
					blueList = [...blueList, ...progress.orangeOutsiders];
				}
			} else if ((progress.blueLeaders.length > 0) && (progress.orangeLeaders.length > 0)) {
				// Есть в синей и оранжевой - заполняем полностью оранжевую лигу (топ - 9),
				// в синей лиге - показываем 2 игрока оранжевой лиги (из orangeOutsiders), и топ7 игроков синей лиги
				blueList = [...blueList, ...progress.orangeOutsiders, ...progress.blueLeaders.slice(0, 9 - progress.orangeOutsiders.length)];
				orangeList = [...orangeList, ...progress.orangeLeaders.slice(0, 9)];
			}
			this.promoTable = this.leadersType$$.value === 'orangeLeaders' ? orangeList : blueList;
		}
	}

	/**
	 * Показать результаты по акции
	 */
	showPromo(): void {
		if (this.storeService.activePromo?.code) {
			this.communicationService.getPromoProgress(this.storeService.activePromo.code, this.storeService.sid)
				.pipe(take(1))
				.subscribe();
		}
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Функция отписки от результатов игр
	 * @param channel Канал для отписки
	 * @private
	 */
	private unsubscribe(channel: Channel): Observable<IAbstractResponse> {
		return this.communicationService.channel(channel, ChannelType.BroadCast, ChannelOperation.Unsubscribe)
			.pipe(
				tap(() => {
					Logger.Log.d(TAG, `unsubscribe => from: ${channel}`)
						.console();
				})
			);
	}

	/**
	 * Функция подписки на результаты игр
	 * @param channel Канал для подписки
	 * @private
	 */
	private subscribe(channel: Channel): Observable<IWinFeedHistoryResponse> {
		return this.communicationService.channel(channel, ChannelType.BroadCast, ChannelOperation.Subscribe)
			.pipe(
				tap((response: IWinFeedHistoryResponse) => {
					Logger.Log.d(TAG, `subscribe => to: ${channel}`)
						.console();
					if (Array.isArray(response.history)) {
						const arr = response.history.reverse();
							// .sort((a: IWinFeedEventData, b: IWinFeedEventData) => {
							// 	const bTS = (new Date(b.timeStamp)).getTime();
							// 	const aTS = (new Date(a.timeStamp)).getTime();
							//
							// 	return bTS - aTS;
							// });
						this.winFeedTable[channel] = arr.map((elem, i) => ({...elem, gray: !!(i % 2)}));
					}
				})
			);
	}

	/**
	 * Слушатель событий с указанного канала
	 * @param channel Канал
	 * @private
	 */
	private listen(channel: Channel): Observable<IWinFeedEvent> {
		return this.communicationService.listenEvents(channel)
			.pipe(
				tap((value: IWinFeedEvent) => {
					Logger.Log.d(TAG, `listen => WinFeedEvent:`, value)
						.console();
					if (value.events && value.events.length) {
						this.addToTable(value.events, channel);
					}
				})
			);
	}

	/**
	 * Добавление записи в таблицу личных ставок.
	 *
	 * @param {number} winAmount Сумма выигрыша
	 * @param {number} winOdds Выигрышный коэффициент
	 */
	private addItemToMyBetsTable(winAmount: number, winOdds: number): void {
		const ticketId = (this.storeService.buyTicket$$.value ?? this.storeService.lastTicket$$.value)?.ticket?.id;
		const betAmount = (this.storeService.buyTicket$$.value ?? this.storeService.lastTicket$$.value)?.ticket?.bet;

		Logger.Log.i(TAG, `addItemToMyBetsTable => winAmount: ${winAmount}, winOdds: ${winAmount > 0 ? winOdds : 0} -> tid: [${ticketId}], ba: [${betAmount}]`)
			.console();

		if (!ticketId && !betAmount) {
			return;
		}

		// пропустить неопределенную порцию данных из потока (повтор)
		if (this.winFeedTable[Channel.WinFeedMy]?.find((v: WinFeedTableItem) => v.ticketId === ticketId)) {
			return;
		}

		const value: WinFeedTableItem[] = [{
			feedType: WinFeedType.My,
			gameCode: this.storeService.gameCode,
			betAmount,
			timeStamp: new Date().toUTCString(),
			userName: undefined,
			ticketId,
			winAmount,
			winOdds: winAmount > 0 ? winOdds : 0
		}];

		if (!this.storeService.demoMode$$.value[this.storeService.gameCode]) {
			this.addToTable(value, Channel.WinFeedMy);
		}
	}

	/**
	 * Добавить в таблицу новые строки
	 * @param delta Строки для добавления
	 * @param channel Канал, по которому выводится таблица
	 * @private
	 */
	private addToTable(delta: Array<WinFeedTableItem>, channel: Channel): void {
		const newAdded = delta.map(el => ({...el, animated: true}));
		// 	.sort((a: IWinFeedEventData, b: IWinFeedEventData) => {
		// 	const bTS = (new Date(b.timeStamp)).getTime();
		// 	const aTS = (new Date(a.timeStamp)).getTime();
		//
		// 	return bTS - aTS;
		// });
		// this.winFeedTable[channel] = [...newAdded, ...this.winFeedTable[channel]];
		for (const item of newAdded) {
			this.addedRows[channel].next([item]);
		}

		// this.winFeedTable[channel].sort((a: IWinFeedEventData, b: IWinFeedEventData) => {
		// 	const bTS = (new Date(b.timeStamp)).getTime();
		// 	const aTS = (new Date(a.timeStamp)).getTime();
		//
		// 	return bTS - aTS;
		// });
	}

	/**
	 * Функция создания popup диалога.
	 *
	 * @param {IWinTableConfig} winTableConfig Конфигурация popup компонента.
	 * @returns {WinTableOverlayRef}
	 */
	private createEinTableOverlayRef(winTableConfig: IWinTableConfig): WinTableOverlayRef {
		const overlayConfig: OverlayConfig = this.getOverlayConfig();
		const overlayRef: OverlayRef = this.overlay.create(overlayConfig);

		const winTableOverlayRef: WinTableOverlayRef = new WinTableOverlayRef(overlayRef);
		const injectionTokens: WeakMap<object, IWinTableConfig | WinTableOverlayRef> = new WeakMap();
		injectionTokens.set(WinTableOverlayRef, winTableOverlayRef);
		injectionTokens.set(WIN_TABLE_DATA_TOKEN, winTableConfig);

		const injector = Injector.create({providers: [{provide: WIN_TABLE_DATA_TOKEN, useValue: winTableConfig}]});
		const containerPortal: ComponentPortal<WinTableMobileWrapperComponent> =
			new ComponentPortal(WinTableMobileWrapperComponent, undefined, injector);
		overlayRef.attach(containerPortal);

		return winTableOverlayRef;
	}

	/**
	 * Получить конфигурацию оверлея для показа таблиц в мобайле
	 * @private
	 */
	private getOverlayConfig(): OverlayConfig {
		const scrollStrategy = this.overlay.scrollStrategies.reposition();
		const positionStrategy: GlobalPositionStrategy = this.overlay
			.position()
			.global()
			.left('0')
			.top('0');

		return new OverlayConfig({
			hasBackdrop: false,
			height: '100%',
			width: '100%',
			scrollStrategy,
			positionStrategy
		});
	}
}
