import { Overlay } from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import '@angular/common/locales/global/uk';
import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, Optional, SkipSelf} from '@angular/core';

import { of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { environment } from '@app/environments/environment';

import { AppErrorHandler } from '@app/core/services/errors/app-error-handler';
import { IFrameEventsService } from '@app/core/services/external/iframe/iframe-events.service';
import { NavigationService } from '@app/core/services/game/navigation.service';
import { LogService } from '@app/core/services/log/log.service';
import { GameStateService } from '@app/core/services/state/game-state.service';
import { CustomGameDataService } from '@app/core/services/store/custom-game-data.service';
import { StoreService } from '@app/core/services/store/store.service';

/**
 * Ссылка на файл конфигурации приложения.
 */
const CONFIG_FILE = `${window['baseHref'] || ''}app-config.json?${Date.now()}`;

/**
 * Функция загрузки конфигурации приложения до начала его инициализации.
 *
 * @param http
 * @returns {() => void}
 */
const loadConfiguration = (http: HttpClient): () => void => {
	console.log(`~~~ Configuration started. Load configuration file: ${CONFIG_FILE}...`);

	return async () => {
		await http.get(CONFIG_FILE, {responseType: 'json'})
			.pipe(
				tap(config => {
					console.log('~~~ Apply configuration to current environment');
					Object.keys(config)
						.forEach(key => environment[key] = config[key]);
					console.log(`~~~ Active version is: ${environment.version}`);
				}),
				catchError(err => {
					console.log('~~~ Will continue w/o external configuration');

					return of(err);
				})
			)
			.toPromise();

		console.log('~~~ Configuration done');
	};
};

/**
 * Модуль приложения, содержащий основную бизнес-логику (ядро приложения).
 */
@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		LogService,
		StoreService,
		GameStateService,
		IFrameEventsService,
		NavigationService,
		Overlay,
		CustomGameDataService,
		{
			provide: APP_INITIALIZER,
			deps: [HttpClient],
			multi: true,
			useFactory: loadConfiguration
		},
		{
			provide: ErrorHandler,
			useClass: AppErrorHandler
		},
		{
			provide: LOCALE_ID,
			useValue: 'uk-UA'
		}
	]
})
export class CoreModule {

	/**
	 * Конструктор модуля.
	 *
	 * @param {CoreModule} parentModule
	 */
	constructor(
		@Optional()
		@SkipSelf() parentModule: CoreModule
	) {
		if (parentModule) {
			throw new Error('CoreModule is already loaded. Import it in the AppModule only');
		}
	}

}
