import { Component, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { ResolveEnd, Router } from '@angular/router';
import { MiniGamesUrls } from '@app/core/enums/mini-games-list.enum';
import { StoreService } from '@app/core/services/store/store.service';
import { ModuleRouteId } from '@app/utils/route-utils';
import { WinTableService } from '@winTable/services/win-table.service';

/**
 * Компонент-обертка над таблицами ставок при десктопном разрешении
 */
@Component({
	selector: 'app-win-table-desktop-wrapper',
	templateUrl: './win-table-desktop-wrapper.component.html',
	styleUrls: ['./win-table-desktop-wrapper.component.scss']
})
export class WinTableDesktopWrapperComponent implements OnInit, OnDestroy {

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Видимые ли таблицы?
	 */
	isVisibleWinTable$: Observable<boolean>;

	/**
	 * Видимость лайв-таблицы на определенных страницах (урлах)
	 */
	isWinTableVisibleOnPage$$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная для уничтожения всех подписок
	 */
	private readonly unsubscribe$$ = new Subject<never>();

	/**
	 * Список урл к модулям игр
	 */
	private readonly games = Object.keys(MiniGamesUrls)
		.filter(k => !(parseInt(k, 10) >= 0))
		.map(key => MiniGamesUrls[key]);

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {ElementRef} elementRef Ссылка на самого себя
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 * @param {Router} router Объект маршрутизации в приложении
	 * @param {WinTableService} winTableService Сервис таблиц выигрыша
	 */
	constructor(
		readonly elementRef: ElementRef,
		private readonly router: Router,
		private readonly storeService: StoreService,
		private readonly winTableService: WinTableService
	) {
		this.router.events
			.pipe(
				// tap(x => console.warn(x)),
				filter(event => !event || (!!event && event instanceof ResolveEnd)),
				tap((x: ResolveEnd) => {
					this.isWinTableVisibleOnPage$$.next(!!this.games?.includes((x?.state?.root?.firstChild?.data as ModuleRouteId)?.moduleUrl));
				}),
				takeUntil(this.unsubscribe$$)
			)
			.subscribe();

		this.isVisibleWinTable$ = combineLatest([
			this.storeService.isTabletAndDesktop$,
			this.storeService.isWinTableVisible$$,
			this.isWinTableVisibleOnPage$$
		])
			.pipe(
				map(([isTabletAndDesktop, isWinTableVisible, show]: [boolean, boolean, boolean]) =>
					isTabletAndDesktop && !isWinTableVisible && show),
				takeUntil(this.unsubscribe$$)
			);
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {

		this.storeService.isWinTableVisible$$.asObservable()
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe((visibleWinTable: boolean) => {
				visibleWinTable
					? this.winTableService.showWinTablePopUp()
					: this.winTableService.hideWinTablePopUp();
			});
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

}
