<!-- АКТИВАЦИЯ МЕНЮ-ГАМБУРГЕР -->
<button
	class="ip-menu-button"
	data-cy="ip-menu-button"
	*ngIf="isMobile$ | async; else gameList"
	(click)="onClickMenuHandler()"
	>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		viewBox="0 0 448 512"
		>
		<path
			fill="#b09954"
			d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837
				7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16
				16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837
				0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
		></path>
	</svg>
</button>

<!-- СПИСОК ИГР -->
<ng-template #gameList>
	<app-game-list-inner
		[alwaysShowLabels]="false"
		(selectedGame)="onSelectedGameHandler($event)"
	></app-game-list-inner>
</ng-template>

<!-- ПРАВАЯ ЧАСТЬ -->
<div class="ip-right"
	 data-cy="ip-right"
>
	<ng-container *ngIf="demoMode">
		<!-- ДЕМО-БАЛАНС -->
		<div class="demo-balance"
			 [class.demo-balance_error]="notEnoughBalance"
			 data-cy="demo-balance"
		>
			<div class="demo-balance__title"
				 data-cy="demo-balance-title"
			>{{ 'common.demo-balance' | translate }}:</div>
			<div class="demo-balance__main"
				 data-cy="demo-balance-main"
			>
				<div class="demo-balance__value"
					 data-cy="demo-balance-value"
				>
					<span class="demo-balance__sum"
						  data-cy="demo-balance-sum"
					>{{ demoBalance }}</span> &#8372;
				</div>
				<!-- КНОПКА ПОПОЛНЕНИЯ ДЕМО БАЛАНСА -->
				<button *ngIf="notEnoughBalance && (this.storeService.gameState$$.value !== GameState.GameTime)"
						class="demo-balance__button fill-balance"
						data-cy="fill-balance"
						(click)="onFillBalanceClick()"
				>{{ 'common.fill' | translate }}</button>
			</div>
		</div>
	</ng-container>
	<!-- ПРОМО -->
<!--
	<ng-container *ngIf="storeService.getPromoProgress$$ | async as promoProgress">
		<app-action-panel
			*ngIf="(isVisibleActionPanel$ | async) && !demoMode"
			[promoProgress]="promoProgress"
		></app-action-panel>
	</ng-container>
-->
	<div *ngIf="storeService.activePromo" class="hint-wrapper" (mouseleave)="onCloseHintClick()">
		<button *ngIf="!storeService.demoMode$$.value[storeService.gameCode]"
				type="button" class="ip-right__stars-hint stars-action"
				[class.stars-action_pressed]="hintShown"
				(mousemove)="onShowHintClick()">
			<span class="star-pic star-pic_blue-stroke stars-action__star"></span>
			<svg class="stars-action__cup" width="31" height="25" viewBox="0 0 31 25" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M24.7773 1.54571C24.7971 0.602767 24.7613 0.00696262 24.7613 0.00696262L15.5468 0H15.5H15.4533L6.2377 0.00696262C6.2377 0.00696262 6.20289 0.602767 6.22278 1.54571H0.11853V2.55231C0.11853 2.78208 0.157322 8.202 3.50238 11.1711C4.89789 12.4094 6.63855 13.0321 8.69055 13.0331C9.00088 13.0331 9.32017 13.0122 9.64344 12.9843C10.8092 14.5808 12.156 15.6988 13.6708 16.2558V20.6771H9.22369V23.4462H7.75457V24.9989H15.4533H15.5468H23.2455V23.4472H21.7754V20.6781H17.3282V16.2568C18.8421 15.6998 20.1899 14.5818 21.3556 12.9853C21.6809 13.0132 22.0002 13.0331 22.3105 13.0331C24.3615 13.0311 26.1021 12.4094 27.4977 11.1701C30.8427 8.20101 30.8815 2.78108 30.8815 2.55131V1.54571H24.7773ZM4.84517 9.67013C2.93442 7.9792 2.36946 5.08472 2.20235 3.55891H6.33817C6.51323 5.45971 6.92502 7.82801 7.87492 9.90984C8.04899 10.2918 8.23201 10.6538 8.419 11.0079C6.99365 10.9532 5.79408 10.5086 4.84517 9.67013ZM26.1549 9.67013C25.2069 10.5096 24.0064 10.9532 22.582 11.0079C22.769 10.6548 22.952 10.2918 23.1261 9.90984C24.076 7.82801 24.4878 5.45971 24.6619 3.55891H28.7977C28.6306 5.08373 28.0666 7.9782 26.1549 9.67013Z" fill="white"/>
			</svg>
			<span class="star-pic star-pic_orange-stroke stars-action__star"></span>
		</button>

		<div class="popup-hint" *ngIf="hintShown">
			<div class="popup-hint__header">
				<button type="button" class="popup-hint__btn-close" (click)="onCloseHintClick()">
					<svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M8.33551 6.09482L12.6999 1.73999C12.891 1.54885 12.9984 1.28959 12.9984 1.01927C12.9984 0.748939 12.891 0.489685 12.6999 0.298536C12.5087 0.107387 12.2495 0 11.9792 0C11.709 0 11.4497 0.107387 11.2586 0.298536L6.90441 4.66352L2.5502 0.298536C2.35907 0.107387 2.09986 -2.01408e-09 1.82957 0C1.55928 2.01409e-09 1.30006 0.107387 1.10894 0.298536C0.917819 0.489685 0.810448 0.748939 0.810448 1.01927C0.810448 1.28959 0.917819 1.54885 1.10894 1.73999L5.4733 6.09482L1.10894 10.4497C1.01381 10.544 0.938303 10.6563 0.886774 10.78C0.835246 10.9037 0.808716 11.0364 0.808716 11.1704C0.808716 11.3044 0.835246 11.4371 0.886774 11.5608C0.938303 11.6845 1.01381 11.7967 1.10894 11.8911C1.2033 11.9863 1.31555 12.0618 1.43924 12.1133C1.56292 12.1648 1.69558 12.1914 1.82957 12.1914C1.96356 12.1914 2.09622 12.1648 2.2199 12.1133C2.34358 12.0618 2.45584 11.9863 2.5502 11.8911L6.90441 7.52613L11.2586 11.8911C11.353 11.9863 11.4652 12.0618 11.5889 12.1133C11.7126 12.1648 11.8453 12.1914 11.9792 12.1914C12.1132 12.1914 12.2459 12.1648 12.3696 12.1133C12.4933 12.0618 12.6055 11.9863 12.6999 11.8911C12.795 11.7967 12.8705 11.6845 12.922 11.5608C12.9736 11.4371 13.0001 11.3044 13.0001 11.1704C13.0001 11.0364 12.9736 10.9037 12.922 10.78C12.8705 10.6563 12.795 10.544 12.6999 10.4497L8.33551 6.09482Z" fill="#4C526A"/>
					</svg>
				</button>
			</div>
			<div class="popup-hint__body" [innerHTML]="'common.popup_hint' | translate"></div>
		</div>
	</div>
	
	<!-- КНОПКА LIVE -->
	<button
		class="live"
		(click)="onClickLiveHandler()"
		data-cy="live"
		>
		<span class="circle"
			  data-cy="circle"
		></span>
		<span class="live-text"
			  data-cy="live-text"
		>live</span>
	</button>
</div>
