<div
	class="twb-wrapper"
	[ngClass]="screenClassName"
	data-cy="twb-wrapper"
	>
	<button
		[attr.state]="buttonState"
		[disabled]="disabled"
		[innerHTML]="buttonState | translate"
		(click)="takeWinClicked.emit()"
		data-cy="twb-take-win-button"
	></button>
</div>
