import { Directive, EventEmitter, Input, Output } from '@angular/core';

import { IGameListItem } from '@app/core/interfaces/igame-list-item';
import { NavigationService } from '@app/core/services/game/navigation.service';
import { Logger } from '@app/core/services/log/log.service';
import { StoreService } from '@app/core/services/store/store.service';

/**
 * Перечень ориентаций для списка игр
 */
export enum GameListOrientation {
	/**
	 * Горизонтальная ориентация
	 */
	Horizontal  = 'horizontal',
	/**
	 * Вертикальная ориентация
	 */
	Vertical    = 'vertical'
}

/**
 * Тег для логирования
 */
const TAG = 'AbstractGameListDirective';

/**
 * Абстрактный компонент для списка игр.
 */
@Directive()
export abstract class AbstractGameListDirective {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Ориентация списка игр.
	 */
	@Input()
	orientation: GameListOrientation = GameListOrientation.Vertical;

	// -----------------------------
	//  Output properties
	// -----------------------------
	/**
	 * Событие выбора игры
	 */
	@Output()
	readonly selectedGame = new EventEmitter<IGameListItem>();

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Сервис навигации по внутренним маршрутам приложения
	 */
	abstract readonly navigationService: NavigationService;

	/**
	 * Сервис-хранилище приложения
	 */
	abstract readonly storeService: StoreService;

	// -----------------------------
	//  Public functions
	// -----------------------------
	/**
	 * Вспомогательная функция для ускорения вывода информации в шаблон
	 * @param index Индекс элемента
	 * @param item Элемент
	 */
	readonly trackByGameFn = (index, item: IGameListItem) => item.code;

	/**
	 * Обработчик клика по кнопки для выбора игры из списка
	 * @param {MouseEvent} event Передаваемое событие
	 */
	onGameClickHandler(event: MouseEvent): void {
		if (event.target instanceof HTMLButtonElement) {
			const id = event.target.id;
			if (id === 'lobby') {
				this.selectedGame.emit(undefined);
				this.navigationService.navigateToLobby();
			} else {
				const el = this.storeService.gameList$$.value.find(p => p.id === id);
				if (!!el) {
					Logger.Log.i(TAG, `onGameClickHandler => will navigate to game: [${el.name}]`)
						.console();

					this.selectedGame.emit(el);
					this.navigationService.navigateToSelectedGame(el);
				}
			}
		}
	}

}
