import { GlobalPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { ComponentRef, Injectable, Injector } from '@angular/core';
import { IGameListItem } from '@app/core/interfaces/igame-list-item';
import { NavigationService } from '@app/core/services/game/navigation.service';
import { Logger } from '@app/core/services/log/log.service';
import { StoreService } from '@app/core/services/store/store.service';
import { MenuHamburgerComponent } from '@hamburger/components/menu-hamburger/menu-hamburger.component';
import { HAMBURGER_DATA_TOKEN, HAMBURGER_LINK_LIST } from '@hamburger/constants/hamburger-constants';
import { IHamburgerConfig } from '@hamburger/interfaces/ihamburger-config';
import { HamburgerOverlayRef } from '@hamburger/services/hamburger-overlay-ref';

/**
 * Тег для логирования
 */
const TAG = 'MenuHamburgerService';

/**
 * Сервис гамбургер-меню
 */
@Injectable({
	providedIn: 'root'
})
export class MenuHamburgerService {

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Ссылка на оверлей под гамбургер-меню
	 * @private
	 */
	private _componentRef: HamburgerOverlayRef;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 * @param {NavigationService} navigationService Сервис навигации по внутренним маршрутам приложения
	 * @param {Injector} injector Инжектор сервисов и токенов с помощью DI
	 * @param {Overlay} overlay Объект подложки для гамбургер-меню
	 */
	constructor(
		private readonly storeService: StoreService,
		private readonly navigationService: NavigationService,
		private readonly injector: Injector,
		private readonly overlay: Overlay
	) {}

	/**
	 * Отобразить меню-гамбургер.
	 */
	openHamburgerMenu(): void {
		Logger.Log.i(TAG, `openHamburgerMenu`)
			.console();

		this._componentRef = this.creatHamburgerOverlayRef();
	}

	/**
	 * Скрыть меню-гамбургер.
	 */
	closeHamburgerMenu(): void {
		Logger.Log.i(TAG, `closeHamburgerMenu`)
			.console();

		this._componentRef?.close();
	}

	/**
	 * Перейти из меню на выбранную игру
	 * @param {IGameListItem} game Выбранный элемент меню с игрой
	 */
	navigateToGame(game: IGameListItem): void {
		this.navigationService.navigateToSelectedGame(game);
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Создать оверлей под гамбургер-меню
	 * @private
	 */
	private creatHamburgerOverlayRef(): HamburgerOverlayRef {
		const overlayConfig: OverlayConfig = this.getOverlayConfig();
		const overlayRef: OverlayRef = this.overlay.create(overlayConfig);

		const hamburgerOverlayRef: HamburgerOverlayRef = new HamburgerOverlayRef(overlayRef);
		const hamburgerConfig: IHamburgerConfig = { linkList: HAMBURGER_LINK_LIST, hamburgerOverlayRef };
		const injectionTokens: WeakMap<object, IHamburgerConfig | HamburgerOverlayRef> = new WeakMap();
		injectionTokens.set(HamburgerOverlayRef, hamburgerOverlayRef);
		injectionTokens.set(HAMBURGER_DATA_TOKEN, hamburgerConfig);

		const injector = Injector.create({providers: [{provide: HAMBURGER_DATA_TOKEN, useValue: hamburgerConfig}]});
		const containerPortal: ComponentPortal<MenuHamburgerComponent> = new ComponentPortal(MenuHamburgerComponent, undefined, injector);
		const containerRef: ComponentRef<MenuHamburgerComponent> = overlayRef.attach(containerPortal);

		// по клику на подложке вызвать метод закрытия меню
		overlayRef
			.backdropClick()
			.subscribe(() => {
				Logger.Log.i(TAG, `backdropClick`)
					.console();

				containerRef.instance.onClickCloseMenuHandler();
			});

		return hamburgerOverlayRef;
	}

	/**
	 * Получить конфигурацию оверлея под гамбургер-меню 
	 * @private
	 */
	private getOverlayConfig(): OverlayConfig {
		const scrollStrategy = this.overlay.scrollStrategies.reposition();
		const positionStrategy: GlobalPositionStrategy = this.overlay
			.position()
			.global()
			.left('0px')
			.top('0px');

		return new OverlayConfig({
			hasBackdrop: true,
			height: '100%',
			width: '300px',
			scrollStrategy,
			positionStrategy,
			panelClass: 'cdk-overlay-container_hamburger'
		});
	}

}
