import { MiniGamesCodes } from '@app/core/enums/mini-games-list.enum';
import { Action } from '@app/core/services/network/enums/action.enum';
import { ResultCode } from '@app/core/services/network/enums/result-code.enum';
import { seq } from '@app/core/services/network/mocks/constants';
import { CRASH_SERIES } from '@app/core/services/network/mocks/crash-series';
import { MINES_SERIES } from '@app/core/services/network/mocks/mines-series';
import { SQUID_SERIES } from '@app/core/services/network/mocks/squid-series';
import { TOD_SERIES } from '@app/core/services/network/mocks/tod-series';
import { MockResponse } from '@app/core/services/network/mocks/types';
import {TOWER_SERIES} from "@app/core/services/network/mocks/tower-series";
import {SMILE_SERIES} from "@app/core/services/network/mocks/smile-series";
import {BUHTA_SERIES} from "@app/core/services/network/mocks/buhta-series";
import {COLOR_SERIES} from "@app/core/services/network/mocks/color-series";

/**
 * Массив mock-запросов и mock-ответов на получение данных по каждой игре
 */
export const GetGameData = [
	{
		request: {
			action: Action.GetGameData,
			game_code: MiniGamesCodes.Mines,
			seq
		},
		response: {
			action: Action.GetGameData,
			R: ResultCode.Ok,
			series: MINES_SERIES,
			min_bet: 100,
			max_bet: 100000,
			seq
		}
	},
	{
		request: {
			action: Action.GetGameData,
			game_code: MiniGamesCodes.Tower,
			seq
		},
		response: {
			action: Action.GetGameData,
			R: ResultCode.Ok,
			series: TOWER_SERIES,
			min_bet: 100,
			max_bet: 100000,
			seq
		}
	},
	{
		request: {
			action: Action.GetGameData,
			game_code: MiniGamesCodes.Smile,
			seq
		},
		response: {
			action: Action.GetGameData,
			R: ResultCode.Ok,
			series: SMILE_SERIES,
			min_bet: 100,
			max_bet: 100000,
			seq
		}
	},
	{
		request: {
			action: Action.GetGameData,
			game_code: MiniGamesCodes.Crash,
			seq
		},
		response: {
			action: Action.GetGameData,
			R: ResultCode.Ok,
			series: CRASH_SERIES,
			min_bet: 100,
			max_bet: 100000,
			seq
		}
	},
	{
		request: {
			action: Action.GetGameData,
			game_code: MiniGamesCodes.Tod,
			seq
		},
		response: {
			action: Action.GetGameData,
			R: ResultCode.Ok,
			series: TOD_SERIES,
			min_bet: 100,
			max_bet: 100000,
			seq
		}
	},
	{
		request: {
			action: Action.GetGameData,
			game_code: MiniGamesCodes.Buhta,
			seq
		},
		response: {
			action: Action.GetGameData,
			R: ResultCode.Ok,
			series: BUHTA_SERIES,
			min_bet: 100,
			max_bet: 100000,
			seq
		}
	},
	{
		request: {
			action: Action.GetGameData,
			game_code: MiniGamesCodes.Squid,
			seq
		},
		response: {
			action: Action.GetGameData,
			R: ResultCode.Ok,
			series: SQUID_SERIES,
			min_bet: 100,
			max_bet: 100000,
			seq
		}
	},
	{
		request: {
			action: Action.GetGameData,
			game_code: MiniGamesCodes.Color,
			seq
		},
		response: {
			action: Action.GetGameData,
			R: ResultCode.Ok,
			series: COLOR_SERIES,
			min_bet: 100,
			max_bet: 100000,
			seq
		}
	}
];

/**
 * Функция для получения данных по каждой игре
 * @param gameCode Код игры
 * @param seriesCode Код серии
 */
export const getGameData = (gameCode: number, seriesCode: number): MockResponse => {
	const gameData = GetGameData.find(item => item.request.game_code === gameCode).response;

	return {
		...gameData,
		series: gameData.series.find(series => series.code === seriesCode)
	};
};
