import { Directive, Input } from '@angular/core';
import { IRowButton, RowControl } from '@app/shared/components/game-row-field/game-row-field.component';

/**
 * Абстрактная директива для создания кнопки в игровых рядах
 */
@Directive()
export abstract class AbstractRowFieldButtonDirective {

	// -----------------------------
	//  Input properties
	// -----------------------------
	/**
	 * Входная информация о кнопке
	 */
	@Input()
	button: IRowButton;

	/**
	 * Показывать ли надпись на кнопке
	 */
	@Input()
	showLabel: boolean;

	/**
	 * Данные для фокусирования на текущем шаге в игре
	 */
	@Input()
	rowControl: RowControl = {step: 0, active: true};

}
