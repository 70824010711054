import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	SimpleChanges
} from '@angular/core';

import {concat, merge, Observable, of, Subject, Subscription, timer} from 'rxjs';
import {map, repeat, share, switchMap} from 'rxjs/operators';

import {MiniGamesCodes} from '@app/core/enums/mini-games-list.enum';
import {BuyTicketMessageService} from '@app/core/services/game/buy-ticket-message.service';
import {GameState} from '@app/core/services/state/enums/game-state.enum';

/**
 * Модель одной кнопки.
 */
export interface IRowButton {

	/**
	 * Идентификатор кнопки, он же порядковый номер от 0.
	 */
	id: string;

	/**
	 * Метка кнопки.
	 */
	label?: string;

	/**
	 * Номер ряда, в которой находится кнопка от 0.
	 */
	row?: number;

	/**
	 * Номер колонки, в которой находится кнопка от 0.
	 */
	col?: number;

	/**
	 * Признак отключенной кнопки.
	 */
	disabled?: boolean;

	/**
	 * Признак проигрышного символа.
	 */
	hasBomb?: boolean;

	/**
	 * Признак выигрышного символа.
	 */
	hasSymbol?: boolean;

	/**
	 * Признак открытой ячейки.
	 */
	isOpen?: boolean;

	/**
	 * Признай активного рядка (в игре), на котором можно совершить ход.
	 */
	isActiveRow?: boolean;

	/**
	 * Номер анимационной иконки, для игры Смайл.
	 */
	iconNum?: number;

	/**
	 * Количество кадров анимации, для игры Смайл.
	 */
	animationFrameCount?: number;

}

/**
 * Модель ячейки с коэффициентом.
 */
export interface IGameRowOdds {

	/**
	 * Величина коэффициента.
	 */
	odds: number;

	/**
	 * Номер шага.
	 */
	step: number;

}

/**
 * Модель строки на игровом поле.
 */
export interface IGameRowItem {

	/**
	 * Номер строки, начиная с 0.
	 */
	rowIndex: string;

	/**
	 * Модель коэффициента.
	 */
	oddsItem: IGameRowOdds;

	/**
	 * Модель кнопки из ряда.
	 */
	rowButtons: Array<IRowButton>;

	/**
	 * Дополнительное имя стиля.
	 */
	styleClass?: string;

}

/**
 * Модель управления анимацией и стилем ряда, для игры Смайл
 */
export interface RowControl {

	/**
	 * Шаг игры, для расчета анимации ряда.
	 */
	step: number;

	/**
	 * Признак активности ряда.
	 */
	active: boolean;

}

/**
 * Компонент для отображения игрового поля в построчных играх.
 */
@Component({
	selector: 'app-game-row-field',
	templateUrl: './game-row-field.component.html',
	styleUrls: ['./game-row-field.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GameRowFieldComponent implements OnChanges {

	/**
	 * Текущий шаг
	 * @private
	 */
	private _currentStep: number;

	/**
	 * Стрим для обнаружения изменения игрового шага
	 */
	private readonly _changeStepEvent$ = new Subject<never>();

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Признак показа метки на кнопке.
	 */
	@Input()
	showLabel = false;

	/**
	 * Список рядов в рабочем поле.
	 */
	@Input()
	rows: Array<IGameRowItem>;

	/**
	 * Код игры.
	 */
	@Input()
	gameCode: MiniGamesCodes;

	/**
	 * Ключ для локализации надписи на кофе.
	 */
	@Input()
	stepLocalizationKey = 'common.hit';

	/**
	 * Сеттер текущего шага в игре
	 * @param value Значение шага
	 */
	@Input()
	set currentStep(value: number) {
		this._currentStep = value;
		this._changeStepEvent$.next();
	}

	/**
	 * Геттер текущего шага
	 */
	get currentStep(): number {
		return this._currentStep;
	}

	/**
	 * Текущее состояние игры.
	 */
	@Input()
	gameState: GameState;

	/**
	 * Данные для фокусирования на текущем шаге в игре
	 */
	@Input()
	rowControl: RowControl = { step: 0, active: true };

	/**
	 * Признак мобильного.
	 */
	@Input()
	isMobile = false;

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие клика по кнопке на игровом поле
	 */
	@Output()
	readonly buttonClicked = new EventEmitter<IRowButton>();

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Список состояний приложения
	 */
	readonly GameState: typeof GameState = GameState;
	
	/**
	 * Список возможных кодов игр.
	 */
	readonly MiniGamesCodes: typeof MiniGamesCodes = MiniGamesCodes;

	/**
	 * Наблюдаемая переменная для анимации смайлов 
	 */
	readonly smileAnimationEvent$: Observable<number> = merge(this._changeStepEvent$.asObservable(), of(undefined))
		.pipe(
			switchMap(() => concat(
				of(undefined),
				timer(5000)
					.pipe(
						switchMap(() =>
							of(undefined)
								.pipe(
									switchMap(() => timer(Math.random() * 3000 + 2000)
										.pipe(
											map(() => Math.floor(Math.random() * this.rows[0].rowButtons.length))
										)
									),
									repeat()
								)
						)
					)
				)
			),
			// tap(x => console.warn('Smile anim: %o', x)),
			share()
		);

	/**
	 * Массив строк на игровом поле
	 */
	parsedRows: Array<IGameRowItem>;

	/**
	 * Последний коэффициент с двумя знаками после запятой
	 */
	lastOddsWithTwoDigits: number;

	/**
	 * Последний коэффициент с одним знаком после запятой
	 */
	lastOddsWithOneDigits: number;


	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Подписка на таймер для мигания
	 * @private
	 */
	private _blinkerSubscription: Subscription;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {ChangeDetectorRef} cdr Детектор обнаружения изменений
	 * @param buyTicketMessageService Сервис управления сообщением о покупке билета
	 */
	constructor(
		private readonly cdr: ChangeDetectorRef,
		private readonly buyTicketMessageService: BuyTicketMessageService
	) {}

	/**
	 * Вспомогательная функция для ускорения вывода информации в шаблон
	 * @param index Индекс элемента
	 * @param item Элемент
	 */
	readonly trackByRowsFn = (index, item: IGameRowItem) => `${item.rowIndex}`;

	/**
	 * Вспомогательная функция для ускорения вывода информации в шаблон
	 * @param index Индекс элемента
	 * @param item Элемент
	 */
	readonly trackByButtonsFn = (index, item: IRowButton) => `${item.id}`;

	/**
	 * Обработчик клика на рабочем поле.
	 * Обрабатывает нажатый элемент и в случае, если это кнопка - отсылает событие.
	 *
	 * @param {MouseEvent} event Передаваемое событие
	 */
	onClickRowHandler(event: MouseEvent): void {
		let clickedBtn: IRowButton;
		if (Array.isArray(this.parsedRows) && this.parsedRows.length > 0 && event.target instanceof HTMLButtonElement) {
			const id = event.target.id;
			if (isNaN(Number.parseInt(id, 10))) {
				return;
			}

			const allButtons: IRowButton[] = this.parsedRows
				.map(m => m.rowButtons)
				.reduce((p: IRowButton[], c: IRowButton[]) => [ ...p, ...c ], []);
			clickedBtn = allButtons.find(f => f.id === id);
		}

		if (clickedBtn && (this.gameState === GameState.GameTime)) {
			this.buttonClicked.emit(clickedBtn);
		}

		if (this.gameState === GameState.GameTime) {
			this.buyTicketMessageService.setDefaults();
			this.cdr.detectChanges();
		} else {
			if (this.buyTicketMessageService.firstTimeClicked) {
				this.buyTicketMessageService.buyTicketHint = true;
			}
			this.buyTicketMessageService.firstTimeClicked = false;
		}
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события изменений каких-либо переменных в директиве
	 * @param changes Изменения
	 */
	ngOnChanges(changes: SimpleChanges): void {
		// при смене параметров обнулить шаги
		if (changes.gameState?.currentValue === GameState.ChangedSettingsAfterGame) {
			this.currentStep = 0;
		}

		if (Array.isArray(changes.rows?.currentValue)) {
			this.parsedRows = this.rows.map(m => m);
			this.parseOddsZeroLimits();

			// помигать "золотом"
			if (this.gameState !== GameState.GameTime
				&& this.gameState !== GameState.ShowBigWin
				&& this.gameState !== GameState.ShowLosing
				&& this.gameState !== GameState.ShowWin
			) {
				this.parsedRows = this.rows.map((m: IGameRowItem) => ({ ...m, styleClass: 'golden-blinker' }));

				this._blinkerSubscription?.unsubscribe();
				this._blinkerSubscription = timer(750)
					.subscribe(() => {
						this.parsedRows = this.parsedRows.map(m => ({ ...m, styleClass: undefined }));
						this.cdr.detectChanges();
					});
			}
		}
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Определить граничные коэффициенты для отсечки лишних 0.
	 */
	private parseOddsZeroLimits(): void {
		if (!Array.isArray(this.parsedRows)) {
			return;
		}

		const arr: string[] = this.parsedRows
			.filter(f => !isNaN(f.oddsItem.odds))
			.map(m => m.oddsItem.odds.toFixed(2))
			.map(m => m.split('.')[1]);

		const two = arr
			.map((m: string, i: number) => ({c: m.charAt(1) !== '0', i}))
			.filter(f => f.c)
			.sort((a, b) => b.i - a.i);
		this.lastOddsWithTwoDigits = two.length > 0 ? this.parsedRows[two[0].i].oddsItem.odds : NaN;

		const one = arr
			.map((m: string, i: number) => ({c: m.charAt(0) !== '0' && m.charAt(1) === '0', i}))
			.filter(f => f.c)
			.sort((a, b) => b.i - a.i);
		this.lastOddsWithOneDigits = one.length > 0 ? this.parsedRows[one[0].i].oddsItem.odds : NaN;
	}

}
