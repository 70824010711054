import {Injectable} from '@angular/core';

import {IGameListItem} from '@app/core/interfaces/igame-list-item';
import {ExternalCommunicationService} from '@app/core/services/external/external-communication.service';
import {NavigationService} from '@app/core/services/game/navigation.service';
import {Logger} from '@app/core/services/log/log.service';
import {AbstractCommunication} from '@app/core/services/network/abstract-communication';
import {Action} from '@app/core/services/network/enums/action.enum';
import {Channel, ChannelOperation, ChannelType} from '@app/core/services/network/enums/channel.enum';
import {NextGameStepState} from '@app/core/services/network/enums/next-game-step-state';
import {ResultCode} from '@app/core/services/network/enums/result-code.enum';
import {IAbstractEvent} from '@app/core/services/network/interfaces/events/iabstract-event';
import {IAbstractRequest} from '@app/core/services/network/interfaces/requests/iabstract-request';
import {IBuyTicketRequest} from '@app/core/services/network/interfaces/requests/ibuy-ticket-request';
import {IChannelRequest} from '@app/core/services/network/interfaces/requests/ichannel-request';
import {IFinishGameRequest} from '@app/core/services/network/interfaces/requests/ifinish-game-request';
import {IGetGameDataRequest} from '@app/core/services/network/interfaces/requests/iget-game-data-request';
import {IGetLastTicketDataRequest} from '@app/core/services/network/interfaces/requests/iget-last-ticket-data-request';
import {IGetLastTicketRequest} from '@app/core/services/network/interfaces/requests/iget-last-ticket-request';
import {IGetLobbyDataRequest} from '@app/core/services/network/interfaces/requests/iget-lobby-data-request';
import {IGetPromoProgressRequest} from '@app/core/services/network/interfaces/requests/iget-promo-progress-request';
import {IGetPromosRequest} from '@app/core/services/network/interfaces/requests/iget-promos-request';

import {IGetUserBonusesRequest} from '@app/core/services/network/interfaces/requests/iget-user-bonuses-request';
import {INextGameStepRequest} from '@app/core/services/network/interfaces/requests/inext-game-step-request';
import {IAbstractResponse} from '@app/core/services/network/interfaces/responses/iabstract-response';
import {IBuyTicketResponse} from '@app/core/services/network/interfaces/responses/ibuy-ticket-response';
import {IFinishGameResponse} from '@app/core/services/network/interfaces/responses/ifinish-game-response';
import {IGetGameDataResponse} from '@app/core/services/network/interfaces/responses/iget-game-data-response';
import {
	IGetLastTicketDataResponse
} from '@app/core/services/network/interfaces/responses/iget-last-ticket-data-response';
import {IGetLastTicketResponse} from '@app/core/services/network/interfaces/responses/iget-last-ticket-response';
import {
	IGameLobbyData,
	IGetLobbyDataResponse
} from '@app/core/services/network/interfaces/responses/iget-lobby-data-response';
import {IGetPromoProgressResponse} from '@app/core/services/network/interfaces/responses/iget-promo-progress-response';
import {IGetPromosResponse} from '@app/core/services/network/interfaces/responses/iget-promos-response';
import {IGetUserBonusesResponse} from '@app/core/services/network/interfaces/responses/iget-user-bonuses-response';
import {INextGameStepResponse} from '@app/core/services/network/interfaces/responses/inext-game-step-response';
import {GameStateService} from '@app/core/services/state/game-state.service';
import {StoreService} from '@app/core/services/store/store.service';

import {environment} from '@app/environments/environment';

import {convertGameCodeToIconSrc, convertGameCodeToPath} from '@app/utils/route-utils';
import {GameDialogsService} from '@dialogs/services/game-dialogs.service';
import {PreloaderService} from '@preloader/services/preloader.service';
import {CookieService} from 'ngx-cookie-service';

import {MiniGamesCodes} from '@app/core/enums/mini-games-list.enum';
import {GameState} from '@app/core/services/state/enums/game-state.enum';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, EMPTY, Observable, of, Subscriber, throwError, TimeoutError} from 'rxjs';
import {catchError, switchMap, take, tap, timeout} from 'rxjs/operators';

/**
 * Тег для логирования
 */
const TAG = 'CommunicationService';

const RESTRICTED_LOTTERIES = [
	MiniGamesCodes.Squid,
	MiniGamesCodes.Color,
	MiniGamesCodes.Smile,
	MiniGamesCodes.Crash
];

/**
 * Коммуникационный сервис для взаимодействия с сервером.
 */
@Injectable({
	providedIn: 'root'
})
export class CommunicationService extends AbstractCommunication<IAbstractRequest, IAbstractResponse, IAbstractEvent> {

	// -----------------------------
	//  Protected properties
	// -----------------------------
	/**
	 * Ссылка на подключение к общему сервису регистрации игр.
	 */
	protected connectUrl = environment.registryServiceUrl;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса регистрации.
	 *
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 * @param {GameStateService} gameStateService Сервис для работы с состоянием приложения
	 * @param {GameDialogsService} gameDialogsService Сервис диалоговых окон
	 * @param {ExternalCommunicationService} externalCommunicationService Сервис взаимодействия с внешним окном или WebView
	 * @param {PreloaderService} preloaderService Сервис для показа прелоадера при загрузке приложения
	 * @param {NavigationService} navigationService Сервис навигации по внутренним маршрутам приложения
	 * @param cookieService Сервис по работе с куками
	 * @param translateService Сервис переводов
	 */
	constructor(
		protected readonly storeService: StoreService,
		protected readonly gameStateService: GameStateService,
		protected readonly gameDialogsService: GameDialogsService,
		protected readonly externalCommunicationService: ExternalCommunicationService,
		protected readonly preloaderService: PreloaderService,
		protected readonly navigationService: NavigationService,
		private readonly cookieService: CookieService,
		private readonly translateService: TranslateService
	) {
		super();
	}

	/**
	 * Получить версию сервера.
	 *
	 * @returns {Observable<any>}
	 */
	version(): Observable<IAbstractResponse> {
		return this.sendMessage({ action: Action.Version })
			.pipe();
	}

	/**
	 * Подписаться или отписаться от потока событий данного канала.
	 *
	 * @param {Channel} channel Имя канала потока событий.
	 * @param {ChannelType} type Тип канала событий.
	 * @param {ChannelOperation} operation Операция (подпись или отписка).
	 * @returns {Observable<IAbstractResponse>}
	 */
	channel(channel: Channel, type: ChannelType, operation: ChannelOperation): Observable<IAbstractResponse> {
		const request: IChannelRequest = { action: Action.Channel, channel, type, operation };

		return this.sendMessageInBackground(request);
		// TODO обрабатывать результат (ошибки)
	}

	/**
	 * Получить список доступных игр в лобби.
	 *
	 * @returns {Observable<IGetLobbyDataResponse>}
	 * @see IGetLobbyDataRequest
	 */
	getLobbyData(): Observable<IGetLobbyDataResponse> {
		if (!!this.storeService.getLobbyData$$.value && this.storeService.getLobbyData$$.value.games.length > 0) {
			return of(this.storeService.getLobbyData$$.value);
		}

		const request: IGetLobbyDataRequest = { action: Action.GetLobbyData };
		const parseGameData = (game: IGameLobbyData): IGameListItem => {
			const path = convertGameCodeToPath(game.code);
			const iconSrc = convertGameCodeToIconSrc(game.code);

			return { ...game, path, id: `${game.code}`, iconSrc };
		};

		return this.sendMessage(request)
			.pipe(
				tap((response: IGetLobbyDataResponse) => {
					// del
					const response2 = {
						...response,
						games: response.games.filter(game => !RESTRICTED_LOTTERIES.includes(game.code))
					};
					// del
					
					this.storeService.getLobbyData$$.next(response2);
					this.storeService.gameList$$.next(
						Array.isArray(response2.games)
							? response2.games.map(parseGameData)
							: []
					);
				})
			);
	}

	/**
	 * Получить детальную информацию об игре.
	 *
	 * @param {number} gameCode Код игры.
	 * @returns {Observable<IGetGameDataResponse>}
	 */
	getGameData(gameCode: number): Observable<IGetGameDataResponse> {
		const request: IGetGameDataRequest = { action: Action.GetGameData, game_code: gameCode };

		const dataParser = (response: IGetGameDataResponse): IGetGameDataResponse => {
			this.storeService.gameCode = gameCode;
			const parsedResponse = {...response};
			parsedResponse.series.forEach(f => {
				try {
					if (typeof f.params === 'string' && f.params.length > 0) {
						const p = JSON.parse(f.params);
						f.params = Array.isArray(p)
							? p
							: { w: +p.w, h: +p.h, b: +p.b, k: +p.k, n: +p.n, o: p.o };
					} else {
						Logger.Log.w(TAG, `getGameData => series parameters has empty string`)
							.console();
					}
				} catch (err) {
					Logger.Log.e(TAG, `getGameData => can't parse series parameters:`, f.params)
						.console();
				}
			});

			return parsedResponse;
		};

		return this.sendMessage(request)
			.pipe(
				switchMap((response: IAbstractResponse) =>
					this.parseError<IGetGameDataResponse>(response, this.storeService.gameData$$, dataParser)
				)
			);
	}

	/**
	 * Определить, есть ли не доигранный билет у игрока.
	 *
	 * @returns {Observable<IGetLastTicketResponse>}
	 * @see Action.GetLastTicket
	 */
	getLastTicket(gameCode?: number): Observable<IGetLastTicketResponse> {
		const sid = this.storeService.sid || this.storeService.demoSID;
		const frontUID = this.cookieService.get('_ga') || localStorage.getItem('front_uid');
		const request: IGetLastTicketRequest = { action: Action.GetLastTicket, sid, frontUID };

		if (environment.mocks && gameCode) {
			request.game_code = gameCode
		}
		
		return this.sendMessage(request)
			.pipe(
				switchMap((response: IAbstractResponse) =>
					this.parseError<IGetLastTicketResponse>(response, this.storeService.lastTicket$$)
				)
			);
	}

	/**
	 * Получить детальную информацию об ранее купленном не доигранном билете.
	 * Используется для визуализации данного билета на клиенте.
	 *
	 * @param {number} ticketId Идентификатор билета
	 * @param {number} gameCode Код игры
	 * @param {number} seriesCode Код серии
	 * @returns {Observable<IGetLastTicketDataResponse>}
	 */
	getLastTicketData(ticketId: string, gameCode: number, seriesCode: number): Observable<IGetLastTicketDataResponse> {
		const sid = this.storeService.sid || this.storeService.demoSID;
		const request: IGetLastTicketDataRequest = { action: Action.GetLastTicketData, sid, ticket_id: ticketId,
			game_code: gameCode,
			series_code: seriesCode
		};

		return this.sendMessage(request)
			.pipe(
				switchMap((response: IAbstractResponse) =>
					this.parseError<IGetLastTicketDataResponse>(response, this.storeService.lastTicketData$$)
				)
			);
	}

	/**
	 * Список бонусов собранных игроком
	 * @param gameCode Код игры. Если игра известна, то нужно указывать обязательно. Не указывать можно, только если нужна информация по всем играм
	 * @param amount Размер ставки в копейках
	 */
	getUserBonuses(gameCode?: number, amount?: number): Observable<IGetUserBonusesResponse> {
		const sid = this.storeService.sid || this.storeService.demoSID;
		const request: IGetUserBonusesRequest = { action: Action.GetUserBonuses, sid, gameCode, amount };

		return this.sendMessage(request)
			.pipe(
				switchMap((response: IAbstractResponse) =>
					this.parseError<IGetUserBonusesResponse>(response, this.storeService.userBonuses$$)
				)
			);
	}

	/**
	 * Зарегистрировать ставку (купить билет).
	 *
	 * @param {number} gameCode Код игры.
	 * @param {number} seriesCode Код серии.
	 * @param {number} betAmount Сумма ставки в копейках, т.е. стоимость билета, которую указывает игрок при регистрации ставки.
	 * @param {number} auto_stop Величина коэффициента, при котором игра остановится автоматически (1000 > odds >= 1.01).
	 * @returns {Observable<IBuyTicketResponse>}
	 */
	buyTicket(gameCode: number, seriesCode: number, betAmount: number, auto_stop?: number): Observable<IBuyTicketResponse> {
		const sid = this.storeService.sid ||
			(this.storeService.demoMode$$.value[this.storeService.gameCode] ? this.storeService.demoSID : undefined);
		const request: IBuyTicketRequest = {
			action: Action.BuyTicket,
			game_code: gameCode,
			series_code: seriesCode,
			sid,
			bet_amount: betAmount,
			auto_stop
		};
		if (environment.isMobile) {
			request.termCode = this.storeService.termCode;
		}

		return this.sendMessage(request)
			.pipe(
				switchMap((response: IAbstractResponse) =>
					this.parseError<IBuyTicketResponse>(response, this.storeService.buyTicket$$)
					// this.parseError<IBuyTicketResponse>({...response, R: 4393}, this.storeService.buyTicket$$) // TODO test
				)
			);
	}

	/**
	 * Совершить шаг в игре, кроме Креша.
	 *
	 * @param {number} gameCode Код игры.
	 * @param {number} seriesCode Код серии
	 * @param {number} cell Номер ячейки
	 * @param {boolean} auto признак игры по авто кнопке
	 * @param step номер шага чисто для дебага
	 * @returns {Observable<INextGameStepResponse>}
	 */
	nextGameStep(gameCode: number, seriesCode: number, cell: number, auto?: boolean, step?: number): Observable<INextGameStepResponse> {
		const ticketId = !!this.storeService.buyTicket$$.value
			? this.storeService.buyTicket$$.value.ticket.id
			: this.storeService.lastTicket$$.value.ticket.id;

		const sid = this.storeService.sid ||
			(this.storeService.demoMode$$.value[this.storeService.gameCode] ? this.storeService.demoSID : undefined);

		const request: INextGameStepRequest = {
			action: Action.NextGameStep,
			game_code: gameCode,
			sid,
			ticket_id: ticketId,
			series_code: seriesCode,
			cell
		};
		if (auto) {
			request.auto = auto;
		}

		return this.sendMessage(request)
			.pipe(
				switchMap(response => {
					if (gameCode === MiniGamesCodes.Squid) {
						return this.parseError<INextGameStepResponse>(response, this.storeService.nextGameStep2$$);
					}

					return this.parseError<INextGameStepResponse>(response, this.storeService.nextGameStep$$);
				})
			);
	}

	/**
	 * Совершить шаг в игре Креш.
	 *
	 * @param coef_step Номер шага
	 * @returns {Observable<INextGameStepResponse>}
	 */
	nextCrashGameStep(coef_step: number): Observable<INextGameStepResponse> {
		const ticketId = !!this.storeService.buyTicket$$.value
			? this.storeService.buyTicket$$.value.ticket.id
			: this.storeService.lastTicket$$.value.ticket.id;

		const sid = this.storeService.sid ||
			(this.storeService.demoMode$$.value[this.storeService.gameCode] ? this.storeService.demoSID : undefined);

		const request: INextGameStepRequest = {
			action: Action.NextGameStep,
			coef_step,
			game_code: MiniGamesCodes.Crash,
			sid,
			ticket_id: ticketId,
			cell: 0,
			auto: false
		};

		return this.sendMessage(request, false)
			.pipe(
				switchMap(response => this.parseError<INextGameStepResponse>(response, this.storeService.nextGameStep$$))
			);
	}

	/**
	 * Завершить игру и забрать выигрыш.
	 *
	 * @returns {Observable<IFinishGameResponse>}
	 */
	finishGame(gameCode: number, seriesCode: number): Observable<IFinishGameResponse> {
		const ticketId = !!this.storeService.buyTicket$$.value ? this.storeService.buyTicket$$.value.ticket.id
			: this.storeService.lastTicket$$.value.ticket.id;

		const sid = this.storeService.sid || (this.storeService.demoMode$$.value[gameCode] ? this.storeService.demoSID : undefined);

		const request: IFinishGameRequest = { action: Action.FinishGame, game_code: gameCode, sid, ticket_id: ticketId,
			series_code: seriesCode
		};

		return this.sendMessage(request)
			.pipe(
				switchMap(response => this.parseError<IFinishGameResponse>(response, this.storeService.finishGame$$))
			);
	}

	/**
	 * Получить список акций.
	 *
	 * @returns {Observable<IGetPromosResponse>}
	 * @see IGetPromosRequest
	 */
	getPromos(): Observable<IGetPromosResponse> {
		const request: IGetPromosRequest = { action: Action.GetPromos };

		return this.sendMessage(request)
			.pipe(
				switchMap((response: IAbstractResponse) =>
					this.parseError<IGetPromosResponse>(response, this.storeService.getPromos$$)
				)
			);
	}

	/**
	 * Получить прогресс (текущие результаты по акции), если такое предусмотрено акцией.
	 *
	 * @param {number} promoCode Код акции, по которой нужно получить прогресс.
	 * @param {string} sid Токен пользователя
	 * @returns {Observable<IGetPromoProgressResponse>}
	 * @see IGetPromoProgressRequest
	 */
	getPromoProgress(promoCode: number, sid?: string): Observable<IGetPromoProgressResponse> {
		if (!this.storeService.isPromoProgressMayBeRequested) {
			return EMPTY;
		}

		if (this.storeService.isPromoProgressRequestInAction) {
			return EMPTY;
		}

		this.storeService.isPromoProgressRequestInAction = true;

		const request: IGetPromoProgressRequest = { action: Action.GetPromoProgress, promoCode, sid };

		return this.sendMessageInBackground(request) ///////// TODO ???
			.pipe(
				switchMap((response: IAbstractResponse) =>
					this.parseError<IGetPromoProgressResponse>(response, this.storeService.getPromoProgress$$)
				),
				timeout(30000),
				catchError(err => {
					this.storeService.isPromoProgressRequestInAction = false;
					if (err instanceof TimeoutError) {
						return EMPTY;
					}

					return throwError(err);
				}),
				tap(x => {
					this.storeService.isPromoProgressRequestInAction = false;
				})
			);
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Обработать успешный ответ
	 * @param r Ответ, приведенный к конкретному типу
	 * @param response Любой ответ от сервера
	 * @param subscriber Подписчик, создающий наблюдаемую переменную с ответом от сервера
	 * @param subject Наблюдаемая переменная, которая содержит модифицированный ответ от сервера
	 * @param parser Парсер ответа от сервера
	 * @private
	 */
	private handleAsSuccess<R extends IAbstractResponse>(
		r: R, response: IAbstractResponse, subscriber: Subscriber<R>, subject?: BehaviorSubject<R>, parser?: (response: R) => R
	): void {
		let newR: R = r;
		let ngsResponse;
		if (!!subject) {
			if (!!parser) {
				newR = parser(response as R);
			}
			if ((response as INextGameStepResponse).extra) {
				ngsResponse = { ...response, ...(response as INextGameStepResponse).extra };
				this.storeService.nextGameStep$$.next(ngsResponse);
			} else {
				subject.next(newR);
			}
			this.gameStateService.parseGameStateByResponse(response);
		}

		subscriber.next(!!subject && (response as INextGameStepResponse).extra ? ngsResponse : newR);
		subscriber.complete();
	}

	/**
	 * Дефолтный обработчик ошибок
	 * @param r Ответ, приведенный к конкретному типу
	 * @param response Любой ответ от сервера (абстрактного типа)
	 * @param subscriber Подписчик, создающий наблюдаемую переменную с ответом от сервера
	 * @private
	 */
	private handleAsDefault<R extends IAbstractResponse>(r: R, response: IAbstractResponse, subscriber: Subscriber<R>): void {
		this.gameDialogsService.showErrorDialog(response.R)
			.pipe(take(1))
			.subscribe(() => {
				subscriber.error(r);
				subscriber.complete();
			});
	}

	/**
	 * Сообщение о просьбе обратиться в тех. поддержку
	 * @param r Ответ, приведенный к конкретному типу
	 * @param subscriber Подписчик, создающий наблюдаемую переменную с ответом от сервера
	 * @private
	 */
	private techSupportMessage<R extends IAbstractResponse>(r: R, subscriber: Subscriber<R>): void {
		const msg = this.translateService.instant('dialogs.win-1201', {link: environment.techSupport});
		this.gameDialogsService.showInfoDialog(msg)
			.pipe(take(1))
			.subscribe(() => {
				subscriber.error(r);
				subscriber.complete();
			});
	}

	/**
	 * Парсер ответа от сервиса.
	 * Определяет содержит ли ответ коды ошибок. В случае ошибки показывает диалог с ошибкой.
	 * Если ответ не содержит ошибку, вызывает парсер состояния.
	 *
	 * @param {IAbstractResponse} response  Любой ответ от сервера (абстрактного типа)
	 * @param {BehaviorSubject<R>} subject Наблюдаемая переменная, которая содержит модифицированный ответ от сервера
	 * @param {(response: R) => R} parser Парсер ответа от сервера
	 * @returns {Observable<R>}
	 */
	private parseError<R extends IAbstractResponse>(
		response: IAbstractResponse, subject?: BehaviorSubject<R>, parser?: (response: R) => R
	): Observable<R> {
		Logger.Log.i(TAG, `parseError => started`)
			.console();

		return new Observable<R>(subscriber => {
			const r: R = response as R;
			r.timestamp = Date.now();

			if (response.R === ResultCode.Ok) {
				this.handleAsSuccess(r, response, subscriber, subject, parser);
			} else  {
				// потушить все оверлеи для показа диалога с ошибкой
				this.storeService.hideAllOverlayPanelsOnError$$.next(r);

				if (response.R === ResultCode.SID_Required || response.R === ResultCode.SID_SessionError) {
					Logger.Log.d(TAG, `parseError => perform signIn() due to specific errors`)
						.console();
					this.storeService.sid = undefined;
					this.externalCommunicationService.showExternalLoginDialog();
					subscriber.error(r);
					subscriber.complete();
				} else if (response.R === ResultCode.BigWin) {
					subject.next(r);
					this.gameStateService.parseGameStateByResponse(response);
					subscriber.error(r);
					subscriber.complete();
				} else if (response.R === ResultCode.UserHasOneTicket) {
					this.navigationService.navigateToLobby();
				} else if (response.action === Action.GetPromoProgress && response.R === ResultCode.CantFindPromos) {
					this.storeService.isPromoProgressMayBeRequested = false;
					this.storeService.getPromos$$.next(undefined);
					subscriber.error(r);
					subscriber.complete();
				} else {
					if (response.action === Action.FinishGame) {
						if (this.storeService.gameCode === MiniGamesCodes.Crash) {
							Logger.Log.d(TAG, `parseError => [${response.R}] user finished game after end of stream:`, this._streamData$$.value)
								.console();
							if (this._streamData$$.value.state === NextGameStepState.Lose) {
								this.storeService.gameState$$.next(GameState.ShowLosing);
							}
							if ((response as INextGameStepResponse).extra.state === NextGameStepState.EndOfGame) {
								let ngsResponse;
								if (!!subject) {
									ngsResponse = { ...response, ...(response as INextGameStepResponse).extra, R: ResultCode.Ok };
									this.storeService.nextGameStep$$.next(ngsResponse);

									this.storeService.gameState$$.next(GameState.ShowWin);
								}
								subscriber.next(ngsResponse);
							} else {
								subscriber.error(r);
							}
							// subscriber.error(r);
							subscriber.complete();
						} else {
							const ngsResponse = response as INextGameStepResponse;
							if (ngsResponse.extra) {
								this.storeService.gameState$$.next(GameState.ShowWin);
								this.handleAsSuccess(r, response, subscriber, subject, parser);
								this.techSupportMessage(r, subscriber);
							} else {
								this.handleAsDefault(r, response, subscriber);
							}
						}
					} else if (response.action === Action.NextGameStep) {
						this.storeService.gameState$$.next(GameState.GamePaused);
						const ngsResponse = response as INextGameStepResponse;
						if (ngsResponse.extra && (
								ngsResponse.extra.state === NextGameStepState.Lose ||
								ngsResponse.extra.state === NextGameStepState.EndOfGame ||
								ngsResponse.extra.state === NextGameStepState.BigWin
						)) {
							this.handleAsSuccess(r, response, subscriber, subject, parser);
							if (ngsResponse.extra.state === NextGameStepState.EndOfGame ||
								ngsResponse.extra.state === NextGameStepState.BigWin) {
								this.techSupportMessage(r, subscriber);
							}
						} else {
							this.handleAsDefault(r, response, subscriber);
						}
					} else {
						this.handleAsDefault(r, response, subscriber);
					}
				}
			}
		});
	}
}
