import {Action} from '@app/core/services/network/enums/action.enum';
import {mock_number, seq, sid, ticket_id} from '@app/core/services/network/mocks/constants';
import {ResultCode} from "@app/core/services/network/enums/result-code.enum";
import {MiniGamesCodes} from "@app/core/enums/mini-games-list.enum";
import {MockType} from "@app/core/services/network/mocks/types";

/**
 * Выбранные серии по каждой игре
 */
const seriesOfGames = {
	[MiniGamesCodes.Mines]: 10,
	[MiniGamesCodes.Tower]: 24,
	[MiniGamesCodes.Smile]: 34,
	[MiniGamesCodes.Tod]: 39,
	[MiniGamesCodes.Buhta]: 504,
	[MiniGamesCodes.Color]: 901
};

/**
 * Массив mock-запросов и mock-ответов
 */
const reqResp: Array<MockType> = [];
for (const gameCode of Object.keys(seriesOfGames)) {
	reqResp.push({
		request: {
			action: Action.GetLastTicket,
			game_code: +gameCode,
			sid,
			seq
		},
		response: {
			action: Action.GetLastTicket,
			R: ResultCode.Ok,
			ab: 1,
			game_code: +gameCode,
			series_code: seriesOfGames[gameCode],
			seq,
			ticket: {
				bet: 100,
				id: ticket_id,
				number: mock_number
			}
		}
	});
}

reqResp.push({
	request: {
		action: Action.GetLastTicket,
		sid,
		seq
	},
	response: {
		action: Action.GetLastTicket,
		R: ResultCode.Ok,
		ab: 1,
		seq
	}
});

/**
 * Массив mock-запросов и mock-ответов на получение информации по последнему не доигранному билету
 */
export const GetLastTicket = reqResp;
