import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { CdkStepperModule } from '@angular/cdk/stepper';

import { TranslateModule } from '@ngx-translate/core';
import { ToolTipModule } from '@toolTip/tool-tip.module';

import { ArrowButtonComponent } from '@app/shared/components/arrow-button/arrow-button.component';
import { AutoStepComponent } from '@app/shared/components/auto-step/auto-step.component';
import { BaseSelectorComponent } from '@app/shared/components/base-selector/base-selector.component';
import { BombCountSelectorComponent } from '@app/shared/components/bomb-count-selector/bomb-count-selector.component';
import { ContinueButtonComponent } from '@app/shared/components/continue-button/continue-button.component';
import { CountItemsComponent } from '@app/shared/components/count-items/count-items.component';
import { CrossCloseButtonComponent } from '@app/shared/components/cross-close-button/cross-close-button.component';
import { GameBottomPanelComponent } from '@app/shared/components/game-bottom-panel/game-bottom-panel.component';
import { GameFieldSelectorComponent } from '@app/shared/components/game-field-selector/game-field-selector.component';
import { GameRowFieldComponent } from '@app/shared/components/game-row-field/game-row-field.component';
import { SmileButtonComponent } from '@app/shared/components/game-row-field/smile-button/smile-button.component';
import { TowerButtonComponent } from '@app/shared/components/game-row-field/tower-button/tower-button.component';
import { MinesOddsComponent } from '@app/shared/components/mines-odds/mines-odds.component';
import { OddsInputComponent } from '@app/shared/components/odds-input/odds-input.component';
import { PlayButtonComponent } from '@app/shared/components/play-button/play-button.component';
import { PlusMinusButtonComponent } from '@app/shared/components/plus-minus-button/plus-minus-button.component';
import { StopButtonComponent } from '@app/shared/components/stop-button/stop-button.component';
import { TakeWinComponent } from '@app/shared/components/take-win/take-win.component';
import { TutorialStepperComponent } from '@app/shared/components/tutorial/tutorial-stepper/tutorial-stepper.component';
import { TutorialComponent } from '@app/shared/components/tutorial/tutorial/tutorial.component';
import { WinSumComponent } from '@app/shared/components/win-sum/win-sum.component';

import { AnimatedSpriteDirective } from '@app/shared/directives/animated-sprite.directive';
import { BaseSelectorScrollDirective } from '@app/shared/directives/base-selector-scroll.directive';
import { MaxHeightDirective } from '@app/shared/directives/max-height.directive';

import { BuhtaOddsComponent } from '@app/shared/components/buhta-odds/buhta-odds.component';
import { SquidOddsComponent } from '@app/shared/components/squid-odds/squid-odds.component';
import { NumsuffixesPipe } from '@app/shared/pipes/numsuffixes.pipe';
import { OddsPipe } from '@app/shared/pipes/odds.pipe';

/**
 * Модуль с общими компонентами без внешних зависимостей.
 */
@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		TranslateModule.forChild(),
		CdkStepperModule,
		ToolTipModule
	],
	declarations: [
		ArrowButtonComponent,
		PlusMinusButtonComponent,
		BaseSelectorComponent,
		TakeWinComponent,
		AutoStepComponent,
		CountItemsComponent,
		GameFieldSelectorComponent,
		BombCountSelectorComponent,
		MinesOddsComponent,
		BuhtaOddsComponent,
		GameRowFieldComponent,
		PlayButtonComponent,
		StopButtonComponent,
		ContinueButtonComponent,
		OddsInputComponent,
		TutorialComponent,
		CrossCloseButtonComponent,
		GameBottomPanelComponent,

		TowerButtonComponent,
		SmileButtonComponent,
		TutorialStepperComponent,
		WinSumComponent,

		AnimatedSpriteDirective,
		BaseSelectorScrollDirective,
		MaxHeightDirective,

		OddsPipe,

		NumsuffixesPipe,
		SquidOddsComponent
	],
	exports: [
		TranslateModule,
		ToolTipModule,

		ArrowButtonComponent,
		PlusMinusButtonComponent,
		BaseSelectorComponent,
		TakeWinComponent,
		AutoStepComponent,
		CountItemsComponent,
		GameFieldSelectorComponent,
		BombCountSelectorComponent,
		MinesOddsComponent,
		GameRowFieldComponent,
		PlayButtonComponent,
		StopButtonComponent,
		ContinueButtonComponent,
		OddsInputComponent,
		TutorialComponent,
		CrossCloseButtonComponent,
		GameBottomPanelComponent,

		AnimatedSpriteDirective,
		MaxHeightDirective,

		OddsPipe,
		BuhtaOddsComponent,
		NumsuffixesPipe,
		SquidOddsComponent
	]
})
export class SharedModule {}
