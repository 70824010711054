import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as Hammer from 'hammerjs';
import { AppRoutingModule } from '@app/app-routing.module';
import { CoreModule } from '@app/core/core.module';
import { SharedModule } from '@app/shared/shared.module';
import { GameDialogsModule } from '@dialogs/game-dialogs.module';
import { GameListShortModule } from '@gameListShort/game-list-short.module';
import { MenuHamburgerModule } from '@hamburger/menu-hamburger.module';
import { PreloaderModule } from '@preloader/preloader.module';
import { TopPanelModule } from '@topPanel/top-panel.module';
import { WinTableModule } from '@winTable/win-table.module';
import { AppComponent } from '@app/app.component';
import { CookieService } from 'ngx-cookie-service';

/**
 * Функция-загрузчик переводов
 * @param http Http-клиент
 */
export const httpLoaderFactory = (http: HttpClient): TranslateLoader =>
	new TranslateHttpLoader(http, 'assets/i18n/', `.json?${Date.now()}`);

/**
 * Конфигурация библиотеки HammerJS
 */
@Injectable()
export class HammerConfig extends HammerGestureConfig {
	/**
	 * Объект с переопределяемыми полями конфигурации
	 */
	overrides = {
		pan: {
			enable: true,
			direction: Hammer.DIRECTION_VERTICAL,
			threshold: 5
		},
		swipe: {
			enable: true,
			direction: Hammer.DIRECTION_ALL,
			threshold: 10
		}
	};
}

/**
 * Корневой модуль приложения
 */
@NgModule({
	declarations: [
		AppComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		HttpClientModule,
		CoreModule,
		SharedModule,
		GameDialogsModule,
		GameListShortModule,
		TopPanelModule,
		WinTableModule.forRoot(),
		MenuHamburgerModule,
		PreloaderModule,
		HammerModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				deps: [HttpClient],
				useFactory: httpLoaderFactory
			}
		})
	],
	providers: [
		{
			provide: HAMMER_GESTURE_CONFIG,
			useClass: HammerConfig
		},
		CookieService
		// { 	provide: APP_BASE_HREF,
		// 	useValue: environment.baseHref
		// }
	],
	bootstrap: [
		AppComponent
	]
})
export class AppModule {}
