import { Injectable } from '@angular/core';

import { IFrameEventsService } from '@app/core/services/external/iframe/iframe-events.service';
import { MobileService } from '@app/core/services/external/mobile/mobile.service';
import { Logger } from '@app/core/services/log/log.service';
import { StoreService } from '@app/core/services/store/store.service';

import { environment } from '@app/environments/environment';
import { ModuleRouteId } from '@app/utils/route-utils';
import { adjustIFrameToContent } from '@app/utils/utils';

/**
 * Тег для логирования
 */
const TAG = 'ExternalCommunicationService';

/**
 * Сервис для взаимодействия с внешними фреймами, в которых расположено приложение.
 */
@Injectable({
	providedIn: 'root'
})
export class ExternalCommunicationService {

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса
	 *
	 * @param {IFrameEventsService} iframeEventsService Сервис взаимодействия с сайтом
	 * @param {MobileService} mobileService Сервис по взаимодействию с мобильным приложением
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 */
	constructor(
		private readonly iframeEventsService: IFrameEventsService,
		private readonly mobileService: MobileService,
		private readonly storeService: StoreService
	) {}

	/**
	 * Показать внешнее окно авторизации.
	 */
	showExternalLoginDialog(): void {
		Logger.Log.i(TAG, `showExternalLoginDialog`)
			.console();

		adjustIFrameToContent();

		if (environment.isMobile) {
			this.mobileService.refreshAuthToken();
		} else {
			this.iframeEventsService.signIn();

			if (!environment.production) {
				this.storeService.sid = prompt('Login');
			}
		}
	}

	/**
	 * Обновить баланс во внешнем фрейме (web или мобильном).
	 */
	updateBalanceOnHostView(): void {
		Logger.Log.i(TAG, `updateBalanceOnHostView`)
			.console();

		if (environment.isMobile) {
			// ...
		} else {
			this.iframeEventsService.updateBalance();
		}
	}

	/**
	 * Сообщить внешним контейнерам об изменении текущего маршрута.
	 *
	 * @param {ModuleRouteId} moduleIdFrom С какого маршрута
	 * @param {ModuleRouteId} moduleIdTo На какой
	 */
	changeLocation(moduleIdFrom: ModuleRouteId, moduleIdTo: ModuleRouteId): void {
		Logger.Log.i(TAG, `changeLocation =>`, moduleIdFrom, moduleIdTo)
			.console();

		if (environment.isMobile) {
			this.mobileService.changeLocation(moduleIdFrom, moduleIdTo);
		} else {
			// ...
		}
	}

}
