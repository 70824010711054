/**
 * Mock-токен пользователя
 */
export const sid = 'mock_sid';
/**
 * Mock-ID билета
 */
export const ticket_id = 'mockID';
/**
 * Mock-идентификатор запроса и ответа
 */
export const seq = 111111;
/**
 * Mock-номер билета
 */
export const mock_number = 'MOCK 1234 5678 9123';
