
<div class="cab-mob" [class.cab-mob_logged-in]="storeService.sid"
	 data-cy="cab-mob"
>
	<a *ngIf="storeService.sid" target="_blank" href="{{ cab }}"
	   class="table-button table-button_cabinet-mob"
	   data-cy="table-button-cabinet-mob"
	>
		<svg class="table-button__icon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg"
			 data-cy="table-button-icon"
		>
			<path d="M5 0C3.54611 0 2.36328 1.18283 2.36328 2.63672C2.36328 4.09061 3.54611 5.27344 5 5.27344C6.45389 5.27344 7.63672 4.09061 7.63672 2.63672C7.63672 1.18283 6.45389 0 5 0Z" fill="#4E4B3E"/>
			<path d="M8.28059 6.996C7.55871 6.26303 6.60174 5.85938 5.58594 5.85938H4.41406C3.39828 5.85938 2.44129 6.26303 1.71941 6.996C1.00107 7.72537 0.605469 8.68814 0.605469 9.70703C0.605469 9.86883 0.736641 10 0.898438 10H9.10156C9.26336 10 9.39453 9.86883 9.39453 9.70703C9.39453 8.68814 8.99893 7.72537 8.28059 6.996Z" fill="#4E4B3E"/>
		</svg>
		{{ 'common.cabinet-mob' | translate }}
	</a>
</div>

<button *ngIf="!hideCloseButton"
		type="button"
		class="close-button"
		(click)="onCloseTable()"
		data-cy="close-button"
></button>

<div class="my-wins"
	 data-cy="my-wins"
>
	<button class="table-button table-button_my"
			(click)="onWinFeedButtonClick(WinTableFeedButton.My)"
			[disabled]="(storeService.activeWinFeed$$ | async) === WinTableFeedButton.My"
			[innerHTML]="'win-feed.my-bets' | translate"
			data-cy="table-button-my"
	></button>

	<a *ngIf="storeService.sid"
	   target="_blank"
	   href="{{ cab }}"
	   class="table-button table-button_cabinet"
	   data-cy="table-button-cabinet"
	>{{ 'common.cabinet' | translate }}</a>

	<!--			<app-win-table-legend class="win-table-legend"
				*ngIf="hasPromo && (storeService.activeWinFeed$$ | async) === WinTableFeedButton.Promo"
			></app-win-table-legend>-->
</div>

<div class="any-wins"
	 data-cy="any-wins"
>
	<button class="table-button table-button_all"
			(click)="onWinFeedButtonClick(WinTableFeedButton.All)"
			[disabled]="(storeService.activeWinFeed$$ | async) === WinTableFeedButton.All"
			[innerHTML]="'win-feed.all-bets' | translate"
			data-cy="table-button-all"
	></button>

	<button class="table-button table-button_hot"
			(click)="onWinFeedButtonClick(WinTableFeedButton.Hot)"
			[disabled]="(storeService.activeWinFeed$$ | async) === WinTableFeedButton.Hot"
			[innerHTML]="'win-feed.hot-moments' | translate"
			data-cy="table-button-hot"
	></button>
</div>

<!--
<div *ngIf="storeService.activePromo" class="tournament">
	<button	class="table-button table-button_promo"
			   (click)="onWinFeedButtonClick(WinTableFeedButton.Promo)"
			   [disabled]="(storeService.activeWinFeed$$ | async) === WinTableFeedButton.Promo"
			   [innerHTML]="('win-feed.promo' | translate) + (zeroTime ? '' : timeRemaining)"
	></button>

	<input class="star-radio" type="radio" name="star" id="star_orange"
		   (change)="onLeagueChange('orangeLeaders')"
		   [attr.checked]="(winTableService.leadersType$$.value === 'orangeLeaders') || undefined"
	>
	<label for="star_orange" class="star-btn star-btn_orange">
		<span class="star-btn__pic star-pic star-pic_orange"></span>
	</label>
	<input class="star-radio" type="radio" name="star" id="star_blue"
		   (change)="onLeagueChange('blueLeaders')"
		   [attr.checked]="(winTableService.leadersType$$.value === 'blueLeaders') || undefined"
	>
	<label for="star_blue" class="star-btn star-btn_blue">
		<span class="star-btn__pic star-pic star-pic_blue"></span>
	</label>
</div>	
-->
