import {Action} from "@app/core/services/network/enums/action.enum";
import {mock_number, seq, sid, ticket_id} from "@app/core/services/network/mocks/constants";
import {ResultCode} from "@app/core/services/network/enums/result-code.enum";
import {MiniGamesCodes} from "@app/core/enums/mini-games-list.enum";
import {NextGameStepState} from "@app/core/services/network/enums/next-game-step-state";

/**
 * Массив mock-запросов и mock-ответов на получение дополнительных данных по последнему не доигранному билету
 */
export const GetLastTicketData = [
	{
		request: {
			action: Action.GetLastTicketData,
			game_code: MiniGamesCodes.Mines,
			series_code: 10,
			sid,
			seq,
			ticket_id
		},
		response: {
			R: ResultCode.Ok,
			action: Action.GetLastTicketData,
			open: [0],
			seq,
			state: NextGameStepState.Win,
			step: 1,
			sum: 102
		}
	},
	{
		request: {
			action: Action.GetLastTicketData,
			game_code: MiniGamesCodes.Tower,
			series_code: 24,
			sid,
			seq,
			ticket_id
		},
		response: {
			R: ResultCode.Ok,
			action: Action.GetLastTicketData,
			open: [0],
			lose: [5],
			seq,
			state: NextGameStepState.Win,
			step: 1,
			sum: 108
		}
	},
	{
		request: {
			action: Action.GetLastTicketData,
			game_code: MiniGamesCodes.Smile,
			series_code: 34,
			sid,
			seq,
			ticket_id
		},
		response: {
			R: ResultCode.Ok,
			action: Action.GetLastTicketData,
			open: [0],
			lose: [4],
			seq,
			state: NextGameStepState.Win,
			step: 1,
			sum: 108
		}
	},
	{
		request: {
			action: Action.GetLastTicketData,
			game_code: MiniGamesCodes.Tod,
			series_code: 39,
			sid,
			seq,
			ticket_id
		},
		response: {
			R: ResultCode.Ok,
			action: Action.GetLastTicketData,
			open: [{cell: 0, cat: [ 4, 5, 6, 3 ]}],
			score: [0, 0, 1, 1, 1, 1],
			seq,
			state: NextGameStepState.Win,
			step: 1,
			sum: 0
		}
	},
	{
		request: {
			action: Action.GetLastTicketData,
			game_code: MiniGamesCodes.Buhta,
			series_code: 504,
			sid,
			seq,
			ticket_id
		},
		response: {
			R: ResultCode.Ok,
			action: Action.GetLastTicketData,
			open: [0],
			seq,
			state: NextGameStepState.Win,
			step: 1,
			sum: 0
		}
	},
	{
		request: {
			action: Action.GetLastTicketData,
			game_code: MiniGamesCodes.Color,
			series_code: 901,
			sid,
			seq,
			ticket_id
		},
		response: {
			R: ResultCode.Ok,
			action: Action.GetLastTicketData,
			open: [{c: 0, s: 1}],
			seq,
			state: NextGameStepState.Win,
			step: 1,
			sum: 175
		}
	}
];
