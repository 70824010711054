/**
 * Mock-серии игры "Башня"
 */
export const TOWER_SERIES = [
	{
		"code": 18,
		"letters": "А",
		"default": false,
		"params": "{\"w\":4,\"h\":12,\"b\":1}",
		"koefs": [
			"1.20",
			"1.60",
			"2.10",
			"2.90",
			"3.80",
			"5.00",
			"6.80",
			"9.00",
			"12.00",
			"16.00",
			"21.00",
			"28.00"
		],
		"is_demo": false
	},
	{
		"code": 19,
		"letters": "Б",
		"default": false,
		"params": "{\"w\":4,\"h\":12,\"b\":2}",
		"koefs": [
			"1.80",
			"3.60",
			"7.20",
			"14.00",
			"29.00",
			"58.00",
			"115.00",
			"230.00",
			"460.00",
			"920.00",
			"1800.00",
			"3700.00"
		],
		"is_demo": false
	},
	{
		"code": 20,
		"letters": "В",
		"default": false,
		"params": "{\"w\":4,\"h\":9,\"b\":3}",
		"koefs": [
			"3.60",
			"14.00",
			"58.00",
			"230.00",
			"920.00",
			"3700.00",
			"15000.00",
			"59000.00",
			"235000.00"
		],
		"is_demo": false
	},
	{
		"code": 21,
		"letters": "Г",
		"default": true,
		"params": "{\"w\":5,\"h\":12,\"b\":1}",
		"koefs": [
			"1.13",
			"1.40",
			"1.75",
			"2.20",
			"2.80",
			"3.40",
			"4.30",
			"5.40",
			"6.70",
			"8.40",
			"10.50",
			"13.00"
		],
		"is_demo": false
	},
	{
		"code": 22,
		"letters": "Ґ",
		"default": false,
		"params": "{\"w\":5,\"h\":12,\"b\":2}",
		"koefs": [
			"1.50",
			"2.50",
			"4.10",
			"7.00",
			"12.00",
			"19.00",
			"32.00",
			"54.00",
			"89.00",
			"145.00",
			"250.00",
			"410.00"
		],
		"is_demo": false
	},
	{
		"code": 23,
		"letters": "Д",
		"default": false,
		"params": "{\"w\":5,\"h\":12,\"b\":3}",
		"koefs": [
			"2.25",
			"5.60",
			"14.00",
			"35.00",
			"88.00",
			"220.00",
			"550.00",
			"1370.00",
			"3400.00",
			"8600.00",
			"21500.00",
			"55000.00"
		],
		"is_demo": false
	},
	{
		"code": 24,
		"letters": "Е",
		"default": false,
		"params": "{\"w\":6,\"h\":12,\"b\":1}",
		"koefs": [
			"1.08",
			"1.30",
			"1.55",
			"1.90",
			"2.20",
			"2.70",
			"3.20",
			"3.90",
			"4.60",
			"5.60",
			"6.70",
			"8.00"
		],
		"is_demo": false
	},
	{
		"code": 25,
		"letters": "Є",
		"default": false,
		"params": "{\"w\":6,\"h\":12,\"b\":2}",
		"koefs": [
			"1.35",
			"2.00",
			"3.00",
			"4.60",
			"6.80",
			"10.00",
			"15.00",
			"23.00",
			"35.00",
			"52.00",
			"80.00",
			"120.00"
		],
		"is_demo": false
	},
	{
		"code": 26,
		"letters": "Ж",
		"default": false,
		"params": "{\"w\":6,\"h\":12,\"b\":3}",
		"koefs": [
			"1.80",
			"3.60",
			"7.20",
			"14.00",
			"29.00",
			"58.00",
			"115.00",
			"230.00",
			"460.00",
			"920.00",
			"1800.00",
			"3700.00"
		],
		"is_demo": false
	},
	{
		"code": 27,
		"letters": "З",
		"default": false,
		"params": "{\"w\":6,\"h\":12,\"b\":4}",
		"koefs": [
			"2.70",
			"8.10",
			"24.00",
			"73.00",
			"220.00",
			"650.00",
			"2000.00",
			"5900.00",
			"17500.00",
			"53000.00",
			"160000.00",
			"480000.00"
		],
		"is_demo": false
	}
];
