/**
 * Mock-серии игры "Сапер"
 */
export const MINES_SERIES = [{
	code: 1,
	letters: 'А',
	default: false,
	params: '{"w":3,"h":3,"b":1}',
	koefs: ['1.01', '1.15', '1.35', '1.60', '2.00', '2.70', '4.00', '8.00'],
	is_demo: false
}, {
	code: 2,
	letters: 'Б',
	default: false,
	params: '{"w":3,"h":3,"b":2}',
	koefs: ['1.15', '1.55', '2.15', '3.25', '5.40', '11.00', '32.00'],
	is_demo: false
}, {
	code: 3,
	letters: 'В',
	default: false,
	params: '{"w":3,"h":3,"b":3}',
	koefs: ['1.35', '2.15', '3.80', '7.60', '19.00', '75.00'],
	is_demo: false
}, {
	code: 4,
	letters: 'Г',
	default: false,
	params: '{"w":4,"h":4,"b":2}',
	koefs: ['1.03', '1.20', '1.35', '1.65', '2.00', '2.40', '3.00', '3.80', '5.10', '7.20', '11.00', '18.00', '35.00', '110.00'],
	is_demo: false
}, {
	code: 5,
	letters: 'Ґ',
	default: false,
	params: '{"w":4,"h":4,"b":3}',
	koefs: ['1.11', '1.35', '1.75', '2.30', '3.00', '4.20', '6.00', '9.00', '14.50', '25.00', '50.00', '125.00', '500.00'],
	is_demo: false
}, {
	code: 6,
	letters: 'Д',
	default: false,
	params: '{"w":4,"h":4,"b":4}',
	koefs: ['1.20', '1.60', '2.30', '3.30', '5.00', '7.80', '13.00', '23.00', '47.00', '110.00', '325.00', '1650.00'],
	is_demo: false
}, {
	code: 7,
	letters: 'Е',
	default: false,
	params: '{"w":4,"h":4,"b":5}',
	koefs: ['1.31', '1.95', '3.05', '5.00', '8.50', '15.50', '31.00', '70.00', '190.00', '655.00', '3900.00'],
	is_demo: false
}, {
	code: 8,
	letters: 'Є',
	default: false,
	params: '{"w":4,"h":4,"b":6}',
	koefs: ['1.44', '2.40', '4.20', '7.80', '15.60', '34.00', '86.00', '257.00', '1030.00', '7200.00'],
	is_demo: false
}, {
	code: 9,
	letters: 'Ж',
	default: false,
	params: '{"w":4,"h":4,"b":7}',
	koefs: ['1.60', '3.00', '6.00', '13.00', '31.00', '86.00', '286.00', '1290.00', '10300.00'],
	is_demo: false
}, {
	code: 10,
	letters: 'З',
	default: true,
	params: '{"w":5,"h":5,"b":3}',
	koefs: ['1.02', '1.17', '1.35', '1.55', '1.80', '2.15', '2.50', '3.00', '3.70', '4.55', '5.70', '7.25', '9.50', '12.50', '17.00', '25.00', '37.00', '60.00', '103.00', '205.00', '515.00', '2050.00'],
	is_demo: false
}, {
	code: 11,
	letters: 'И',
	default: false,
	params: '{"w":5,"h":5,"b":4}',
	koefs: ['1.07', '1.28', '1.55', '1.90', '2.35', '2.95', '3.70', '4.80', '6.25', '8.35', '11.40', '15.90', '23.00', '34.50', '54.00', '90.00', '162.00', '325.00', '760.00', '2275.00', '11500.00'],
	is_demo: false
}, {
	code: 12,
	letters: 'І',
	default: false,
	params: '{"w":5,"h":5,"b":5}',
	koefs: ['1.12', '1.40', '1.80', '2.35', '3.10', '4.10', '5.60', '7.70', '11.00', '16.00', '23.00', '37.00', '60.00', '103.00', '190.00', '380.00', '850.00', '2275.00', '8000.00', '48000.00'],
	is_demo: false
}, {
	code: 13,
	letters: 'Ї',
	default: false,
	params: '{"w":5,"h":5,"b":6}',
	koefs: ['1.18', '1.55', '2.15', '3.00', '4.00', '6.00', '9.00', '13.00', '20.00', '30.00', '55.00', '90.00', '175.00', '350.00', '750.00', '1900.00', '5500.00', '23000.00', '150000.00'],
	is_demo: false
}, {
	code: 14,
	letters: 'Й',
	default: false,
	params: '{"w":5,"h":5,"b":7}',
	koefs: ['1.25', '1.75', '2.50', '3.75', '5.50', '8.50', '13.50', '22.00', '38.00', '65.00', '125.00', '250.00', '550.00', '1300.00', '3600.00', '12000.00', '55000.00', '450000.00'],
	is_demo: false
}, {
	code: 15,
	letters: 'К',
	default: false,
	params: '{"w":5,"h":5,"b":8}',
	koefs: ['1.32', '2.00', '3.00', '4.75', '7.75', '13.00', '22.00', '40.00', '75.00', '150.00', '325.00', '755.00', '2000.00', '5900.00', '22000.00', '110000.00'],
	is_demo: false
}, {
	code: 16,
	letters: 'Л',
	default: false,
	params: '{"w":5,"h":5,"b":9}',
	koefs: ['1.40', '2.25', '3.70', '6.25', '11.00', '20.00', '38.00', '75.00', '160.00', '360.00', '920.00', '2600.00', '8300.00', '33500.00', '185000.00'],
	is_demo: false
}, {
	code: 17,
	letters: 'М',
	default: false,
	params: '{"w":5,"h":5,"b":10}',
	koefs: ['1.50', '2.55', '4.55', '8.35', '16.00', '32.00', '67.00', '150.00', '360.00', '1000.00', '2900.00', '10300.00', '45000.00', '270000.00'],
	is_demo: false
}];
