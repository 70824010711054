import { Action } from '@app/core/services/network/enums/action.enum';
import { seq } from '@app/core/services/network/mocks/constants';

/**
 * Mock запроса и ответа на получение информации для лобби
 */
export const GetLobbyData = {
	request: {
		action: Action.GetLobbyData,
		seq
	},
	response: {
		action: Action.GetLobbyData,
		R: 0,
		games: [
			{code: 407, name: 'color', icon: '1'},
			{code: 400, name: 'мины', icon: '1'},
			{code: 401, name: 'башни', icon: '1'},
			{code: 402, name: 'смайл', icon: '1'},
			{code: 403, name: 'Креш', icon: ''},
			{code: 404, name: 'скарби', icon: 'undefined'},
			{code: 405, name: 'harbor', icon: '1'},
			{code: 406, name: 'squid', icon: '1'}
		],
		seq
	}
};
