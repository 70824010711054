import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';

import { Logger } from '@app/core/services/log/log.service';

import { PreloaderComponent } from '@preloader/components/preloader/preloader.component';
import { PreloaderOverlayRef, PRELOADER_DATA_TOKEN } from '@preloader/constants/preloader-constants';
import { IPreloaderConfig } from '@preloader/interfaces/ipreloader-config';

/**
 * Тег для логирования
 */
const TAG = 'PreloaderService';

/**
 * Сервис для показа анимации во время загрузки приложения
 */
@Injectable({
	providedIn: 'root'
})
export class PreloaderService {

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Ссылка на слой прелоадера
	 * @private
	 */
	private preloaderOverlayRef: PreloaderOverlayRef;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {Injector} injector Инжектор
	 * @param {Overlay} overlay Ссылка на оверлей
	 */
	constructor(
		private readonly injector: Injector,
		private readonly overlay: Overlay
	) {}

	/**
	 * Показать прелоадер.
	 *
	 * @param {IPreloaderConfig} config Конфигурация прелоадера
	 */
	showPreloader(config?: IPreloaderConfig): void {
		Logger.Log.i(TAG, `showPreloader => config:`, config)
			.console();

		if (!this.preloaderOverlayRef) {
			this.preloaderOverlayRef = this.createPreloaderOverlayRef(config);
		}
	}

	/**
	 * Спрятать прелоадер
	 */
	hidePreloader(): void {
		this.preloaderOverlayRef?.close();
		this.preloaderOverlayRef = undefined;
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Создать слой для прелоадера
	 * @param config Конфигурация прелоадера
	 * @private
	 */
	private createPreloaderOverlayRef(config: IPreloaderConfig): PreloaderOverlayRef {
		const overlayConfig: OverlayConfig = this.getOverlayConfig();
		const overlayRef: OverlayRef = this.overlay.create(overlayConfig);
		const tooltipRef: PreloaderOverlayRef = new PreloaderOverlayRef(overlayRef);

		const injector = Injector.create({
			providers: [{
				provide: PRELOADER_DATA_TOKEN,
				useValue: config
			}]
		});
		const containerPortal: ComponentPortal<PreloaderComponent> = new ComponentPortal(PreloaderComponent, undefined, injector);
		overlayRef.attach(containerPortal);

		return tooltipRef;
	}

	/**
	 * Получить конфигурацию прелоадера
	 * @private
	 */
	private getOverlayConfig(): OverlayConfig {
		const scrollStrategy = this.overlay.scrollStrategies.reposition();
		const positionStrategy = this.overlay
			.position()
			.global()
			.left('0px')
			.top('0px');

		return new OverlayConfig({
			height: '100%',
			width: '100%',
			hasBackdrop: false,
			scrollStrategy,
			positionStrategy
		});
	}

}
