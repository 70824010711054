import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Компонент малого прелоадера
 */
@Component({
	selector: 'app-small-preloader',
	templateUrl: './small-preloader.component.html',
	styleUrls: ['./small-preloader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SmallPreloaderComponent  {

	// -----------------------------
	//  Input properties
	// -----------------------------
	/**
	 * Видимый ли малый прелоадер?
	 */
	@Input()
	isVisible: boolean;

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------

	

}
