import { Action } from '@app/core/services/network/enums/action.enum';
import { ResultCode } from '@app/core/services/network/enums/result-code.enum';
import { seq } from '@app/core/services/network/mocks/constants';

/**
 * Mock-запрос и mock-ответ по таблицам ставок
 */
export const Channel = {
	request: {
		action: Action.Channel,
		channel: 'winFeedAll',
		type: 1,
		operation: 'sub',
		seq
	},
	response: {
		action: Action.Channel,
		R: ResultCode.Ok,
		history: [{
			event: 'winEvent',
			feedType: 2,
			gameCode: 404,
			ticketId: '28ba614ee48629828a32f9750d4c5779',
			timeStamp: 'Mon, 31 May 2021 20:31:15 GMT',
			betAmount: 100000,
			winOdds: 1.25,
			winAmount: 125000,
			userName: 'bce**'
		}, {
			event: 'winEvent',
			feedType: 2,
			gameCode: 404,
			ticketId: '955979a59e3bb0c8f01cf7083878b433',
			timeStamp: 'Mon, 31 May 2021 20:31:25 GMT',
			betAmount: 100000,
			winOdds: 0,
			winAmount: 0,
			userName: 'bce**'
		}, {
			event: 'winEvent',
			feedType: 2,
			gameCode: 404,
			ticketId: 'c093f201620821dfebf11b771589f0d1',
			timeStamp: 'Mon, 31 May 2021 20:46:54 GMT',
			betAmount: 100000,
			winOdds: 0,
			winAmount: 0,
			userName: 'bce**'
		}, {
			event: 'winEvent',
			feedType: 2,
			gameCode: 404,
			ticketId: 'fa894b721fb9b552ae67fa8da3c9dba2',
			timeStamp: 'Mon, 31 May 2021 20:47:09 GMT',
			betAmount: 100000,
			winOdds: 0,
			winAmount: 0,
			userName: 'bce**'
		}, {
			event: 'winEvent',
			feedType: 2,
			gameCode: 404,
			ticketId: '93f41d572700d8aa4c3035956901ef17',
			timeStamp: 'Mon, 31 May 2021 20:47:14 GMT',
			betAmount: 100000,
			winOdds: 0,
			winAmount: 0,
			userName: 'bce**'
		}, {
			event: 'winEvent',
			feedType: 2,
			gameCode: 404,
			ticketId: '11a6c8b8fc50ba2c4fc59cf8f45ae28e',
			timeStamp: 'Mon, 31 May 2021 20:47:19 GMT',
			betAmount: 100000,
			winOdds: 0,
			winAmount: 0,
			userName: 'bce**'
		}, {
			event: 'winEvent',
			feedType: 2,
			gameCode: 404,
			ticketId: '602c2c6e6c08a5b487e97f46e2633d92',
			timeStamp: 'Mon, 31 May 2021 20:47:26 GMT',
			betAmount: 100000,
			winOdds: 1.25,
			winAmount: 125000,
			userName: 'bce**'
		}, {
			event: 'winEvent',
			feedType: 2,
			gameCode: 404,
			ticketId: 'f41f8f55cc264c4f216f018012d0a473',
			timeStamp: 'Mon, 31 May 2021 21:01:52 GMT',
			betAmount: 100000,
			winOdds: 0,
			winAmount: 0,
			userName: 'bce**'
		}, {
			event: 'winEvent',
			feedType: 2,
			gameCode: 404,
			ticketId: '418b28e62ced03fc928ee243a7870ef4',
			timeStamp: 'Mon, 31 May 2021 21:02:00 GMT',
			betAmount: 100000,
			winOdds: 1.25,
			winAmount: 125000,
			userName: 'bce**'
		}, {
			event: 'winEvent',
			feedType: 2,
			gameCode: 400,
			ticketId: '5a0117bea7d9bf9ad2cc6aea46822482',
			timeStamp: 'Thu, 03 Jun 2021 06:22:49 GMT',
			betAmount: 100000,
			winAmount: 200000,
			winOdds: 2,
			userName: 'bce**'
		}],
		seq
	}
};
