<div
	class="hm-wrapper"
	(swipe)="onClickCloseMenuHandler()"
	[ngClass]="state"
	>
	<app-cross-close-button
		(closeButtonClicked)="onClickCloseMenuHandler()"
	></app-cross-close-button>

	<div class="hm-games-wrapper">
		<div>
			<app-game-list-inner
				(selectedGame)="onClickCloseMenuHandler(true)"
			></app-game-list-inner>
			
			<span></span>

			<app-info-links
				[linkList]="hamburgerConfig.linkList"
				[orientation]="InfoLinksOrientation.Vertical"
				[isVisibleIcons]="false"
				(linkClick)="onClickCloseMenuHandler()"
			></app-info-links>
		</div>
	</div>
</div>
			
