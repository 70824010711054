/**
 * Список маршрутов возможных игр.
 */
export enum MiniGamesUrls {

	Mines           = 'mines',
	Tower           = 'tower',
	Smile           = 'smile',
	Crash           = 'crash',
	Tod             = 'gems',
	Buhta           = 'buhta',
	Squid			= 'squid',
	Color			= 'color'

	// ...
	// ... новые игры
	// ...

}

/**
 * Список возможных кодов игр.
 */
export enum MiniGamesCodes {

	Mines           = 400,
	Tower           = 401,
	Smile           = 402,
	Crash           = 403,
	Tod             = 404,
	Buhta			= 405,
	Squid			= 406,
	Color			= 407

	// ...
	// ... новые игры
	// ...

}
