import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@app/environments/environment';
import { AppModule } from '@app/app.module';

if (environment.production) {
	enableProdMode();
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.then(ref => {
		// ensure Angular destroys itself on hot reloads.
		if (window['ngRef']) {
			window['ngRef'].destroy();
		}

		window['ngRef'] = ref;
	})
	.catch(err => {
		// otherwise, log the boot error
		console.error(err);
	});

