import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ToolTipComponent } from '@toolTip/components/tool-tip/tool-tip.component';
import { ToolTipDirective } from '@toolTip/directives/tool-tip.directive';

@NgModule({
	imports: [
		CommonModule
	],
	exports: [
		ToolTipDirective
	],
	declarations: [
		ToolTipComponent,
		ToolTipDirective
	]
})
export class ToolTipModule {}
