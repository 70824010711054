/**
 * Mock-шаги в игре "Сокровища судьбы"
 */
export const TOD_STEPS = {
	601: [
		{	cell: 0,
			cat: [
				4,
				5,
				6,
				3
			]
		},
		{	cell: 1,
			cat: [
				6,
				5
			]
		},
		{	cell: 2,
			cat: [
				1,
				4,
				2
			]
		},
		{	cell: 3,
			cat: [
				2,
				6
			]
		},
		{	cell: 4,
			cat: [
				6,
				5
			]
		},
		{	cell: 5,
			cat: [
				3,
				5
			]
		},
		{	cell: 6,
			cat: [
				6,
				4,
				1
			]
		},
		{	cell: 7,
			cat: [
				6,
				4,
				3,
				5
			]
		},
		{	cell: 8,
			cat: [
				2,
				1
			]
		},
		{	cell: 9,
			cat: [
				5,
				6
			]
		},
		{	cell: 10,
			cat: [
				1,
				2,
				4
			]
		},
		{	cell: 11,
			cat: [
				4,
				5,
				2
			]
		},
		{	cell: 12,
			cat: [
				3,
				1,
				6
			]
		},
		{	cell: 13,
			cat: [
				4,
				3,
				2
			]
		},
		{	cell: 14,
			cat: [
				2,
				1
			]
		},
		{	cell: 15,
			cat: [
				3,
				2,
				4
			]
		},
		{	cell: 16,
			cat: [
				1,
				6,
				3
			]
		}
	],
	39: [
		{	cell: 0,
			cat: [
				3,
				1,
				5
			]
		},
		{	cell: 1,
			cat: [
				5,
				4,
				2,
				6
			]
		},
		{	cell: 2,
			cat: [
				1,
				3,
				5
			]
		},
		{	cell: 3,
			cat: [
				3,
				6,
				2
			]
		},
		{	cell: 4,
			cat: [
				1,
				2,
				5,
				6
			]
		},
		{	cell: 5,
			cat: [
				4,
				3,
				6
			]
		},
		{	cell: 6,
			cat: [
				5,
				6,
				4,
				2
			]
		},
		{	cell: 7,
			cat: [
				3,
				6,
				4
			]
		},
		{	cell: 8,
			cat: [
				6,
				2,
				1,
				5
			]
		},
		{	cell: 9,
			cat: [
				2,
				4,
				6,
				5
			]
		},
		{	cell: 10,
			cat: [
				1,
				5,
				2,
				4
			]
		},
		{	cell: 11,
			cat: [
				2,
				3
			]
		},
		{	cell: 12,
			cat: [
				4,
				1,
				6,
				3
			]
		},
		{	cell: 13,
			cat: [
				3,
				6,
				2
			]
		},
		{	cell: 14,
			cat: [
				3,
				5,
				1
			]
		},
		{	cell: 15,
			cat: [
				2,
				1,
				5,
				4
			]
		},
		{	cell: 16,
			cat: [
				6,
				4
			]
		},
		{	cell: 17,
			cat: [
				3,
				5,
				6
			]
		},
		{	cell: 18,
			cat: [
				6,
				2,
				5
			]
		},
		{	cell: 19,
			cat: [
				3,
				1,
				6,
				4
			]
		},
		{	cell: 20,
			cat: [
				1,
				3,
				6,
				4
			]
		},
		{	cell: 21,
			cat: [
				3,
				1,
				4,
				6
			]
		},
		{	cell: 22,
			cat: [
				1,
				2,
				5,
				4
			]
		},
		{	cell: 23,
			cat: [
				6,
				5,
				4
			]
		},
		{	cell: 24,
			cat: [
				5,
				6,
				2
			]
		}
	]
};
