<div
	class="il-wrapper"
	[ngClass]="orientation"
	(click)="onClickLinkItemHandler($event)"
	data-cy="il-wrapper"
	>

	<ng-container *ngFor="let link of linkList; trackBy: trackByLinkItems; let i = index">
		<app-info-label
			*ngIf="!link.hide"
			[infoText]="link.label"
			[iconUrl]="link?.iconUrl"
			[isVisibleIcons]="link.isVisibleIcons"
			[attr.index]="i"
			data-cy="il-label"
		></app-info-label>
	</ng-container>

</div>
