<!-- ЗАГОЛОВОК -->
<div class="base-selector-title"
	 [innerHTML]="title | translate"
	 data-cy="base-selector-title"
></div>

<!-- РУЧНОЙ ВВОД -->
<div
	class="selector-input-wrapper"
	data-cy="selector-input-wrapper"
	*ngIf="hasManualInput"
	>
	<!-- (-) -->
	<app-plus-minus-button
		class="at-left"
		*ngIf="isVisibleInputButtons"
		[iconType]="PlusMinusIconType.Minus"
		[disabled]="disabled || minusDisabled"
		(plusMinusButtonClicked)="onPlusMinusButtonClickedHandler($event)"
		data-cy="at-left"
	></app-plus-minus-button>
	<input
		class="manual-input"
		id="manual-input"
		type="text"
		inputmode="numeric"
		[class.full-width]="!isVisibleInputButtons"
		[disabled]="disabled"
		[value]="currentValue"
		(paste)="onPasteHandler($event)"
		(input)="onInputHandler($event)"
		(keydown)="onKeyDownHandler($event)"
		(keyup)="onKeyUpHandler($event)"
		(blur)="onBlurInputHandler($event)"
		data-cy="manual-input"
	/>
	<!-- (+) -->
	<app-plus-minus-button
		class="at-right"
		*ngIf="isVisibleInputButtons"
		[iconType]="PlusMinusIconType.Plus"
		[disabled]="disabled || plusDisabled"
		(plusMinusButtonClicked)="onPlusMinusButtonClickedHandler($event)"
		data-cy="at-right"
	></app-plus-minus-button>
</div>

<div *ngIf="warnHint1"
	 class="warn-tooltip"
	 data-cy="warn-tooltip"
>{{ 'common.sum_not_0' | translate }}</div>
<div *ngIf="warnHint2"
	 class="warn-tooltip"
	 data-cy="warn-tooltip"
>{{ 'common.sum_max_is' | translate:{X: maxValue} }}</div>

<!-- СТРОКА ВВОДА -->
<div class="selector-wrapper"
	 data-cy="selector-wrapper"
>
	
	<!-- (<) -->
	<app-arrow-button
		*ngIf="isVisibleArrows && selectors?.length > fullWidthCount"
		[orientation]="ArrowButtonOrientation.Left"
		(arrowButtonClicked)="onArrowButtonClickedHandler(-1)"
		data-cy="arrow-button-left"
	></app-arrow-button>

	<!-- СПИСОК ЗНАЧЕНИЙ -->
	<div
		class="bs-list-wrapper"
		appBaseSelectorScroll
		[currentPosition]="currentPosition"
		[fullWidthCount]="fullWidthCount"
		[isVisibleArrows]="isVisibleArrows"
		[selectors]="selectors"
		(wheel)="onWheelHandler($event)"
		(swipe)="onSwipeHandler($event)"
		data-cy="bs-list-wrapper"
		>
		<ul
			(click)="onItemClickHandler($event)"
			data-cy="bs-list"
			>
			<ng-container *ngFor="let selector of selectors; trackBy: trackByItemFn">
				<li
					[class.selected]="selector.selected"
					[id]="selector.id"
					[attr.disabled]="disabled"
					[innerHTML]="selector.label"
					[style.min-width]="selector.selectorWidth"
					data-cy="bs-list-item"
				></li>
			</ng-container>
		</ul>
	</div>

	<!-- (>) -->
	<app-arrow-button
		*ngIf="isVisibleArrows && selectors?.length > fullWidthCount"
		[orientation]="ArrowButtonOrientation.Right"
		(arrowButtonClicked)="onArrowButtonClickedHandler(1)"
		data-cy="arrow-button-right"
	></app-arrow-button>

</div>


