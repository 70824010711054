<div class="main-wrapper" data-cy="main-wrapper">
	<div class="main-list-with-content-wrapper"
		 data-cy="main-list-with-content-wrapper"
	>
		
		<!-- КОРОТКИЙ СПИСОК ИГР (ВЕРТИКАЛЬНЫЙ) -->
		<!--<app-game-list-short></app-game-list-short>-->
		
		<!-- КОНТЕНТ С ПАНЕЛЯМИ -->
		<div class="main-content-wrapper"
			 data-cy="main-content-wrapper"
		>
			<app-top-panel></app-top-panel>
			
			<div class="main-game-wrapper"
				 data-cy="main-game-wrapper"
			>
				<router-outlet></router-outlet>
			</div>
			
			<!-- ТАБЛИЦА ВЫИГРЫШЕЙ -->
			<app-win-tables
				*ngIf="isWinTableVisibleOnPage"
				class="win-tables"
				[class.win-tables_mobile-visible]="storeService.isWinTableVisible$$ | async"
				data-cy="win-tables"
			></app-win-tables>
		</div>

	</div>
</div>
