import { OverlayRef } from '@angular/cdk/overlay';
import { Logger } from '@app/core/services/log/log.service';

/**
 * Тег для логирования
 */
const TAG = 'WinTableOverlayRef';

/**
 * Класс-контейнер для оверлея выигрышных таблиц
 */
export class WinTableOverlayRef {

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор класса
	 *
	 * @param {OverlayRef} overlayRef Ссылка на оверлей
	 */
	constructor(
		private readonly overlayRef: OverlayRef
	) {}

	/**
	 * Закрыть диалог.
	 */
	close(): void {
		Logger.Log.i(TAG, `close => dialog`)
			.console();

		this.overlayRef.detach();
		this.overlayRef.dispose();
	}

}
