import { ChangeDetectionStrategy, Component } from '@angular/core';

/**
 * Компонент верхней панели
 */
@Component({
	selector: 'app-top-panel',
	templateUrl: './top-panel.component.html',
	styleUrls: ['./top-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopPanelComponent  {

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------

	

}
