import { Injectable } from '@angular/core';
import { environment } from '@app/environments/environment';

/**
 * Счетчик экземпляров сервиса
 */
let ccc = 0;

/**
 * Типы данных, поддерживаемых логером.
 */
export type FormatType = string | number | boolean | object | unknown;

/**
 * Интерфейс для вывода логов в браузер (консоль разработчика).
 */
export interface IConsole {
	/**
	 * Функция вывода в консоль
	 */
	console(): void;
}

/**
 * Интерфейс для вывода логгируемой информации разделенной по типам
 */
export interface ILogger {
	/**
	 * Вывод информации в консоль
	 *
	 * @param {string} tag Модуль, тег.
	 * @param {FormatType} args Аргументы шаблона.
	 * @returns {IConsole} Интерфейс для возможности управления выводом в консоль браузера.
	 */
	i(tag: string, ...args: Array<FormatType>): IConsole;
	/**
	 * Вывод предупреждения в консоль
	 *
	 * @param {string} tag Модуль, тег.
	 * @param {FormatType} args Аргументы шаблона.
	 * @returns {IConsole} Интерфейс для возможности управления выводом в консоль браузера.
	 */
	w(tag: string, ...args: Array<FormatType>): IConsole;
	/**
	 * Вывод ошибки в консоль
	 *
	 * @param {string} tag Модуль, тег.
	 * @param {FormatType} args Аргументы шаблона.
	 * @returns {IConsole} Интерфейс для возможности управления выводом в консоль браузера.
	 */
	e(tag: string, ...args: Array<FormatType>): IConsole;
	/**
	 * Вывод отладочной информации в консоль
	 *
	 * @param {string} tag Модуль, тег.
	 * @param {FormatType} args Аргументы шаблона.
	 * @returns {IConsole} Интерфейс для возможности управления выводом в консоль браузера.
	 */
	d(tag: string, ...args: Array<FormatType>): IConsole;
}

/**
 * Модель хранящая синглтон логгер-сервиса.
 */
export class Logger {

	/**
	 * Экземпляр класса логгирования
	 * @private
	 */
	private static _logger: ILogger = {
		i(tag: string, ...args): IConsole { return { console(): void {} }; },
		e(tag: string, ...args): IConsole { return { console(): void {} }; },
		d(tag: string, ...args): IConsole { return { console(): void {} }; },
		w(tag: string, ...args): IConsole { return { console(): void {} }; }
	};

	/**
	 * Геттер для возврата экземпляра класса логгирования
	 * @constructor
	 */
	static get Log(): ILogger {
		return Logger._logger;
	}

	/**
	 * Сеттер для присвоения ссылки на экземпляр класса логгирования
	 * @param value
	 * @constructor
	 */
	static set Log(value: ILogger) {
		Logger._logger = value;
	}

}

/**
 * Уровни логирования.
 */
enum LogType {
	/**
	 * Предупреждение
	 */
	Warning	= 'warning',
	/**
	 * Вывод отладочной информации
	 */
	Debug	= 'debug',
	/**
	 * Вывод информационного сообщения
	 */
	Info	= 'info',
	/**
	 * Ошибка
	 */
	Error	= 'error'
}

/**
 * Функция, которая ничего не делает
 */
const noOp = (): void => {};

/**
 * Функция преобразования аргументов в строку.
 *
 * @param {FormatType} args
 * @returns {string}
 */
const format = (...args: Array<FormatType>): string => {
	const separator = ' ';
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const parseTypes = (value: any): string => {
		if (value === undefined) {
			return 'undefined';
		} else if (value === null) {
			return 'null';
		} else if (typeof value === 'string') {
			return value;
		} else if (typeof value === 'number') {
			return `${value}`;
		} else if (typeof value === 'boolean') {
			return `${value}`;
		} else if (Array.isArray(value)) {
			const arr = value
				.map(parseTypes)
				.reduce((p, c, i) => `${p}${i > 0 ? separator : ''}${c}`, '');

			return `[${arr}]`;
		} else if (typeof value === 'object') {
			let obj: string;
			try {
				obj = JSON.stringify(value);
			} catch (e) {
				obj = `{too much complex object}`;
			}

			return obj;
		}

		return value.toString();
	};

	return args
		.map(parseTypes)
		.reduce((p, c, i) => `${p}${i > 0 ? separator : ''}${c}`, '');
};

/**
 * Сервис логирования.
 */
@Injectable({
	providedIn: 'root'
})
export class LogService implements ILogger {

	/**
	 * Конструктор сервиса
	 */
	constructor() {
		console.log('~~~ LogService', ccc++);
		Logger.Log = this;
	}

	// -----------------------------
	//  ILogger
	// -----------------------------

	/**
	 * Уровень логирования INFO.
	 *
	 * @param {string} tag Модуль, тег.
	 * @param {FormatType} args Аргументы шаблона.
	 * @returns {IConsole} Интерфейс для возможности управления выводом в консоль браузера.
	 */
	i(tag: string, ...args: Array<FormatType>): IConsole {
		const message = format(...args);
		this.log(tag, LogType.Info, message);

		return this.console(LogType.Info, message);
	}

	/**
	 * Уровень логирования WARNING.
	 *
	 * @param {string} tag Модуль, тег.
	 * @param {FormatType} args Аргументы шаблона.
	 * @returns {IConsole} Интерфейс для возможности управления выводом в консоль браузера.
	 */
	w(tag: string, ...args: Array<FormatType>): IConsole {
		const message = format(...args);
		this.log(tag, LogType.Warning, message);

		return this.console(LogType.Warning, message);
	}

	/**
	 * Уровень логирования ERROR.
	 *
	 * @param {string} tag модуль, тег
	 * @param {FormatType} args аргументы шаблона
	 * @returns {IConsole} интерфейс для возможности управления выводом в консоль браузера
	 */
	e(tag: string, ...args: Array<FormatType>): IConsole {
		const message = format(...args);
		this.log(tag, LogType.Error, message);

		return this.console(LogType.Error, message);
	}

	/**
	 * Уровень логирования DEBUG.
	 *
	 * @param {string} tag модуль, тег
	 * @param {FormatType} args аргументы шаблона
	 * @returns {IConsole} интерфейс для возможности управления выводом в консоль браузера
	 */
	d(tag: string, ...args: Array<FormatType>): IConsole {
		// if (environment.production) {
		// 	return {console: noOp};
		// }

		const message = format(...args);
		this.log(tag, LogType.Debug, message);

		return this.console(LogType.Debug, message);
	}

	/**
	 * Отправка запроса на сервис.
	 *
	 * @param {string} moduleName Модуль, тег.
	 * @param {LogType} logType Уровень логирования.
	 * @param {string} message Сообщение для логирования.
	 */
	private readonly log = (moduleName: string, logType: LogType, message: string): void => {
		const logItem = {
			timestamp: `${Date.now()}`,
			moduleName,
			logType,
			message
		};
		// TODO
	}

	/**
	 * Позволяет вывести сообщение в консоль браузера, если приложение запущено в режиме разработки.
	 *
	 * @param {LogType} type Уровень логирования.
	 * @param {string} message Сообщение для логирования.
	 * @returns {IConsole} Интерфейс для возможности управления выводом в консоль браузера.
	 */
	private readonly console = (type: LogType, message: string): IConsole => {
		if (environment.debug || !environment.production) {
			switch (type) {
				case LogType.Info:
					return {console: console.info.bind(console, `%c${message}`, 'color:green')};
				case LogType.Warning:
					return {console: console.warn.bind(console, message)};
				case LogType.Error:
					return {console: console.error.bind(console, message)};
				case LogType.Debug:
					return {console: console.debug.bind(console, `%c${message}`, 'color:gray')};
				default:
					break;
			}
		}

		return { console: noOp };
	}

}
