import { Injectable } from '@angular/core';
import { GameState } from '@app/core/services/state/enums/game-state.enum';
import { StoreService } from '@app/core/services/store/store.service';

/**
 * Сервис показа сообщения о покупке билета
 */
@Injectable({
	providedIn: 'root'
})
export class BuyTicketMessageService {
	/**
	 * Первый ли раз кликнули на игровое поле?
	 */
	firstTimeClicked = true;
	/**
	 * Показывать ли сообщение о покупке билета?
	 */
	buyTicketHint = false;

	/**
	 * Конструктор сервиса
	 * @param storeService Сервис-хранилище приложения
	 */
	constructor(private readonly storeService: StoreService) {
	}

	/**
	 * Может ли быть показано сообщение о покупке билета?
	 */
	get canBeShown(): boolean {
		return this.buyTicketHint && this.storeService.gameState$$.value === GameState.BuyTicket;
	}

	/**
	 * Установить параметры по-умолчанию
	 */
	setDefaults(): void {
		this.firstTimeClicked = true;
		this.buyTicketHint = false;
	}
}
