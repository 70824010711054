import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Channel } from '@app/core/services/network/enums/channel.enum';
import { WinFeedType } from '@app/core/services/network/enums/win-feed-type';
import { IPromoScoreTable } from '@app/core/services/network/interfaces/responses/iget-promo-progress-response';
import { IWinFeedEventData } from '@app/core/services/network/interfaces/responses/iwin-feed-history-response';
import { StoreService } from '@app/core/services/store/store.service';
import { convertApplicationLocaleToSite } from '@app/utils/localization-utils';
import { TranslateService } from '@ngx-translate/core';
import {
	DEFAULT_WIN_FEED,
	getWinFeedChannelByType,
	WinFeedTableItem,
	WIN_TABLE_SIZE
} from '@winTable/constants/win-table-constants';
import { WinTableService } from '@winTable/services/win-table.service';
import { interval, Subscription, timer } from 'rxjs';
import { delay, tap } from 'rxjs/operators';

/**
 * Тег для логирования
 */
const TAG = 'WinTableComponent';

/**
 * Компонент таблицы ставок
 */
@Component({
	selector: 'app-win-table',
	templateUrl: './win-table.component.html',
	styleUrls: ['./win-table.component.scss']
})
export class WinTableComponent implements OnInit, OnDestroy {

	/**
	 * Текущий канал событий
	 */
	channel: Channel;

	/**
	 * Тип выигрышного канала для показа в таблице
	 */
	@Input() winFeedType: WinFeedType = DEFAULT_WIN_FEED;

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Список каналов событий
	 */
	readonly Channel = Channel;
	
	/**
	 * Список типов выигрышных каналов
	 */
	readonly WinFeedType = WinFeedType;

	/**
	 * Код промо-акции
	 */
	promoCode: number;

	/**
	 * Должна ли быть анимация строк при добавлении новой записи в таблицу?
	 */
	isAnimated = false;

	/**
	 * Признак занятости компонента анимацией
	 */
	busy = false;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Подписка на канал ставок
	 * @private
	 */
	private activeListenSubscription: Subscription;

	/**
	 * Конструктор компонента
	 * @param storeService Сервис-хранилище приложения
	 * @param winTableService Сервис таблиц выигрыша
	 * @param translateService Сервис переводов
	 * @param doc Ссылка на DOM-документ
	 */
	constructor(
				readonly storeService: StoreService,
				readonly winTableService: WinTableService,
				readonly translateService: TranslateService,
				@Inject(DOCUMENT) private doc: Document
	) { }

	/**
	 * Вспомогательная функция для ускорения вывода информации в шаблон
	 * @param index Индекс элемента
	 * @param item Элемент
	 */
	readonly trackByFeedFn = (index, item: WinFeedTableItem | IPromoScoreTable) => {
		if (!item) {
			return '';
		}

		return 'ticketId' in item ? `${item.ticketId}` : `${item.login}${item.place}`;
	}

	/**
	 * Обработчик клика по ссылке на акцию
	 */
	onLinkClickHandler(): void {
		const lang = convertApplicationLocaleToSite(this.translateService.currentLang);
		window.open(`${location.origin}/cabinet/${lang}/static/september_champ`, '_parent ');
	}

	/**
	 * Функция анимированной вставки строк в таблицу
	 * @param row Строка для вставки
	 * @private
	 */
	private waitToAddRows(row: IWinFeedEventData): void {
		const intr = interval(50)
			.subscribe(() => {
				if (!this.busy) {
					intr.unsubscribe();
					this.channel = getWinFeedChannelByType(this.winFeedType);
					if (this.channel === Channel.WinFeedAll) {
						if ((new Date(row.timeStamp)).getTime() > this.winTableService.maxTimeStamp) {
							this.winTableService.maxTimeStamp = (new Date(row.timeStamp)).getTime();
						} else {
							return;
						}
					}
					this.busy = true;
					row.gray = this.winTableService.winFeedTable[this.channel][0] && !this.winTableService.winFeedTable[this.channel][0].gray;
					this.winTableService.winFeedTable[this.channel].unshift(row);
					const tmr = timer(200)
						.pipe(
							tap(() => {
								// console.log('2 stage');
								(this.doc.getElementsByClassName(`win-table__row_${this.channel}`)[0] as HTMLDivElement).style.display = 'flex';
								(this.doc.getElementsByClassName(`win-table-content_${this.channel}`)[0] as HTMLDivElement).style.marginTop = '-40px';
							}),
							delay(200),
							tap(() => {
								// console.log('3 stage');
								this.isAnimated = true;
							}),
							delay(200),
							tap(() => {
								// console.log('4 stage');
								(this.doc.getElementsByClassName(`win-table-content_${this.channel}`)[0] as HTMLDivElement).style.marginTop = '0';
							}),
							delay(500),
							tap(() => {
								// console.log('5 stage', this.winTableService.winFeedTable);
								this.isAnimated = false;
								for (const tableRow of this.winTableService.winFeedTable[this.channel]) {
									tableRow.animated = false;
								}
								if (this.winTableService.winFeedTable[this.channel].length > WIN_TABLE_SIZE) {
									this.winTableService.winFeedTable[this.channel].pop();
								}
								this.busy = false;
							})
						)
						.subscribe(() => {
							tmr.unsubscribe();
						});
				}
			});
	}

	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.channel = getWinFeedChannelByType(this.winFeedType);
		this.winTableService.winFeedTable[this.channel] = this.winTableService.winFeedTable[this.channel].slice(0, 10);
		if (!this.activeListenSubscription) {
			this.activeListenSubscription = this.winTableService.addedRows[this.channel]
				.subscribe(rows => {
					this.waitToAddRows(rows[0]);
				});
		}
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		if (this.activeListenSubscription) {
			this.activeListenSubscription.unsubscribe();
			this.activeListenSubscription = undefined;
		}
	}
}
