import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

import { MiniGamesCodes } from '@app/core/enums/mini-games-list.enum';
import { Logger } from '@app/core/services/log/log.service';
import { WinFeedType } from '@app/core/services/network/enums/win-feed-type';
import { StoreService } from '@app/core/services/store/store.service';

/**
 * Модель пользовательских данных по игре, сохраняемые в localStorage браузера.
 */
export interface IStoreData {

	/**
	 * Время последнего запуска.
	 */
	lastRun?: number;

	/**
	 * Последняя выбранная сумма ставки.
	 */
	betAmount?: number;

	/**
	 * Последний выбранный тип в компоненте ставок Live.
	 */
	winFeed?: WinFeedType;

	/**
	 * Код последней выбранной игры.
	 */
	selectedGame?: MiniGamesCodes;

	// ...
	// ... TODO дополнить параметры модели
	// ...

}

/**
 * Тег для логирования
 */
const TAG = 'CustomGameDataService';

/**
 * Список возможных ключей для работы с {@link localStorage}.
 * - {@link AllApplicationData} - параметры для всего приложения
 */
enum LocalStorageKeys {
	/**
	 * Параметры для всего приложения
	 */
	AllApplicationData = 'APP'
}

/**
 * Обновить данные в локальном хранилище браузера
 * @param {MiniGamesCodes | string} storeKey
 * @param {IStoreData} value
 * @returns {IStoreData}
 */
const updateStoreData = (storeKey: MiniGamesCodes | string, value: IStoreData): IStoreData => {
	const data: IStoreData = getStoreData(storeKey);
	const newData: IStoreData = { ...data, ...value };
	localStorage?.setItem(`FG_DATA_${storeKey}`, JSON.stringify(newData));

	return newData;
};

/**
 * Получить данные из локального хранилища браузера
 * @param {MiniGamesCodes | string} storeKey
 * @returns {IStoreData | undefined}
 */
const getStoreData = (storeKey: MiniGamesCodes | string): IStoreData | undefined => {
	const str: string | null = localStorage?.getItem(`FG_DATA_${storeKey}`);
	if (Boolean(str)) {
		try {
			const data: IStoreData | undefined = JSON.parse(str);
			Logger.Log.i(TAG, `parseStoreData => stored data for the game:`, data)
				.console();

			return data;
		} catch (err) {
			Logger.Log.e(TAG, `parseStoreData => can't parse stored data:`, err)
				.console();
		}
	} else {
		Logger.Log.i(TAG, `parseStoreData => no game data`)
			.console();
	}

	return undefined;
};

/**
 * Сервис хранения данных типа {@link IStoreData IStoreData} по играм.
 */
@Injectable({
	providedIn: 'root'
})
export class CustomGameDataService {

	// -----------------------------
	//  Private properties
	// -----------------------------

	/**
	 * Наблюдаемая переменная с данными из хранилища
	 * @private
	 */
	private readonly _storeData$$ = new BehaviorSubject<IStoreData | undefined>(undefined);

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 */
	constructor(
		private readonly storeService: StoreService
	) {
		this.storeService.customGameData$ = this._storeData$$.asObservable();
	}

	/**
	 * Получить данные по игре из {@link localStorage} и обновить полученными данными
	 * наблюдаемую переменную {@link StoreService.customGameData$ customGameData$}.
	 * В качестве ключа используется одно из значений {@link MiniGamesCodes}.
	 *
	 * @param {MiniGamesCodes} gameCode Код игры.
	 * @returns {IStoreData | undefined}
	 */
	getCustomGameData(gameCode: MiniGamesCodes): IStoreData | undefined {
		Logger.Log.i(TAG, `getCustomGameData => get GAME DATA by code: [${gameCode}]`)
			.console();

		const data: IStoreData = getStoreData(gameCode);
		this._storeData$$.next(data);

		return data;
	}

	/**
	 * Обновить данные по игре в {@link localStorage}.
	 * В качестве ключа используется одно из значений {@link MiniGamesCodes}.
	 *
	 * @param {MiniGamesCodes} gameCode Код игры.
	 * @param {IStoreData} value
	 */
	updateCustomGameData(gameCode: MiniGamesCodes, value: IStoreData): void {
		const newData: IStoreData = updateStoreData(gameCode, value);
		Logger.Log.i(TAG, `updateCustomGameData => get GAME DATA by code: [${gameCode}]`, newData)
			.console();

		this._storeData$$.next(newData);
	}

	/**
	 * Получить сохраненные данные приложения из {@link localStorage}.
	 *
	 * @returns {IStoreData | undefined}
	 */
	getCustomApplicationData(): IStoreData | undefined {
		return getStoreData(LocalStorageKeys.AllApplicationData);
	}

	/**
	 * Обновить данные приложения в {@link localStorage}.
	 *
	 * @param {IStoreData} value Новое значение с данными приложения.
	 */
	updateCustomApplicationData(value: IStoreData): void {
		updateStoreData(LocalStorageKeys.AllApplicationData, value);
	}

}
