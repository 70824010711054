import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IPreloaderConfig } from '@preloader/interfaces/ipreloader-config';

/**
 * Компонент большого прелоадера
 */
@Component({
	selector: 'app-big-preloader',
	templateUrl: './big-preloader.component.html',
	styleUrls: ['./big-preloader.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BigPreloaderComponent  {

	// -----------------------------
	//  Input properties
	// -----------------------------
	/**
	 * Флаг видимости прелоадера
	 */
	@Input()
	isVisible: boolean;

	/**
	 * Конфигурация прелоадера.
	 */
	@Input()
	preloaderConfig: IPreloaderConfig;

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------

	

}
