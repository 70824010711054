import { Pipe, PipeTransform } from '@angular/core';

/**
 * Интерфейс опций преобразования величины коэффициента в строку с соответствущим значению форматом
 */
export interface IOddsPipe {
	/**
	 * В мобильном ли мы разрешении?
	 */
	isMobile: boolean;
	/**
	 * Последний коэффициент с двумя знаками после запятой
	 */
	lastOddsWithTwoDigits?: number;
	/**
	 * Последний коэффициент с одним знаком после запятой
	 */
	lastOddsWithOneDigits?: number;
}

/**
 * Пайп, преобразующий величину коэффициента в строку с соответствущим значению форматом.
 */
@Pipe({
	name: 'odds'
})
export class OddsPipe implements PipeTransform {
	/**
	 * Функция преобразования
	 * @param value Передаваемое значение
	 * @param opt Опции
	 */
	transform(value: string | number, opt: IOddsPipe): string {
		let resultValue = typeof value === 'string' ? +value : value;

		if (isNaN(resultValue)) {
			return '';
		}

		let fractionDigits = 0;
		if (!!opt && !isNaN(opt.lastOddsWithTwoDigits) && resultValue <= opt.lastOddsWithTwoDigits) {
			fractionDigits = 2;
		} else if (!!opt && !isNaN(opt.lastOddsWithOneDigits) && resultValue <= opt.lastOddsWithOneDigits) {
			fractionDigits = 1;
		}

		let short = '';
		if (opt && opt.isMobile && resultValue > 1000) {
			resultValue = resultValue / 1000;
			short = 'K';
		}

		return `${resultValue.toFixed(fractionDigits)}${short}`;
	}

}
