import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { GameListShortComponent } from '@gameListShort/components/game-list-short/game-list-short.component';
import { GameListInnerComponent } from './components/game-list-inner/game-list-inner.component';

/**
 * Модуль короткого списка игр.
 */
@NgModule({
	imports: [
		CommonModule,
		SharedModule
	],
	declarations: [
		GameListShortComponent,
		GameListInnerComponent
	],
	exports: [
		GameListShortComponent,
		GameListInnerComponent
	]
})
export class GameListShortModule {}
