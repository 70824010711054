import { interval, timer } from 'rxjs';

/**
 * Возвращает случайный элемент из одномерного массива.
 *
 * @param {Array<T>} array Одномерный массив
 * @returns {T}
 */
export const randomItemFromArray = <T>(array: Array<T>): T => array[Math.ceil(Math.random() * array.length) - 1];

/**
 * Перемешать содержимое массива.
 *
 * @param {Array<number>} array Одномерный массив
 * @returns {Array<number>}
 * @see https://en.wikipedia.org/wiki/Fisher%E2%80%93Yates_shuffle
 */
export const shuffleArray = (array: Array<number>): Array<number> => {
	for (let i = array.length - 1; i > 0; i--) {
		const j = Math.floor(Math.random() * (i + 1));
		[array[i], array[j]] = [array[j], array[i]];
	}

	return array;
};

/**
 * Возвращает одно из переданных аргументов случайным образом.
 *
 * @param {T} a Первый аргумент
 * @param {T} b Второй аргумент
 * @returns {T} Случайно выбранный аргумент.
 */
export const randomItem = <T>(a: T, b: T): T => Math.random() < 0.5 ? a : b;

/**
 * Генерирует строку из случайных символов заданного набора и заданной длины
 * @param len Длина строки
 * @param randomChars Набор возможных символов
 */
export const getRandomString = (len: number, randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'): string => {
	let result = '';
	for (let i = 0; i < len; i++) {
		result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
	}

	return result;
};

/**
 * Интерфейс доступа к функциям мобильного приложения
 */
export const ANDROID: IAndroid = window[`android`];

/**
 * Список экшенов, доступных для общения с мобильным телефоном.
 * @see https://confluence.emict.net/pages/viewpage.action?pageId=209846332
 */
export enum MobileAction {
	/**
	 * Получить token, необходимый для работы приложения в рамках webView.
	 * Возвращает значение текущего token сохраненного в мобильном приложении, если токена нет, буден возвращен null
	 * (обрабатывается методом android.getData(action: string): string).
	 */
	GetAuthToken        = 'get_auth_token',
	/**
	 * Обновить token. Данный экшен используется для получения (обновления) токена из ЦС.
	 * Возвращаемых параметров нет, в связи с тем, что получение токена будет происходить в мобильном приложении асинхронно
	 * с открытием активити содержащей форму ввода логина и пароля и взаимодействия с пользователем,
	 * что подразумевает остановку работы webView и его уничтожение. После удачного получения токена webView, будет перезапущено.
	 * обрабатывается методом  "android.doAction(action: string, data: string)" 
	 */
	RefreshAuthToken    = 'refresh_auth_token',
	/**
	 * Передать на мобильное приложение информацию об изменении маршрута приложения Fastgame.
	 * С данными будет передан объект с исходной и конечной точкой навигации.
	 */
	ChangeLocation      = 'change_location'
}

/**
 * Модель объекта мобильного приложения
 */
export interface IAndroid {
	/**
	 * Метод для вызова какой-либо функции с параметром в МП
	 * @param action Вызываемая функция
	 * @param data Передаваемый параметр
	 */
	doAction(action: MobileAction, data: string): void;

	/**
	 * Метод для получения каких-либо данных из МП
	 * @param action Вызываемая функция
	 */
	getData(action: MobileAction): string;
}

/**
 * Пустой объект в случае открытия веб-приложения НЕ через мобильное приложение
 */
export const EmptyAndroid: IAndroid = {
	/**
	 * Метод для получения каких-либо данных из МП (фиктивный)
	 * @param action Вызываемая функция
	 */
	getData: (action: MobileAction) => undefined,
	
	/**
	 * Метод для вызова какой-либо функции с параметром в МП (фиктивный)
	 * @param action Вызываемая функция
	 * @param data Передаваемый параметр
	 */
	doAction: (action: MobileAction, data: string) => {}
};

/**
 * Функция плавной прокрутки в начало фрейма
 */
export const scrollToBegin = (): void => {
	if (window.parent) {
		const anchor = window.parent.document.getElementById('fast-frame') as HTMLIFrameElement;
		if (anchor) {
			const tmr = timer(1000)
				.subscribe(() => {
					anchor.scrollIntoView({behavior: 'smooth'});
					tmr.unsubscribe();
				});
		}
	}
};

/**
 * Функция подстройки фрейма под высоту контента
 */
export const adjustIFrameToContent = (): void => {
	if (window.parent) {
		const anchor = window.parent.document.getElementById('fast-frame') as HTMLIFrameElement;
		if (anchor) {
			anchor.style.overflow = 'hidden';
			anchor.style.height = `2000px`;
			const tmr = timer(1000)
				.subscribe(() => {
					const appRootColl = anchor.contentWindow.document.getElementsByTagName('app-root');
					if (appRootColl?.length) {
						anchor.style.height = `${appRootColl[0].scrollHeight + 20}px`;
					}
					tmr.unsubscribe();
				});
		}
	}
};

/**
 * Плавная прокрутка в DOM-элементе
 * @param elem DOM-элемент
 * @param top Величина прокрутки
 * @param speed Скорость прокрутки (мс)
 * @param by Флаг - прокрутить К позиции top px (false) или НА top px (true) ?
 */
export const smoothScroll = (elem: HTMLElement, top: number, speed: number, by = false): void => {
	if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator',
			'iPad', 'iPhone', 'iPod',
			'Mac68K', 'MacPPC', 'MacIntel'
		].includes(navigator.platform)
		// iPad on iOS 13 detection
		|| (navigator.userAgent.includes('Mac') && 'ontouchend' in document)) {
		const MIN_INTERVAL = 50;
		const distance = by ? top : top - elem.scrollTop;
		const scDelta = distance * MIN_INTERVAL / speed;
		const finalTop = by ? elem.scrollTop + top : top;
		let acc = 0;
		const handler = interval(MIN_INTERVAL)
			.subscribe(() => {
				if (Math.abs(distance - acc) <= Math.abs(scDelta)) {
					elem.scrollTo(0, finalTop);
					handler.unsubscribe();
				} else {
					acc += scDelta;
					elem.scrollBy(0, scDelta);
				}
			});
	} else {
		by ? elem.scrollBy({top, behavior: 'smooth'}) : elem.scrollTo({top, behavior: 'smooth'});
	}
};

/**
 * Функция формирования слов рядом с числами
 * @param value Число
 * @param words Слова с соответствующими окончаниями
 */
export const num_word = (value: number, words: Array<string>) => {
	value = Math.abs(value) % 100;
	var num = value % 10;
	if(value > 10 && value < 20) return words[2];
	if(num > 1 && num < 5) return words[1];
	if(num == 1) return words[0];
	return words[2];
}

/**
 * Открыто ли приложение в iframe?
 */
export const inIframe = (): boolean => {
	try {
		return window.self !== window.top;
	} catch (e) {
		return true;
	}
};

/**
 * Получить позицию вертикальной прокрутки окна
 * @param wnd Объект окна
 */
export const getScrollTop = (wnd: Window): number => {
	if (typeof wnd.pageYOffset !== 'undefined') {
		// most browsers except IE before #9
		return wnd.pageYOffset;
	}
	const  docBody = wnd.document.body; // IE 'quirks'
	let docEl = wnd.document.documentElement; // IE with doctype
	docEl = (docEl.clientHeight) ? docEl : docBody;

	return docEl.scrollTop;
};

/**
 * Функция позиционирования окна инструкции
 */
export const placeInstruction = (): void => {
	const tutors = document.getElementsByClassName('tutorial');
	// console.log(tutors);
	if ((tutors.length > 0) && window.parent) {
		const tutor = tutors[0] as HTMLDivElement;
		const thisIframe = window.parent.document.getElementById('fast-frame') as HTMLElement;
		const overlay = document.getElementsByClassName('overlay')[0] as HTMLElement;
		// console.log(tutor);
		const scTopParent = getScrollTop(window.parent);
		// console.log(tutor);
		const scTopTutor = (scTopParent < 50) ? 50 :
			(scTopParent > thisIframe.offsetTop + overlay.offsetHeight - 150 - tutor.offsetHeight) ?
				thisIframe.offsetTop + overlay.offsetHeight - 150 - tutor.offsetHeight : scTopParent;
		// console.log(scTopTutor);
		tutor.style.top = `${scTopTutor}px`;
	}
}

