import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { StoreService } from '@app/core/services/store/store.service';
import { IFrameEventsService } from '@app/core/services/external/iframe/iframe-events.service';
import { AbstractDialogComponent } from '@dialogs/components/abstract-dialog.component';
import { DIALOG_DATA_TOKEN } from '@dialogs/constants/game-dialog-constants.service';
import { IDialogConfig } from '@dialogs/interfaces/idialog-config.service';

/**
 * Диалог для отображения ошибок в процессе работы приложения.
 */
@Component({
	selector: 'app-error-dialog',
	templateUrl: './error-dialog.component.html',
	styleUrls: ['./error-dialog.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorDialogComponent extends AbstractDialogComponent {

	/**
	 * Тексты на кнопке диалогового окна, в зависимости от ошибки
	 */
	readonly buttonTexts = {
		4347: 'common.fill-balance',
		4393: 'common.fill-balance',
		1007: 'common.authorize',
		4313: 'common.authorize'
	};

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {IDialogConfig} dialogConfig Конфигурация диалогового окна
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 * @param iframeEventsService Сервис взаимодействия с сайтом через iframe
	 */
	constructor(
		@Inject(DIALOG_DATA_TOKEN)
		readonly dialogConfig: IDialogConfig,
		protected storeService: StoreService,
		readonly iframeEventsService: IFrameEventsService
	) {
		super(iframeEventsService);
	}
}
