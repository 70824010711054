import { Component, Input, SimpleChanges } from '@angular/core';
import { AbstractRowFieldButtonDirective } from '@app/shared/components/game-row-field/abstract-row-field-button.directive';
import { BuyTicketMessageService } from '@app/core/services/game/buy-ticket-message.service';
import { IRowButton, RowControl } from '@app/shared/components/game-row-field/game-row-field.component';
import { concat, Observable, of, timer } from 'rxjs';
import { map, mapTo, repeat, share, switchMap, take } from 'rxjs/operators';

/**
 * Компонент кнопки смайла в игре "Смайл"
 */
@Component({
	selector: 'app-smile-button',
	templateUrl: './smile-button.component.html',
	styleUrls: ['./smile-button.component.scss'] // ,
	// changeDetection: ChangeDetectionStrategy.OnPush // TODO использовать это и исправить проблему с "забрать выигрышь"
})
export class SmileButtonComponent extends AbstractRowFieldButtonDirective  {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Индекс колонки анимируемого смайла
	 */
	@Input()
	smileAnimationIconColumn = undefined;

	/**
	 * Данные о 'батоне' смайла
	 */
	@Input()
	button: IRowButton;

	/**
	 * Данные для анимации смайла
	 */
	@Input()
	rowControl: RowControl;

	/**
	 * Тестирует необходимость анимации смайла
	 */
	get isAnimationTime(): boolean | number {
		return this.button.row === this.rowControl.step && this.smileAnimationIconColumn === this.button.col ? Date.now() : false;
	}

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная, которая содержит флаг показа анимации выигрыша
	 */
	readonly smileAnimationWinEvent$: Observable<boolean> = timer(0, 500)
		.pipe(
			map(value => !!value),
			take(2)
		);

	/**
	 * Наблюдаемая переменная, которая содержит флаг показа анимации проигрыша
	 */
	readonly smileAnimationLoseEvent$: Observable<boolean> = timer(1000)
		.pipe(
			switchMap(() =>
				of(0)
					.pipe(
						switchMap(value => concat(
							timer(0)
								.pipe(
									mapTo(true)
								),
							timer(Math.random() * 3000 + 2000)
								.pipe(
									mapTo(false)
								)
							)
						),
						repeat()
					)
			),
			share()
		);

	/**
	 * Конструктор компонента
	 * @param buyTicketMessageService Сервис показа сообщения о покупке билета
	 */
	constructor(readonly buyTicketMessageService: BuyTicketMessageService) {
		super();
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------

	

}
