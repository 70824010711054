import {MiniGamesCodes} from '@app/core/enums/mini-games-list.enum';
import {Action} from '@app/core/services/network/enums/action.enum';
import {NextGameStepState} from '@app/core/services/network/enums/next-game-step-state';
import {ResultCode} from '@app/core/services/network/enums/result-code.enum';
import {IAbstractResponse} from '@app/core/services/network/interfaces/responses/iabstract-response';
import {IGameDataSeries} from '@app/core/services/network/interfaces/responses/iget-game-data-response';
import {Channel} from '@app/core/services/network/mocks/channel';
import {mock_number, seq, ticket_id} from '@app/core/services/network/mocks/constants';
import {getGameData, GetGameData} from '@app/core/services/network/mocks/get-game-data';
import {GetLastTicket} from '@app/core/services/network/mocks/get-last-ticket';
import {GetLobbyData} from '@app/core/services/network/mocks/get-lobby-data';
import {GetPromos} from '@app/core/services/network/mocks/get-promos';
import {GetUserBonuses} from '@app/core/services/network/mocks/get-user-bonuses';
import {MockWebSocket} from '@app/core/services/network/mocks/mock-web-socket';
import {MockRequest, MockResponse, MockType} from '@app/core/services/network/mocks/types';
import {Version} from '@app/core/services/network/mocks/version';
import {TOD_STEPS} from "@app/core/services/network/mocks/tod-steps";
import {GetLastTicketData} from "@app/core/services/network/mocks/get-last-ticket-data";
import {IColorCell} from "@color/interfaces/i-color-cell";

/**
 * Сумма в грн, начиная с которой выигрыш считается большим
 */
const BIG_WIN = 10000;

/**
 * Интерфейс параметров серий
 */
interface ISeriesParams {
	/**
	 * Ширина игрового поля 
	 */
	w: number;
	/**
	 * Высота игрового поля
	 */
	h: number;
	/**
	 * Количество бомб на поле
	 */
	b: number;

	/**
	 * Другие возможные параметры (зависит от игры)
	 */
	[index: string]: number | Array<number>;
}

/**
 * Mock-класс для всех игр
 */
export class Mocks {
	/**
	 * Экземпляр текущего класса
	 * @private
	 */
	private static instance: Mocks;
	/**
	 * Серии игры
	 * @private
	 */
	private series: IGameDataSeries;
	/**
	 * Параметры серий
	 * @private
	 */
	private seriesParams: ISeriesParams | undefined;
	/**
	 * Массив открытых ячеек
	 * @private
	 */
	private open = [];
	/**
	 * Массив проигрышных ячеек
	 * @private
	 */
	private lose = [];
	/**
	 * Массив закрытых ячеек
	 * @private
	 */
	private close = [];
	/**
	 * Очки пользователя
	 * @private
	 */
	private score = [0, 0, 0, 0, 0, 0];
	/**
	 * Билет в игре "Яркие шары"
	 * @private
	 */
	private colorGameTicket: Array<IColorCell> = [];
	/**
	 * Сумма ставки
	 * @private
	 */
	private betAmount = 1;
	/**
	 * Текущий шаг в игре
	 * @private
	 */
	private step = 0;
	/**
	 * Код серии
	 * @private
	 */
	private seriesCode: number;
	/**
	 * Коэффициент автостопа для игры Креш
	 * @private
	 */
	private autoStop = 4;
	/**
	 * Мокнутые запросы с ответами
	 * @private
	 */
	private mockedData: Array<MockType> = [
		Version,
		GetLobbyData,
		...GetLastTicket,
		...GetLastTicketData,
		GetPromos,
		...GetGameData,
		Channel,
		GetUserBonuses
	];
	/**
	 * Коэффициент по билету.
	 * Появляется в случае проигрыша.
	 */
	private ticketStop: number;
	/**
	 * Timestamp начала игры
	 * @private
	 */
	private startGameTS: number;

	/**
	 * Конструктор Одиночки всегда должен быть скрытым, чтобы предотвратить
	 * создание объекта через оператор new.
	 */
	private constructor() { }

	/**
	 * Сгенерировать билет для игры "Яркие шары"
	 * @private
	 */
	private generateColorTicket(): void {
		this.colorGameTicket = [];
		this.close = [];
		const o = this.seriesParams.o as Array<number>;
		const m = this.seriesParams.m as Array<number>;
		for (let c = 0; c < o.length; c++) {
			this.colorGameTicket.push({ c, s: o[c]});
			this.close.push({ c, s: o[c]});
			m[o[c]]--;
		}
		let c = o.length - 1;
		for (let s = 0; s < m.length; s++) {
			for (let j = 0; j < m[s]; j++) {
				c++;
				this.colorGameTicket.push({c, s});
				this.close.push({c, s});
			}
		}
	}

	/**
	 * Статический метод, управляющий доступом к экземпляру одиночки.
	 *
	 * Эта реализация позволяет вам расширять класс Одиночки, сохраняя повсюду
	 * только один экземпляр каждого подкласса.
	 */
	public static getInstance(): Mocks {
		if (!Mocks.instance) {
			Mocks.instance = new Mocks();
		}

		return Mocks.instance;
	}

	/**
	 * Получить mock-ответ на реальный запрос, сравнивая его с mock-запросами
	 * и модифицировать в соответствии с особенностями каждой игры
	 * @param request Реальный запрос
	 */
	getData(request: MockRequest): MockResponse {
		const response = this.getDefaultResponse(request);
		switch (request.action) {
			case Action.GetLastTicket:
				if (response.ticket && response.ticket.bet) {
					this.betAmount = response.ticket.bet;
				}
				return response;
				// break;
			case Action.GetLastTicketData:
				// const response = this.getDefaultResponse(request);
				this.prepareSeriesAndParams(request);
				this.prepareBombs(request);
				this.open = [...response.open];
				this.step = response.step;
				switch (request.game_code) {
					case MiniGamesCodes.Mines:
						break;
					case MiniGamesCodes.Tower:
						this.lose = [...response.lose];
						break;
					case MiniGamesCodes.Smile:
						this.lose = [...response.lose];
						break;
					case MiniGamesCodes.Crash:
						break;
					case MiniGamesCodes.Tod:
						this.score = [...response.score];
						break;
					case MiniGamesCodes.Buhta:
						break;
					case MiniGamesCodes.Squid:
						break;
					case MiniGamesCodes.Color:
						this.generateColorTicket();
						this.close.splice(0, 1);
						break;
				}
				return response;
			// break;
			case Action.BuyTicket:
				this.step = 0;
				this.betAmount = request.bet_amount;
				this.seriesCode = request.series_code;
				this.open = [];
				this.lose = [];
				this.score = [0, 0, 0, 0, 0, 0];
				
				let buyTicketAns: MockResponse = {
					R: ResultCode.Ok,
					action: Action.BuyTicket,
					seq,
					ticket: {
						bet: request.bet_amount,
						id: ticket_id,
						number: mock_number
					}
				};

				this.prepareSeriesAndParams(request);
				
				if (this.seriesParams) {
					this.prepareBombs(request);
				}

				if (request.game_code === MiniGamesCodes.Crash) {
					this.startGameTS = Date.now();
					this.autoStop = request.auto_stop;
					buyTicketAns = {...buyTicketAns, odds: 1, state: NextGameStepState.Win, step: 0, sum: this.betAmount};
				}
				
				if (request.game_code === MiniGamesCodes.Color) {
					this.generateColorTicket();
				}

				return buyTicketAns;
			// break;
			case Action.NextGameStep:
				let nextGameStepResponse: MockResponse = {action: Action.NextGameStep, R: ResultCode.Ok, seq};
				this.step++;
				const cell = request.cell;
				
				if (request.game_code === MiniGamesCodes.Mines) {
					const isWin = cell < (this.seriesParams.w * this.seriesParams.h - this.seriesParams.b);
					this.open.push(cell);
					const part = {
						step: this.step,
						sum: isWin ? +this.series.koefs[this.step - 1] * this.betAmount : 0,
						state: isWin && (this.step === (this.seriesParams.w * this.seriesParams.h - this.seriesParams.b)) ?
							NextGameStepState.EndOfGame : isWin ? NextGameStepState.Win : NextGameStepState.Lose,
						open: this.open
					};
					
					if (part.sum >= BIG_WIN * 100) {
						nextGameStepResponse = {
							action: Action.NextGameStep,
							R: ResultCode.BigWin,
							extra: {
								action: Action.NextGameStep,
								R: ResultCode.BigWin,
								state: NextGameStepState.BigWin,
								step: this.step,
								sum: part.sum,
								open: part.open,
								lose: this.lose
							},
							seq
						};
					} else {
						nextGameStepResponse = {...nextGameStepResponse, ...part};
						if (!isWin || (this.step === (this.seriesParams.w * this.seriesParams.h - this.seriesParams.b))) {
							nextGameStepResponse = {...nextGameStepResponse, lose: this.lose};
						}
					}
					
					// if (nextGameStepResponse.state === NextGameStepState.EndOfGame) {
					// 	nextGameStepResponse.promos = [{
					// 		code: 1
					// 	}];
					// }
				}

				if (request.game_code === MiniGamesCodes.Tower || request.game_code === MiniGamesCodes.Smile) {
					const isWin = cell < (this.seriesParams.w * this.step - this.seriesParams.b);
					this.open.push(cell);
					for (let loseCell = this.seriesParams.w * this.step - this.seriesParams.b; loseCell < this.seriesParams.w * this.step; loseCell++) {
						this.lose.push(loseCell);
					}
					const part = {
						step: this.step,
						sum: isWin ? +this.series.koefs[this.step - 1] * this.betAmount : 0,
						state: isWin && (this.step === this.seriesParams.h) ?
							NextGameStepState.EndOfGame : isWin ? NextGameStepState.Win : NextGameStepState.Lose,
						open: this.open,
						lose: this.lose
					};

					if (part.sum >= BIG_WIN * 100) {
						nextGameStepResponse = {
							action: Action.NextGameStep,
							R: ResultCode.BigWin,
							extra: {
								action: Action.NextGameStep,
								R: ResultCode.BigWin,
								state: NextGameStepState.BigWin,
								step: this.step,
								sum: part.sum,
								open: part.open,
								lose: this.lose
							},
							seq
						};
					} else {
						nextGameStepResponse = {...nextGameStepResponse, ...part};
						if (!isWin) {
							for (let rowNum = this.step + 1; rowNum <= this.seriesParams.h; rowNum++) {
								for (let loseCell = this.seriesParams.w * rowNum - this.seriesParams.b; loseCell < this.seriesParams.w * rowNum; loseCell++) {
									this.lose.push(loseCell);
								}
							}
							nextGameStepResponse = {...nextGameStepResponse, lose: this.lose};
						}
					}
				}

				if (request.game_code === MiniGamesCodes.Tod) {
					const cat = TOD_STEPS[request.series_code][cell].cat;
					this.open.push({ cell, cat });
					for (const oneCat of cat) {
						this.score[oneCat - 1]++;
					}
					let state = NextGameStepState.Win;
					let sum = 0;
					for (let catNum = 1; catNum <= this.score.length; catNum++) {
						if (this.score[catNum - 1] === this.seriesParams.o[catNum - 1]) {
							const close = [];
							for (const oneCell of TOD_STEPS[request.series_code]) {
								if (!this.open.find(item => item.cell === oneCell.cell)) {
									close.push(oneCell);
								}
							}
							nextGameStepResponse = {...nextGameStepResponse, close};
							state = NextGameStepState.EndOfGame;
							sum = this.betAmount * +this.series.koefs[catNum - 1];
							break;
						}
					}
					nextGameStepResponse = {...nextGameStepResponse, open: this.open, score: this.score, state, sum};
					if (request.auto) {
						nextGameStepResponse.isAuto = true;
					}
				}

				if (request.game_code === MiniGamesCodes.Buhta) {
					const isWin = cell < (this.seriesParams.w * this.seriesParams.h - this.seriesParams.b);
					this.open.push(cell);
					const part = {
						step: this.step,
						sum: isWin ? +this.series.koefs[this.step - 1] * this.betAmount : 0,
						state: isWin && (this.step === (this.seriesParams.w * this.seriesParams.h - this.seriesParams.b)) ?
							NextGameStepState.EndOfGame : isWin ? NextGameStepState.Win : NextGameStepState.Lose,
						open: this.open
					};

					if (part.sum >= BIG_WIN * 100) {
						nextGameStepResponse = {
							action: Action.NextGameStep,
							R: ResultCode.BigWin,
							extra: {
								action: Action.NextGameStep,
								R: ResultCode.BigWin,
								state: NextGameStepState.BigWin,
								step: this.step,
								sum: part.sum,
								open: part.open
							},
							seq
						};
					} else {
						nextGameStepResponse = {...nextGameStepResponse, ...part};
						if (!isWin || (this.step === (this.seriesParams.w * this.seriesParams.h - this.seriesParams.b))) {
							nextGameStepResponse = {...nextGameStepResponse, lose: this.lose};
						}
						if (nextGameStepResponse.state === NextGameStepState.EndOfGame) {
							nextGameStepResponse.promos = [{
								code: 405,
								start: (new Date(Date.now() - 24 * 60 * 60 * 1000)).toISOString(),
								end: (new Date(Date.now() + 96 * 60 * 60 * 1000)).toISOString(),
								results: (new Date()).toISOString()
							}];
						}
					}
				}

				if (request.game_code === MiniGamesCodes.Squid) {
					const loseCell = this.step * this.seriesParams.w - 1;
					const isWin = cell !== loseCell;
					this.open.push(cell);
					this.lose.push(loseCell);
					const part = {
						step: this.step,
						sum: isWin ? +this.seriesParams.koefs[this.step - 1] * this.betAmount : 0,
						state: isWin && (this.step === this.seriesParams.h) ?
							NextGameStepState.EndOfGame : isWin ? NextGameStepState.Win : NextGameStepState.Lose,
						open: this.open,
						lose: this.lose
					};
					nextGameStepResponse = {...nextGameStepResponse, ...part};
					if (!isWin || (this.step === this.seriesParams.h)) {
						this.lose = [];
						for (let i = 1; i <= this.seriesParams.h; i++) {
							this.lose.push(i * this.seriesParams.w - 1);
						}
						nextGameStepResponse = {...nextGameStepResponse, lose: this.lose};
					}
				}

				if (request.game_code === MiniGamesCodes.Color) {
					// [
					// .... сначала выигрышные ....
					// 	{"c":0,"s":1},
					// 	{"c":1,"s":2},
					// 	{"c":2,"s":3},
					// 	{"c":3,"s":4},
					// 	.... потом остальные ....
					// ]
					const o = this.seriesParams.o as Array<number>;
					const m = this.seriesParams.m as Array<number>;
					
					const isWin = (this.colorGameTicket[cell].s === o[this.step - 1]) || (this.colorGameTicket[cell].s === 0);
					const isEndOfGame = this.step === o.length && isWin;
					const cellDataIndex = this.colorGameTicket.findIndex(t => t.c === cell);
					this.open.push(this.colorGameTicket[cellDataIndex]);
					const cellCloseDataIndex = this.close.findIndex(t => t.c === cell);
					this.close.splice(cellCloseDataIndex, 1);
					const part: any = {
						step: this.step,
						sum: isWin ? +this.series.koefs[this.step - 1] * this.betAmount : 0,
						state: isEndOfGame ? NextGameStepState.EndOfGame : (isWin ? NextGameStepState.Win : NextGameStepState.Lose),
						open: this.open
					};
					const isBigWin = part.sum >= BIG_WIN * 100;
					if (isEndOfGame || !isWin || isBigWin) {
						part.close = this.close;
					}
					if (isBigWin) {
						nextGameStepResponse = {
							action: Action.NextGameStep,
							R: ResultCode.BigWin,
							extra: {
								action: Action.NextGameStep,
								R: ResultCode.BigWin,
								state: NextGameStepState.BigWin,
								step: this.step,
								sum: part.sum,
								open: part.open,
								close: part.close,
								macCode: mock_number
							},
							seq
						};
					} else {
						nextGameStepResponse = {...nextGameStepResponse, ...part};
					}
				}				
				
				return nextGameStepResponse;
			// break;
			case Action.FinishGame:
				let finishResponse: MockResponse = {
					R: ResultCode.Ok,
					action: Action.FinishGame,
					seq,
					state: NextGameStepState.EndOfGame,
					step: this.step,
					sum: +this.series.koefs[this.step - 1] * this.betAmount,
					wasBonusPaid: false
				};
				if (request.game_code === MiniGamesCodes.Mines) {
					finishResponse = {...finishResponse, lose: this.lose, open: this.open};
				}

				if (request.game_code === MiniGamesCodes.Tower || request.game_code === MiniGamesCodes.Smile) {
					for (let rowNum = this.step + 1; rowNum <= this.seriesParams.h; rowNum++) {
						for (let loseCell = this.seriesParams.w * rowNum - this.seriesParams.b; loseCell < this.seriesParams.w * rowNum; loseCell++) {
							this.lose.push(loseCell);
						}
					}
					finishResponse = {...finishResponse, lose: this.lose, open: this.open};
				}

				if (request.game_code === MiniGamesCodes.Crash) {
					this.ticketStop = this.autoStop;
					const odds = (MockWebSocket.getInstance().step + 1) * 0.01 + 1;
					finishResponse = {...finishResponse,
						odds,
						step: MockWebSocket.getInstance().step,
						sum: odds * this.betAmount,
						ticketStop: this.ticketStop,
						ticketTime: Date.now() - this.startGameTS,
						time: Math.floor((MockWebSocket.getInstance().step + Math.random()) * 200)
					};
				}

				if (request.game_code === MiniGamesCodes.Buhta) {
					finishResponse = {...finishResponse, lose: this.lose, open: this.open};
				}

				if (request.game_code === MiniGamesCodes.Squid) {
					finishResponse = {...finishResponse, lose: this.lose, open: this.open};
				}

				if (request.game_code === MiniGamesCodes.Color) {
					finishResponse = {...finishResponse, open: this.open, close: this.close};
				}

				return finishResponse;
			// break;
			default:
				return response;
		}
	}

	/**
	 * Получить mock-ответ на реальный запрос, сравнивая его с mock-запросами
	 * @param request Реальный запрос
	 */
	private getDefaultResponse(request: MockRequest): MockResponse {
		for (const mockItem of this.mockedData) {
			const mockKeys = Object.keys(mockItem.request)
				.filter(mk => mk !== 'seq');
			let matched = true;
			for (const mockKey of mockKeys) {
				if ((typeof request[mockKey] === 'undefined') || request[mockKey] !== mockItem.request[mockKey]) {
					matched = false;
					break;
				}
			}
			if (matched) {
				return mockItem.response as IAbstractResponse;
			}
		}
		return undefined;
	}

	/**
	 * Подготовить серии и параметры серий
	 * @param request Реальный запрос
	 * @private
	 */
	private prepareSeriesAndParams(request: MockRequest): void {
		this.series = getGameData(request.game_code, request.series_code).series as IGameDataSeries;
		this.seriesParams = !!this.series.params ? JSON.parse(this.series.params) : undefined;
	}

	/**
	 * Подготовить массив проигрышных ячеек (бомб)
	 * @param request Реальный запрос
	 * @private
	 */
	private prepareBombs(request: MockRequest): void {
		if (request.game_code === MiniGamesCodes.Mines) {
			for (let bomb = this.seriesParams.w * this.seriesParams.h - this.seriesParams.b; bomb < this.seriesParams.w * this.seriesParams.h; bomb++) {
				this.lose.push(bomb);
			}
		}
		if (request.game_code === MiniGamesCodes.Buhta) {
			for (let bomb = this.seriesParams.w * this.seriesParams.h - this.seriesParams.b; bomb < this.seriesParams.w * this.seriesParams.h; bomb++) {
				this.lose.push(bomb);
			}
		}
	}
}
