import { Component, EventEmitter, Output } from '@angular/core';

/**
 * Компонент кнопки закрытия диалоговых окон
 */
@Component({
	selector: 'app-cross-close-button',
	templateUrl: './cross-close-button.component.html',
	styleUrls: ['./cross-close-button.component.scss']
})
export class CrossCloseButtonComponent  {

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие закрытия
	 */
	@Output()
	readonly closeButtonClicked = new EventEmitter<void>();
}
