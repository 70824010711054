import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { PreloaderService } from '@preloader/services/preloader.service';

import { BigPreloaderComponent } from '@preloader/components/big-preloader/big-preloader.component';
import { PreloaderComponent } from '@preloader/components/preloader/preloader.component';
import { SmallPreloaderComponent } from '@preloader/components/small-preloader/small-preloader.component';

/**
 *
 */
@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		PreloaderComponent,
		SmallPreloaderComponent,
		BigPreloaderComponent
	],
	providers: [
		PreloaderService
	]
})
export class PreloaderModule {}
