import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';

import { ActionPanelModule } from '@actionPanel/action-panel.module';
import { GameListShortModule } from '@gameListShort/game-list-short.module';
import { InfoLinksModule } from '@infoLinks/info-links.module';

import { InfoPanelComponent } from '@infoPanel/components/info-panel/info-panel.component';
import { MoneyPanelComponent } from '@infoPanel/components/money-panel/money-panel.component';

/**
 * Модуль информационной панели.
 */
@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		InfoLinksModule,
		GameListShortModule,
		ActionPanelModule
	],
	declarations: [
		InfoPanelComponent,
		MoneyPanelComponent
	],
	exports: [
		InfoPanelComponent
	]
})
export class InfoPanelModule {}
