import { IFrameEventsService } from '@app/core/services/external/iframe/iframe-events.service';
import { StoreService } from '@app/core/services/store/store.service';
import { environment } from '@app/environments/environment';
import { EmptyAndroid, IAndroid, MobileAction } from '@app/utils/utils';
import { IDialogConfig } from '@dialogs/interfaces/idialog-config.service';

/**
 * Объект, доступный при открытии веб-приложения через мобильное приложение
 */
const ANDROID: IAndroid = window[`android`];

/**
 * Функция проверки того, что веб-приложение открыто через мобильное Android-приложение
 * @param showError Показывать ли ошибку
 */
const checkAndroid = (showError: boolean = true): IAndroid => {
	if (!environment.isMobile) {
		return EmptyAndroid;
	}

	if (!ANDROID) {
		return EmptyAndroid;
	}

	return ANDROID;
};

/**
 * Абстрактный компонент диалога.
 */
export abstract class AbstractDialogComponent {

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Конфигурация диалогового окна
	 */
	readonly dialogConfig: IDialogConfig;

	/**
	 * Сервис-хранилище приложения
	 * @protected
	 */
	protected abstract storeService: StoreService;

	// -----------------------------
	//  Public functions
	// -----------------------------
	/**
	 * Конструктор компонента
	 * @param iframeEventsService Сервис взаимодействия с сайтом через iframe
	 * @protected
	 */
	protected constructor(readonly iframeEventsService: IFrameEventsService) {
	}

	/**
	 * Показать внешнее диалоговое окно входа (сайтовское или МПшное)
	 * @private
	 */
	private showExternalLoginDialog(): void {
		if (environment.isMobile) {
			checkAndroid()
				.doAction(MobileAction.RefreshAuthToken, '');
		} else {
			this.iframeEventsService.signIn();

			if (!environment.production) {
				this.storeService.sid = prompt('Login');
			}
		}
	}

	/**
	 * Закрыть диалоговое окно
	 * @param $event Передаваемое событие
	 */
	onCloseButtonClickHandler($event?: MouseEvent): void {
		if (!!$event && (($event.target as HTMLElement).tagName === 'BUTTON')) {
			if ((this.dialogConfig.code === 4347) || (this.dialogConfig.code === 4393)) {
				window.open(environment.fillBalanceLink, '_blank');
			}
			if (this.dialogConfig.code === 1007 || this.dialogConfig.code === 4313) {
				this.showExternalLoginDialog();
			}
		}
		this.dialogConfig?.dialogOverlayRef?.close();
		this.storeService.hideAllOverlayPanelsOnError$$.next(undefined);
	}
}
