import { Injectable } from '@angular/core';

import { Logger } from '@app/core/services/log/log.service';
import { Action } from '@app/core/services/network/enums/action.enum';
import { NextGameStepState } from '@app/core/services/network/enums/next-game-step-state';
import { ResultCode } from '@app/core/services/network/enums/result-code.enum';
import { IAbstractResponse } from '@app/core/services/network/interfaces/responses/iabstract-response';
import { IFinishGameResponse } from '@app/core/services/network/interfaces/responses/ifinish-game-response';
import { IGetLastTicketDataResponse } from '@app/core/services/network/interfaces/responses/iget-last-ticket-data-response';
import { IGetLastTicketResponse } from '@app/core/services/network/interfaces/responses/iget-last-ticket-response';
import { INextGameStepResponse } from '@app/core/services/network/interfaces/responses/inext-game-step-response';
import { GameState } from '@app/core/services/state/enums/game-state.enum';
import { StoreService } from '@app/core/services/store/store.service';

/**
 * Тег для логирования
 */
const TAG = 'GameStateService';

/**
 * Сервис для работы с состоянием приложения.
 */
@Injectable({
	providedIn: 'root'
})
export class GameStateService {

	// -----------------------------
	//  Private properties
	// -----------------------------

	/**
	 * Переменная, хранящая состояние игры перед выполнением какого-либо запроса
	 * @private
	 */
	private _stateBeforeRequest: GameState;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {StoreService} storeService
	 */
	constructor(
		private readonly storeService: StoreService
	) {}

	/**
	 * Парсер состояния на основе полученных данных от коммуникационного сервиса.
	 *
	 * @param {IAbstractResponse} response
	 */
	parseGameStateByResponse(response: IAbstractResponse): void {
		Logger.Log.i(TAG, `parseGameState => for action: [${response.action}]`)
			.console();

		let nextState: GameState;
		switch (response.action) {
			case Action.GetGameData:
				break;

			case Action.BuyTicket:
				nextState = GameState.GameTime;
				break;

			case Action.GetLastTicket:
				nextState = !!(response as IGetLastTicketResponse).ticket
					? GameState.GameTime
					: GameState.BuyTicket;
				break;

			case Action.GetLastTicketData:
				// при наличии параметра stream поставить игру на паузу
				nextState = (response as IGetLastTicketDataResponse).stream !== undefined
					? GameState.GamePaused
					: GameState.GameTime;
				break;

			case Action.NextGameStep:
				nextState = this.parseNextGameStep(response as INextGameStepResponse);
				break;

			case Action.FinishGame:
				nextState = this.parseFinishGame(response as IFinishGameResponse);
				break;

			case Action.GetPromos:
			case Action.GetPromoProgress:
			case Action.GetUserBonuses:
				break;

			default:
				Logger.Log.e(TAG, `parseGameState => can't detect state for action: [${response.action}]`)
					.console();
				nextState = GameState.BuyTicket;
				break;
		}

		if (!!nextState) {
			Logger.Log.i(TAG, `parseGameState => for action: [${response.action}], next state will be: [${nextState}]`)
				.console();

			this.storeService.gameState$$.next(nextState);
		}
	}

	/**
	 * На время выполнения запроса активировать состояние {@link GameState.RequestIsActive RequestIsActive}.
	 * Текущее состояние будет сохранено и по завершению запроса восстановлено.
	 */
	setDataRequestState(): void {
		Logger.Log.i(TAG, `setDataRequestState => previous state: [${this.storeService.gameState$$.value}]`)
			.console();
		this._stateBeforeRequest = this.storeService.gameState$$.value;
		this.storeService.gameState$$.next(GameState.RequestIsActive);
	}

	/**
	 * Восстановить состояние приложения после выполнения запроса.
	 */
	recoverStateAfterRequest(): void {
		Logger.Log.i(TAG, `recoverStateAfterRequest => recover state: [${this._stateBeforeRequest}]`)
			.console();
		this.storeService.gameState$$.next(this._stateBeforeRequest);
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Паресер состояния игры на основе ответ {@link INextGameStepResponse}.
	 *
	 * @param {INextGameStepResponse} response
	 * @returns {GameState | undefined}
	 */
	private readonly parseNextGameStep = (response: INextGameStepResponse): GameState | undefined => {
		const stepState = response.R === 0 ? response.state : response.extra.state;
		switch (stepState) {
			case NextGameStepState.Lose:
				return GameState.ShowLosing;
			case NextGameStepState.Win:
				return GameState.GameTime;
			case NextGameStepState.EndOfGame:
				return GameState.ShowWin;
			case NextGameStepState.BigWin:
				return GameState.ShowBigWin;
			default:
				Logger.Log.e(TAG, `parseNextGameStep => can't detect state for action: [${response.action}]`)
					.console();

				return undefined;
		}
	}

	/**
	 * Паресер состояния игры на основе ответ {@link IFinishGameResponse}.
	 *
	 * @param {IFinishGameResponse} response
	 * @returns {GameState | undefined}
	 */
	private readonly parseFinishGame = (response: IFinishGameResponse): GameState | undefined => {
		if (response.R === ResultCode.Ok) {
			return GameState.ShowWin;
		} else if (response.R === ResultCode.BigWin) {
			return GameState.ShowBigWin;
		}

		return undefined;
	}

}
