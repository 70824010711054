/**
 * Список экшенов, доступных через Websocket:
 * - {@link Channel} - подписка или отписка на события канала (от сервера).
 *
 * - {@link Stream} - Пакет данных из потока (для некоторых игр).
 *
 * - {@link WinFeedHistory} - Метод используется для получения истории по каналу "выигрышей".
 *
 * - {@link Version} - Метод получения информации о версии сервера.
 *
 * - {@link GetLobbyData} - Метод используется для получения информации для отрисовки страницы с лобби.
 * Содержит в себе минимальную информацию для наполнения страницы лобби и для дальнейшего перехода по разным играм внутри лобби.
 *
 * - {@link GetGameData} - Метод используется для отрисовки элементов конкретной игры при загрузке страницы с игрой.
 * Содержит в себе набор параметров игры.
 *
 * - {@link GetLastTicket} - Метод используется для определения, есть ли недоигранный билет у игрока.
 * И если есть, на какую игру нужно перенаправить игрока.
 *
 * - {@link GetLastTicketData} - Метод используеться для визуализации уже открытых ранее шагов в случае недоигранного билета.
 *
 * - {@link BuyTicket} - Метод используется для регистрации ставки (покупки билета).
 *
 * - {@link NextGameStep} - Метод используется при разыгрывании купленного билета.
 *
 * - {@link FinishGame} - Метод используется для завершения игры по инициативе игрока. Вызывается при нажатии на кнопку "Забрать выигрыш".
 *
 * - {@link GetPromos} - Получить список акций.
 *
 * - {@link GetPromoProgress} - Получить прогресс (текущие результаты по акции), если такое предусмотрено акцией.
 */
export enum Action {
	Channel 			= 'channel',
	Stream 				= 'stream',
	WinFeedHistory 		= 'winFeedHistory',

	Version             = 'version',
	GetLobbyData    	= 'getLobbyData',
	GetGameData     	= 'getGameData',
	GetLastTicket   	= 'getLastTicket',
	GetLastTicketData	= 'getLastTicketData',
	BuyTicket       	= 'buyTicket',
	NextGameStep    	= 'nextGameStep',
	FinishGame      	= 'finishGame',

	GetPromos           = 'getPromos',
	GetPromoProgress    = 'getPromoProgress',
	GetUserBonuses		= 'getUserBonuses'
}
