import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { IGameListItem } from '@app/core/interfaces/igame-list-item';
import { NavigationService } from '@app/core/services/game/navigation.service';
import { StoreService } from '@app/core/services/store/store.service';
import { AbstractGameListDirective } from '@gameListShort/components/abstract-game-list.directive';

/**
 * Тег для логирования
 */
const TAG = 'GameListInnerComponent';

/**
 * Компонент меню с иконками игр
 */
@Component({
	selector: 'app-game-list-inner',
	templateUrl: './game-list-inner.component.html',
	styleUrls: ['./game-list-inner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class GameListInnerComponent extends AbstractGameListDirective implements OnInit {
	/**
	 * Список игр
	 */
	gameList = [];

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Всегда показывать подписи к иконкам, независимо от разрешения экрана.
	 * К удалению
	 */
	@Input()
	alwaysShowLabels = true;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {NavigationService} navigationService Сервис навигации по внутренним маршрутам приложения
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 */
	constructor(
		readonly navigationService: NavigationService,
		readonly storeService: StoreService
	) {
		super();
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.storeService.gameList$$.subscribe((gl: Array<IGameListItem>) => {
			this.gameList = gl.sort((a, b) => a.code - b.code);
		});
	}

}
