import { OverlayRef } from '@angular/cdk/overlay';
import { Subject } from 'rxjs';
import { Logger } from '@app/core/services/log/log.service';

/**
 * Тег для логирования
 */
const TAG = 'GameDialogOverlayRef';

/**
 * Класс для создания оверлея под диалоговые окна
 */
export class GameDialogOverlayRef {

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная для закрытия диалогового окна
	 */
	readonly closeHandler$$ = new Subject<never>();

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса
	 *
	 * @param {OverlayRef} overlayRef Ссылка на оверлей диалогового окна
	 */
	constructor(
		private readonly overlayRef: OverlayRef
	) {}

	/**
	 * Закрыть диалог.
	 */
	close(): void {
		Logger.Log.i(TAG, `close => dialog`)
			.console();

		this.closeHandler$$.next();
		this.closeHandler$$.complete();

		this.overlayRef.detach();
		this.overlayRef.dispose();
	}

}
