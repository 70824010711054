<ng-container *ngIf="activeTimer$ | async as activeTimer">
	<app-small-preloader
		[isVisible]="activeTimer === TimersDelay.First"
		>
	</app-small-preloader>
	
	<app-big-preloader
		[isVisible]="activeTimer === TimersDelay.Second"
		[preloaderConfig]="preloaderConfig"
		>
	</app-big-preloader>
</ng-container>
