<ng-container *ngIf="button">
		<button class="smile-button"
			[id]="button.id"
			[attr.bomb]="button.hasBomb"
			[attr.symbol]="button.hasSymbol"
			[attr.open]="button.isOpen"
			[attr.active-row]="button.isActiveRow && rowControl.active"
			[attr.disabled]="button.isOpen || button.hasBomb || button.hasSymbol || undefined" 
			data-cy="smile-button-inner"	
			>
			<span
				class="mgf-symbol"
				appAnimatedSprite
				[ngClass]="'win' + button.iconNum"
				[frameWidth]="91"
				[frameHeight]="91"
				[frameCount]="7"
				[frameRate]="60"
				[startFrame]="(!button.isOpen && button.hasSymbol) ? 6 : 0"
				[repeatCount]="0"
				[activate]="button.isOpen && button.hasSymbol"
				data-cy="mgf-symbol"
			></span>
	
			<span
				class="mgf-win-anim"
				appAnimatedSprite
				[frameWidth]="91"
				[frameHeight]="91"
				[frameCount]="5"
				[frameRate]="60"
				[startFrame]="0"
				[repeatCount]="2"
				[activate]="button.isOpen && button.hasSymbol && button.row === rowControl.step && smileAnimationWinEvent$ | async"
				data-cy="mgf-win-anim"
			></span>
			
			<span
				class="mgf-bomb"
				appAnimatedSprite
				[ngClass]="'lose' + button.iconNum"
				[frameWidth]="91"
				[frameHeight]="91"
				[frameCount]="7"
				[frameRate]="60"
				[startFrame]="(!button.isOpen && button.hasBomb) ? 6 : 0"
				[repeatCount]="0"
				[activate]="button.isOpen && button.hasBomb"
				data-cy="mgf-bomb"
			></span>
				
			<span
				class="mgf-bomb-anim"
				appAnimatedSprite
				[frameWidth]="91"
				[frameHeight]="91"
				[frameCount]="5"
				[frameRate]="200"
				[startFrame]="0"
				[repeatCount]="1"
				[activate]="button.isOpen && button.hasBomb && button.row === rowControl.step && smileAnimationLoseEvent$ | async"
				data-cy="mgf-bomb-anim"
			></span>
		
			<span
				class="mgf-anim"
				appAnimatedSprite
				[ngClass]="'anim' + button.iconNum"
				[frameWidth]="91"
				[frameHeight]="91"
				[frameCount]="button.animationFrameCount"
				[frameRate]="150"
				[startFrame]="0"
				[repeatCount]="1"
				[activate]="isAnimationTime"
				data-cy="mgf-anim"
			></span>
	</button>
</ng-container>
