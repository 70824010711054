<div>
	<label
		class="error-message"
		[innerHTML]="dialogConfig?.message"
	></label>
	
	<button
		(click)="onCloseButtonClickHandler($event)"
		>
		{{ buttonTexts[dialogConfig?.code] ? (buttonTexts[dialogConfig?.code] | translate) : ('dialogs.continue' | translate) }}
	</button>
</div>
