import { OverlayRef } from '@angular/cdk/overlay';
import { timer } from 'rxjs';

/**
 * Класс для создания оверлея под гамбургер-меню
 */
export class HamburgerOverlayRef {

	/**
	 * Конструктор класса
	 *
	 * @param {OverlayRef} overlayRef Ссылка на создаваемый оверлей
	 */
	constructor(
		private readonly overlayRef: OverlayRef
	) {}

	/**
	 * Закрыть меню-гамбургер после анимации в 800мс.
	 */
	close(): void {
		timer(800)
			.subscribe(() => {
				this.overlayRef.detach();
				this.overlayRef.dispose();
			});
	}

}
