import { Action } from '@app/core/services/network/enums/action.enum';
import { ResultCode } from '@app/core/services/network/enums/result-code.enum';
import { seq, sid } from '@app/core/services/network/mocks/constants';

/**
 * Mock запроса и ответа на получение пользовательских бонусов
 */
export const GetUserBonuses = {
	request: {
		action: Action.GetUserBonuses,
		seq,
		sid
	},
	response: {
		R: ResultCode.Ok,
		action: Action.GetUserBonuses,
		seq,
		stat: {
			sum: 51577500,
			tickets: 3534,
			win_sum: 43063074,
			win_tickets: 1454
		},
		userBonuses: []
	}
};
