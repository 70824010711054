import { InjectionToken } from '@angular/core';
import { Channel } from '@app/core/services/network/enums/channel.enum';
import { WinFeedType } from '@app/core/services/network/enums/win-feed-type';
import { IWinFeedEventData } from '@app/core/services/network/interfaces/responses/iwin-feed-history-response';
import { IWinTableTokenData } from '@winTable/interfaces/iwin-table-token-data';

/**
 * Токен для инжектирования конфигурации popup окна с таблицей рекордов.
 */
export const WIN_TABLE_DATA_TOKEN: InjectionToken<IWinTableTokenData> =
	new InjectionToken<IWinTableTokenData>('WIN_TABLE_DATA_TOKEN');

/**
 * Максимальное количество строк в таблице рекордов.
 */
export const WIN_TABLE_SIZE = 10;

/**
 * Значение выбранного типа таблицы из таблицы рекордов.
 */
export const DEFAULT_WIN_FEED = WinFeedType.Hot;

/**
 * Код акции для отображения таблицы турниров
 */
export const WIN_TABLE_PROMO_CODE = 3;

/**
 * Тип данных в таблице лайв событий.
 */
export type WinFeedTableItem = IWinFeedEventData;

/**
 * Возвращает канал событий (строка) по типу канала ставок (число)
 *
 * @param {WinFeedType} winFeedType Тип канала ставок
 * @returns {Channel}
 */
export const getWinFeedChannelByType = (winFeedType: WinFeedType): Channel => {
	switch (winFeedType) {
		case WinFeedType.My:
			return Channel.WinFeedMy;
		case WinFeedType.All:
			return Channel.WinFeedAll;
		case WinFeedType.Hot:
			return Channel.WinFeedHot;
		default:
			return Channel.WinFeedAll;
	}
};

/**
 * Возвращает выигрышный канал (число) по типу канала событий (строка) 
 * @param channel Канал событий
 */
export const getWinFeedTypeByChannel = (channel: Channel): WinFeedType => {
	switch (channel) {
		case Channel.WinFeedAll:
			return WinFeedType.All;
		case Channel.WinFeedHot:
			return WinFeedType.Hot;
		default:
			return WinFeedType.All;
	}
};
