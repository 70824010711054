import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { IGameListItem } from '@app/core/interfaces/igame-list-item';
import { Logger } from '@app/core/services/log/log.service';
import { InfoLinksOrientation } from '@infoLinks/components/info-links/info-links.component';
import { StoreService } from '@app/core/services/store/store.service';
import { HAMBURGER_DATA_TOKEN } from '@hamburger/constants/hamburger-constants';
import { MenuHamburgerState } from '@hamburger/enums/menu-hamburger-state.enum';
import { IHamburgerConfig } from '@hamburger/interfaces/ihamburger-config';

/**
 * Тег для логирования
 */
const TAG = 'MenuHamburgerComponent';

/**
 * Компонент, отображающий выпадающее меню-гамбургер.
 */
@Component({
	selector: 'app-menu-hamburger',
	templateUrl: './menu-hamburger.component.html',
	styleUrls: ['./menu-hamburger.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MenuHamburgerComponent {

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Ориентация ссылок, которые ведут на правила и условия игры
	 */
	readonly InfoLinksOrientation: typeof InfoLinksOrientation = InfoLinksOrientation;

	/**
	 * Состояние гамбургер-меню
	 */
	state: MenuHamburgerState = MenuHamburgerState.Open;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента.
	 *
	 * @param {IHamburgerConfig} hamburgerConfig Конфигурация гамбургер-меню
	 * @param storeService Сервис-хранилище приложения
	 * @param {ChangeDetectorRef} cdr Детектор обнаружения изменений
	 */
	constructor(
		@Inject(HAMBURGER_DATA_TOKEN)
		readonly hamburgerConfig: IHamburgerConfig,
		private readonly storeService: StoreService,
		private readonly cdr: ChangeDetectorRef
	) {}

	/**
	 * Вспомогательная функция для ускорения вывода информации в шаблон
	 * @param index Индекс элемента
	 * @param item Элемент
	 */
	readonly trackByGameFn = (index, item: IGameListItem) => item.code;

	/**
	 * Обработчик клика по элементам, требующих закрытия меню-гамбургер после клика по ним.
	 * @param gameSelected Выбрана ли игра
	 */
	onClickCloseMenuHandler(gameSelected: boolean = false): void {
		Logger.Log.i(TAG, `onClickCloseMenuHandler`)
			.console();

		this.state = MenuHamburgerState.Closed;
		this.cdr.markForCheck();

		if (gameSelected && this.storeService.isWinTableVisible$$.value) {
			this.storeService.isWinTableVisible$$.next(false);
		}
		this.hamburgerConfig.hamburgerOverlayRef.close();
	}
}
