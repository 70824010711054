import { OverlayRef } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';
import { IPreloaderConfig } from '@preloader/interfaces/ipreloader-config';

/**
 * Интерфейс конфигурации прелоадера
 */
export interface IPreloaderTokenData {
	/**
	 * Конфигурация прелоадера
	 */
	data: IPreloaderConfig;
}

/**
 * Токен для инжектирования конфигурации прелоадера
 */
export const PRELOADER_DATA_TOKEN: InjectionToken<IPreloaderTokenData> = new InjectionToken<IPreloaderTokenData>(
	'PRELOADER_DATA_TOKEN'
);

/**
 * Класс для создания оверлея под прелоадер
 */
export class PreloaderOverlayRef {

	/**
	 * Конструктор класса
	 *
	 * @param {OverlayRef} overlayRef Ссылка на оверлей прелоадера
	 */
	constructor(
		private readonly overlayRef: OverlayRef
	) {}

	/**
	 * Скрыть прелоадер
	 */
	close(): void {
		this.overlayRef.dispose();
	}

}
