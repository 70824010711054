import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

/**
 * Список ориентаций иконки со стрелкой.
 */
export enum ArrowButtonOrientation {
	/**
	 * Влево
	 */
	Left    = 'ab-left',
	/**
	 * Вправо
	 */
	Right   = 'ab-right',
	/**
	 * Вверх
	 */
	Up      = 'ab-up',
	/**
	 * Вниз
	 */
	Down    = 'ab-down'
}

/**
 * Компонент, представляющий из себя обычную кнопку с иконкой стрелки.
 * Направление стрелки задается параметром {@link orientation}
 */
@Component({
	selector: 'app-arrow-button',
	templateUrl: './arrow-button.component.html',
	styleUrls: ['./arrow-button.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ArrowButtonComponent  {

	// -----------------------------
	//  Input properties
	// -----------------------------`

	/**
	 * Ориентация стрелки.
	 */
	@Input()
	orientation: ArrowButtonOrientation = ArrowButtonOrientation.Left;

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие клика по кнопке.
	 */
	@Output()
	readonly arrowButtonClicked = new EventEmitter<never>();

	// -----------------------------
	//  Public functions
	// -----------------------------
	/**
	 * Обработчик клика по кнопке
	 */
	onClickHandler(): void {
		this.arrowButtonClicked.emit();
	}
}
