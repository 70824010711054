import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, merge, Observable, Subject } from 'rxjs';
import { filter, map, mapTo } from 'rxjs/operators';
import { MiniGamesCodes } from '@app/core/enums/mini-games-list.enum';
import { IGameListItem } from '@app/core/interfaces/igame-list-item';
import { IOddsItem } from '@app/core/interfaces/iodds-item';
import { Logger } from '@app/core/services/log/log.service';
import { Action } from '@app/core/services/network/enums/action.enum';
import { WinFeedType } from '@app/core/services/network/enums/win-feed-type';
import { IAbstractResponse } from '@app/core/services/network/interfaces/responses/iabstract-response';
import { IBuyTicketResponse } from '@app/core/services/network/interfaces/responses/ibuy-ticket-response';
import { IFinishGameResponse } from '@app/core/services/network/interfaces/responses/ifinish-game-response';
import { IGameDataSeries, IGetGameDataResponse } from '@app/core/services/network/interfaces/responses/iget-game-data-response';
import { IGetLastTicketDataResponse } from '@app/core/services/network/interfaces/responses/iget-last-ticket-data-response';
import { IGetLastTicketResponse } from '@app/core/services/network/interfaces/responses/iget-last-ticket-response';
import { IGetLobbyDataResponse } from '@app/core/services/network/interfaces/responses/iget-lobby-data-response';
import { IGetPromoProgressResponse } from '@app/core/services/network/interfaces/responses/iget-promo-progress-response';
import { IGetPromosResponse, IPromos } from '@app/core/services/network/interfaces/responses/iget-promos-response';
import { INextGameStepResponse } from '@app/core/services/network/interfaces/responses/inext-game-step-response';
import { GameState } from '@app/core/services/state/enums/game-state.enum';
import { IStoreData } from '@app/core/services/store/custom-game-data.service';
import { IGetUserBonusesResponse } from '@app/core/services/network/interfaces/responses/iget-user-bonuses-response';
import { environment } from '@app/environments/environment';
import { TakeWinState } from '@app/shared/components/take-win/take-win.component';

/**
 * Перечень классов для разных разрешений экранов
 */
export enum ScreenClassName {
	/**
	 * Мобильное разрешение
	 */
	Mobile  = 'mobile',
	/**
	 * Планшетное разрешение
	 */
	Tablet  = 'tablet',
	/**
	 * Десктопное разрешение
	 */
	Desktop = 'desktop'
}

/**
 * Перечисление языков
 */
export enum Languages {
	/**
	 * Украинский
	 */
	Ua  = 'ua',
	/**
	 * Английский
	 */
	En  = 'en',
	/**
	 * Русский
	 */
	Ru  = 'ru'
}

/**
 * Тег для логирования
 */
const TAG = 'StoreService';

/**
 * Сервис-хранилище приложения
 */
@Injectable({
	providedIn: 'root'
})
export class StoreService {

	/**
	 * Признак наличия подключения к серверу регистрации.
	 */
	readonly hasServiceConnection$$ = new BehaviorSubject<boolean>(false);

	/**
	 * Список игр.
	 */
	readonly gameList$$: BehaviorSubject<IGameListItem[]> = new BehaviorSubject<IGameListItem[]>([]);

	/**
	 * Демо-режим или обычный
	 */
	demoMode$$: BehaviorSubject<{[gameCode: number]: boolean}> = new BehaviorSubject({
		[MiniGamesCodes.Crash]: false,
		[MiniGamesCodes.Mines]: false,
		[MiniGamesCodes.Smile]: true,
		[MiniGamesCodes.Tod]: false,
		[MiniGamesCodes.Tower]: false,
		[MiniGamesCodes.Buhta]: false,
		[MiniGamesCodes.Squid]: false,
		[MiniGamesCodes.Color]: false
	});

	/**
	 * Токен юзера, полученный с основного сайта.
	 */
	sid: string | undefined = undefined;

	/**
	 * Демо-токен юзера
	 */
	demoSID: string;

	/**
	 * Код игры.
	 */
	gameCode: MiniGamesCodes | undefined = undefined;

	/**
	 * Выбранная серия.
	 */
	selectedSeries: IGameDataSeries | undefined = undefined;

	/**
	 * Список коэффициентов по выбранной серии.
	 */
	oddsList: Array<IOddsItem> = [];

	/**
	 * Признак целесообразности получения результатов акции.
	 * Если в ответ на прогресс акции получена ошибка, дальнейшие попытки получения прогресса не имеют смысла.
	 */
	isPromoProgressMayBeRequested = true;

	/**
	 * Выполняется ли в данный момент запрос на получение прогресса по акциям
	 */
	isPromoProgressRequestInAction = false;

	/**
	 * Вариант для А/В теста
	 */
	abTestVariant = 0;

	/**
	 * Код терминала (в случае мобильной версии)
	 */
	termCode = 59020;

	/**
	 * Демо-баланс
	 */
	readonly demoBalance$$ = new BehaviorSubject<number>(environment.demoBalance);

	/**
	 * Последний ответ от сервиса на запрос {@link IGetLobbyDataRequest}.
	 */
	readonly getLobbyData$$ = new BehaviorSubject<IGetLobbyDataResponse | undefined>(undefined);

	/**
	 * Детальная информация по игре.
	 */
	readonly gameData$$ = new BehaviorSubject<IGetGameDataResponse | undefined>(undefined);

	/**
	 * Текущий купленный билет.
	 */
	readonly buyTicket$$ = new BehaviorSubject<IBuyTicketResponse | undefined>(undefined);

	/**
	 * Последний недоигранный билет
	 */
	readonly lastTicket$$ = new BehaviorSubject<IGetLastTicketResponse | undefined>(undefined);

	/**
	 * Данные по игре из последнего недоигранного билета
	 */
	readonly lastTicketData$$ = new BehaviorSubject<IGetLastTicketDataResponse | undefined>(undefined);

	/**
	 * Пользовательские бонусы
	 */
	readonly userBonuses$$ = new BehaviorSubject<IGetUserBonusesResponse | undefined>(undefined);

	/**
	 * Наблюдаемая переменная с ответом сервера на следующем шаге
	 */
	readonly nextGameStep$$ = new BehaviorSubject<INextGameStepResponse | undefined>(undefined);

	/**
	 * Наблюдаемая переменная с ответом сервера на следующем шаге для игры "Кальмар"
	 */
	readonly nextGameStep2$$ = new BehaviorSubject<INextGameStepResponse | undefined>(undefined);

	/**
	 * Наблюдаемая переменная с ответом сервера при завершении игры
	 */
	readonly finishGame$$ = new BehaviorSubject<IFinishGameResponse | undefined>(undefined);

	/**
	 * Информация о всех текущих акциях.
	 */
	readonly getPromos$$ = new BehaviorSubject<IGetPromosResponse | undefined>(undefined);

	/**
	 * Ответ от сервера с прогрессом по акциям
	 */
	readonly getPromoProgress$$ = new BehaviorSubject<IGetPromoProgressResponse | undefined>(undefined);

	/**
	 * Текущее состояние приложения.
	 * @see GameState
	 */
	readonly gameState$$ = new BehaviorSubject<GameState>(GameState.BuyTicket);

	/**
	 * Признак, определяющий видимость актуальной таблицы выигрышей, которая используется в мобильной версии на весь экран
	 * по нажатию кнопки Live.
	 */
	readonly isWinTableVisible$$ = new BehaviorSubject<boolean>(false);

	/**
	 * Активная вкладка на таблице рекордов типа {@link WinFeedType}.
	 */
	readonly activeWinFeed$$ = new BehaviorSubject<WinFeedType>(undefined);

	//

	/**
	 * Наблюдаемая переменная с ответом от сервера.
	 * Если в ней ошибка - то скрываются все диалоговые окна и подложки под ними 
	 */
	readonly hideAllOverlayPanelsOnError$$: Subject<IAbstractResponse> = new Subject<IAbstractResponse>();

	/**
	 * Текущий номер билета.
	 */
	readonly ticketNumber$ = merge(
		this.lastTicket$$.asObservable()
			.pipe(filter(p => !!p && !!p.ticket), map(m => m?.ticket?.number)),
		this.buyTicket$$.asObservable()
			.pipe(filter(p => !!p), map(m => m?.ticket.number)),
		this.gameState$$.asObservable()
			.pipe(
				filter(f => f === GameState.ChangedSettingsAfterGame || f === GameState.BuyTicket),
				mapTo('')
			)
	);

	/**
	 * Текущая сумма выигрыша в гривнах.
	 */
	readonly winSumma$: Observable<number> = merge(
		this.buyTicket$$.asObservable()
			.pipe(
				mapTo(0)
			),
		this.lastTicketData$$.asObservable()
			.pipe(
				filter((f: IGetLastTicketDataResponse | undefined) => !!f),
				map((m: IGetLastTicketDataResponse) => Number.isInteger(m.sum) ? m.sum : 0)
			),
		this.nextGameStep$$.asObservable()
			.pipe(
				filter(f => !!f),
				map(m => m.R === 0 ? m.sum : m.extra.sum)
			),
		this.gameState$$.asObservable()
			.pipe(
				filter(f => f === GameState.ChangedSettingsAfterGame || f === GameState.BuyTicket),
				mapTo(0)
			),
		this.finishGame$$
			.pipe(
				filter(f => !!f),
				map((finishGameResponse: IFinishGameResponse) => {
					if (Number.isInteger(finishGameResponse.extra?.sum)) {
						return finishGameResponse.extra.sum;
					}

					if (Number.isInteger(finishGameResponse.sum)) {
						return finishGameResponse.sum;
					}

					// if ('win' in finishGameResponse) {
					// 	return finishGameResponse[`win`];
					// }

					return 0;
				})
			)
	)
		.pipe(
			map(m => m / 100)
		);

	/**
	 * Текущий шаг в игре.
	 */
	readonly currentStep$: Observable<number> = combineLatest([
		this.buyTicket$$.asObservable(),
		this.nextGameStep$$.asObservable(),
		this.lastTicketData$$.asObservable()
	])
		.pipe(
			map((responses: [IBuyTicketResponse, INextGameStepResponse, IGetLastTicketDataResponse]) => {
				return responses
					.filter(f => !!f)
					.sort((a: IAbstractResponse, b: IAbstractResponse) => b.timestamp - a.timestamp);
			}),
			map((responses: IAbstractResponse[]) => {
				if (responses.length === 0 || responses[0].action === Action.BuyTicket) {
					return NaN;
				}

				const stepData = responses[0] as INextGameStepResponse | IGetLastTicketDataResponse;

				return stepData.step;
			})
		);

	/**
	 * Признак, указывающий на возможность нажатия кнопки "Забрать выигрыш".
	 */
	readonly takeWinIsDisabled$: Observable<boolean> = combineLatest([
		this.currentStep$
			.pipe(
				map((step: number) => !step)
			),
		this.gameState$$.asObservable()
			.pipe(
				map((gameState: GameState) => gameState !== GameState.GameTime)
			)
	])
		.pipe(
			map(([firstStep, notGameTime]: [boolean, boolean]) => firstStep || notGameTime)
		);

	/**
	 * Состояние кнопки "Забрать выигрыш".
	 */
	readonly takeWinState$: Observable<TakeWinState> = this.gameState$$.asObservable()
		.pipe(
			map((gameState: GameState) => gameState === GameState.ShowWin ? TakeWinState.WinningsTaken : TakeWinState.TakeWin)
		);

	/**
	 * Признак активности кнопки "Купить билет (играть)".
	 */
	readonly buyButtonIsActive$: Observable<boolean> = this.gameState$$.asObservable()
		.pipe(
			map((gameState: GameState) => gameState === GameState.ShowWin
				|| gameState === GameState.ShowBigWin
				|| gameState === GameState.ShowLosing
				|| gameState === GameState.BuyTicket
				|| gameState === GameState.ChangedSettingsAfterGame
				|| gameState === GameState.RequestIsActive
			)
		);

	/**
	 * Признак активности кнопки "Остановить игру".
	 */
	readonly stopButtonIsActive$: Observable<boolean> = this.gameState$$.asObservable()
		.pipe(
			map((gameState: GameState) => gameState === GameState.GameTime)
		);

	/**
	 * Признак активности кнопки "Продолжить игру".
	 */
	readonly continueButtonIsActive$: Observable<boolean> = this.gameState$$.asObservable()
		.pipe(
			map((gameState: GameState) => gameState === GameState.GamePaused)
		);

	/**
	 * Признак мобильного разрешения
	 */
	readonly isMobileScreen$ = this.breakpointObserver
		.observe(['(min-width: 0) and (max-width: 767px)'])
		.pipe(
			map((bs: BreakpointState) => bs.matches)
		);

	/**
	 * Признак планшетного разрешения
	 */
	readonly isTabletScreen$ = this.breakpointObserver
		.observe(['(min-width: 768px) and (max-width: 991px)'])
		.pipe(
			map((bs: BreakpointState) => bs.matches)
		);

	/**
	 * Признак десктопного разрешения
	 */
	readonly isDesktopScreen$ = this.breakpointObserver
		.observe(['(min-width: 992px)'])
		.pipe(
			map((bs: BreakpointState) => bs.matches)
		);

	/**
	 * Содержит имя класса типа {@link ScreenClassName ScreenClassName}, соответствующее текущему разрешению экрана.
	 */
	readonly screenClassName$: Observable<ScreenClassName | undefined> = combineLatest([
		this.isMobileScreen$,
		this.isTabletScreen$,
		this.isDesktopScreen$
	])
		.pipe(
			map(([mobile, tablet, desktop]: [boolean, boolean, boolean]) =>
				mobile
					? ScreenClassName.Mobile
					: tablet
					? ScreenClassName.Tablet
					: desktop
					? ScreenClassName.Desktop
					: undefined
			)
		);

	/**
	 * Признак мобильного и планшетного разрешения
	 */
	readonly isMobileAndTablet$ = combineLatest([this.isMobileScreen$, this.isTabletScreen$])
		.pipe(
			map(([mobile, tablet]: [boolean, boolean]) => (mobile || tablet))
		);

	/**
	 * Признак планшетного и десктопного разрешения
	 */
	readonly isTabletAndDesktop$ = combineLatest([this.isTabletScreen$, this.isDesktopScreen$])
		.pipe(
			map(([tablet, desktop]: [boolean, boolean]) => (tablet || desktop))
		);

	/**
	 * Признак низкого разрешения
	 */
	readonly isLowResolution$ = this.breakpointObserver
		.observe(['(min-width: 0) and (max-width: 1024px)'])
		.pipe(
			map((bs: BreakpointState) => bs.matches)
		);

	/**
	 * Наблюдаемое значение с пользовательскими данными по игре, сохраненные в localStorage.
	 */
	customGameData$: Observable<IStoreData | undefined>;

	/**
	 * Триггер, по которому необходимо сделать прокрутку к таблице рекордов.
	 */
	scrollToWinTable$: Observable<never>;

	// -----------------------------
	//  Private properties
	// -----------------------------

	/**
	 * Триггер, по которому необходимо сделать прокрутку к таблице рекордов (внутренняя переменная)
	 */
	private readonly _scrollToWinTable$$: Subject<never> = new Subject<never>();

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {BreakpointObserver} breakpointObserver Наблюдатель за изменением разрешения
	 */
	constructor(
		private readonly breakpointObserver: BreakpointObserver
	) {
		this.scrollToWinTable$ = this._scrollToWinTable$$.asObservable();

		this.lastTicket$$.asObservable()
			.pipe(
				filter((p: IGetLastTicketResponse | undefined) => !!p && !!p.ab)
			)
			.subscribe((value: IGetLastTicketResponse | undefined) => {
				this.abTestVariant = value.ab;
			});
	}

	/**
	 * Очистка состояний при создании компонента.
	 */
	cleanUpOnInit(): void {
		Logger.Log.i(TAG, `cleanUpOnInit`)
			.console();

		this.buyTicket$$.next(undefined);
		this.nextGameStep$$.next(undefined);
		this.finishGame$$.next(undefined);
		this.userBonuses$$.next(undefined);
	}

	/**
	 * Очистка состояний при уничтожении компонента.
	 */
	cleanUpOnDestroy(): void {
		Logger.Log.i(TAG, `cleanUpOnDestroy`)
			.console();

		this.buyTicket$$.next(undefined);
		this.lastTicket$$.next(undefined);
		this.lastTicketData$$.next(undefined);
		this.nextGameStep$$.next(undefined);
		this.finishGame$$.next(undefined);
		this.gameState$$.next(GameState.BuyTicket);
	}

	/**
	 * Выполнить прокрутку к таблице рекордов.
	 */
	scrollToWinTable(): void {
		Logger.Log.i(TAG, `scrollToWinTable`)
			.console();

		this._scrollToWinTable$$.next();
	}

	/**
	 * Геттер для получения текущей активной промо-акции
	 */
	get activePromo(): IPromos {
		return this.getPromos$$.value?.promos?.find((promo: IPromos) => {
			const prStartTS = (new Date(promo.start)).getTime();
			const prEndTS = (new Date(promo.end)).getTime();

			return promo.code >= 701 && promo.code <= 799 && prStartTS <= Date.now() && Date.now() <= prEndTS;
		});
	}

}
