import {
	ComponentType,
	GlobalPositionStrategy,
	Overlay,
	OverlayConfig,
	OverlayRef,
	RepositionScrollStrategy
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';

import { ComponentRef, Injectable, Injector } from '@angular/core';

import { Observable } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';

import { Logger } from '@app/core/services/log/log.service';
import { ResultCode } from '@app/core/services/network/enums/result-code.enum';
import { IFinishExtraData } from '@app/core/services/network/interfaces/responses/inext-game-step-response';
import { StoreService } from '@app/core/services/store/store.service';

import { environment } from '@app/environments/environment';

import { AbstractDialogComponent } from '@dialogs/components/abstract-dialog.component';
import { ErrorDialogComponent } from '@dialogs/components/error-dialog/error-dialog.component';
import { InfoDialogComponent } from '@dialogs/components/info-dialog/info-dialog.component';
import { DIALOG_DATA_TOKEN } from '@dialogs/constants/game-dialog-constants.service';
import { IDialogConfig } from '@dialogs/interfaces/idialog-config.service';
import { GameDialogOverlayRef } from '@dialogs/services/game-dialogs-overlay-ref.service';

/**
 * Тег для логирования
 */
const TAG = 'GameDialogsService';

/**
 * Сервис, обеспечивающий отображение диалогов в приложении.
 */
@Injectable({
	providedIn: 'root'
})
export class GameDialogsService {

	// -----------------------------
	//  Private properties
	// -----------------------------

	/**
	 * Ссылка на подложку диалоговых окон
	 * @private
	 */
	private _gameDialogOverlayRef: GameDialogOverlayRef;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса.
	 *
	 * @param {Injector} injector Инжектор для загрузки констант и сервисов
	 * @param {Overlay} overlay Объект подложки диалоговых окон
	 * @param {TranslateService} translateService Сервис переводов
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 */
	constructor(
		private readonly injector: Injector,
		private readonly overlay: Overlay,
		private readonly translateService: TranslateService,
		private readonly storeService: StoreService
	) {}

	/**
	 * Отобразить диалог с информационными сообщениями.
	 */
	showInfoDialog(message: string): Observable<never> {
		Logger.Log.i(TAG, `showInfoDialog => message: ${message}`)
			.console();

		this._gameDialogOverlayRef?.close();
		this._gameDialogOverlayRef = this.creatDialogOverlayRef(InfoDialogComponent, { message });

		return this._gameDialogOverlayRef.closeHandler$$.asObservable();
	}

	/**
	 * Отобразить диалог с ошибками.
	 */
	showErrorDialog(code: ResultCode): Observable<void> {
		Logger.Log.i(TAG, `showErrorDialog => code: ${code}`)
			.console();

		const errKey = `dialogs.error-${code}`;
		let message = this.translateService.instant(errKey, { code });
		if (message === errKey) {
			message = this.translateService.instant('dialogs.error-common', { code });
		}
		if ([
			ResultCode.SID_SessionError, ResultCode.MaxBetLimit,
			ResultCode.MaxLoseLimit, ResultCode.MaxFillSumLimit
		].indexOf(code) > -1) {
			message += ` ${this.translateService.instant('dialogs.manage-limits', { link: environment.cabinet })}`;
		}

		if (code === 4313) {
			message = this.translateService.instant('dialogs.error_4313', { code, link: environment.cabinet });
		}

		this._gameDialogOverlayRef?.close();
		this._gameDialogOverlayRef = this.creatDialogOverlayRef(ErrorDialogComponent, { message, code });

		return this._gameDialogOverlayRef.closeHandler$$.asObservable();
	}

	/**
	 * Открыть диалог с большим выигрышем.
	 *
	 * @param {IFinishExtraData} extraData Данные от сервиса.
	 */
	showBigWinDialog(extraData: IFinishExtraData): Observable<void> {
		Logger.Log.d(TAG, `showBigWinDialog => extraData:`, extraData)
			.console();

		const sum = extraData.sum / 100;
		const link = `${environment.urlBigWin}${extraData.macCode}`;
		const message = this.translateService.instant('dialogs.big-win', { sum, link });
		this._gameDialogOverlayRef = this.creatDialogOverlayRef(InfoDialogComponent, { message });

		// this.showInfoDialog(message)
		// 	// .pipe(take(1))
		// 	// .subscribe(() => {
		// 		// this.storeService.resetGame();
		// 		// this.initGameByData();
		// 	// });

		return this._gameDialogOverlayRef.closeHandler$$.asObservable();
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Функция создания диалога.
	 *
	 * @param {ComponentType<AbstractDialogComponent>} component Тип диалога
	 * @param {IDialogConfig} dialogConfig Конфигурация диалога
	 * @returns {GameDialogOverlayRef}
	 */
	private creatDialogOverlayRef(component: ComponentType<AbstractDialogComponent>, dialogConfig: IDialogConfig): GameDialogOverlayRef {
		const overlayConfig: OverlayConfig = this.getOverlayConfig();
		const overlayRef: OverlayRef = this.overlay.create(overlayConfig);

		const dialogOverlayRef: GameDialogOverlayRef = new GameDialogOverlayRef(overlayRef);
		const injectionTokens: WeakMap<object, IDialogConfig | GameDialogOverlayRef> = new WeakMap();
		injectionTokens.set(GameDialogOverlayRef, dialogOverlayRef);
		injectionTokens.set(DIALOG_DATA_TOKEN, dialogConfig);

		const injector = Injector.create({
			providers: [{
				provide: DIALOG_DATA_TOKEN,
				useValue: { ...dialogConfig, dialogOverlayRef }
			}]
		});
		const containerPortal: ComponentPortal<AbstractDialogComponent> = new ComponentPortal(component, undefined, injector);
		const containerRef: ComponentRef<AbstractDialogComponent> = overlayRef.attach(containerPortal);

		// по клику на подложке вызвать метод закрытия меню
		overlayRef
			.backdropClick()
			.subscribe(() => {
				Logger.Log.i(TAG, `backdropClick`)
					.console();

				containerRef.instance.onCloseButtonClickHandler();
			});

		return dialogOverlayRef;
	}

	/**
	 * Получить конфигурацию диалогового окна и его подложки
	 * @private
	 */
	private getOverlayConfig(): OverlayConfig {
		const scrollStrategy: RepositionScrollStrategy = this.overlay.scrollStrategies.reposition();
		const positionStrategy: GlobalPositionStrategy = this.overlay
			.position()
			.global()
			.centerHorizontally()
			.top('200px');

		return new OverlayConfig({
			hasBackdrop: true,
			height: '200px',
			width: '70%',
			maxWidth: '600px',
			minWidth: '340px',
			scrollStrategy,
			positionStrategy
		});
	}

}
