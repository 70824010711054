import { Component, OnDestroy, OnInit } from '@angular/core';
import { Channel } from '@app/core/services/network/enums/channel.enum';
import { WinFeedType } from '@app/core/services/network/enums/win-feed-type';
import { IGetPromoProgressResponse } from '@app/core/services/network/interfaces/responses/iget-promo-progress-response';
import { StoreService } from '@app/core/services/store/store.service';
import { getWinFeedChannelByType } from '@winTable/constants/win-table-constants';
import { WinTableService } from '@winTable/services/win-table.service';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators';

/**
 * Тег для логирования
 */
const TAG = 'WinTablesComponent';

/**
 * Компонент-таблица выигрышей на которой отображены вкладки:
 * - "Мои ставки"
 * - "Все ставки"
 * - "Горячие моменты".
 * - "Чемпионат"
 */
@Component({
	selector: 'app-win-tables',
	templateUrl: './win-tables.component.html',
	styleUrls: ['./win-tables.component.scss']
})
export class WinTablesComponent implements OnInit, OnDestroy {

	/**
	 * Наблюдаемая переменная для уничтожения всех подписок
	 */
	private readonly unsubscribe$$ = new Subject<never>();

	/**
	 * Список каналов событий
	 */
	readonly Channel = Channel;

	/**
	 * Список типов выигрышных каналов
	 */
	readonly WinFeedType = WinFeedType;

	/**
	 * Текущий канал событий
	 */
	channel: Channel;

	/**
	 * Конструктор компонента
	 * @param storeService Сервис-хранилище приложения
	 * @param winTableService Сервис таблиц ставок
	 */
	constructor(readonly storeService: StoreService, readonly winTableService: WinTableService) {
	}

	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.storeService.activeWinFeed$$
			.pipe(
				filter(value => !!value),
				distinctUntilChanged(),
				takeUntil(this.unsubscribe$$)
			)
			.subscribe((winFeedType: WinFeedType) => {
				this.channel = getWinFeedChannelByType(winFeedType);
			});
		this.storeService.getPromoProgress$$.subscribe((progress: IGetPromoProgressResponse) => {
			if (progress) {
				this.winTableService.updateChampTable(progress);
			}
		});
		this.winTableService.showPromo();
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}
}
