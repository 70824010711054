<!-- ПАНЕЛЬ С КНОПКАМИ -->
<app-win-table-button-bar
	data-cy="win-table-button-bar"
></app-win-table-button-bar>

<app-win-table
	class="win-table"
	[class.win-table_visible]="(storeService.activeWinFeed$$ | async) === WinFeedType.My"
	[winFeedType]="WinFeedType.My"
	data-cy="win-table"
></app-win-table>

<app-win-table
	class="win-table"
	[class.win-table_visible]="(storeService.activeWinFeed$$ | async) === WinFeedType.All"
	[winFeedType]="WinFeedType.All"
	data-cy="win-table"
></app-win-table>

<app-win-table
	class="win-table"
	[class.win-table_visible]="(storeService.activeWinFeed$$ | async) === WinFeedType.Hot"
	[winFeedType]="WinFeedType.Hot"
	data-cy="win-table"
></app-win-table>

<app-win-table *ngIf="storeService.activePromo"
	class="win-table"
	[class.win-table_visible]="(storeService.activeWinFeed$$ | async) === WinFeedType.Promo"
	[winFeedType]="WinFeedType.Promo"
	data-cy="win-table"
></app-win-table>
