import { Directive, ElementRef, Input, OnChanges, SimpleChanges } from '@angular/core';

import { interval, Observable, Subscription, timer } from 'rxjs';
import { take, tap } from 'rxjs/operators';

/**
 * Модель параметров для циклической анимации.
 */
export interface ICycleAnimationSpriteData {

	/**
	 * Запустить `true` или остановить `false` циклическую анимацию.
	 */
	run: boolean;

	/**
	 * Скорость анимации спрайта в миллисекундах для циклической последовательности.
	 * Если не задано, то будет использован соответствующий параметр из директивы {@link AnimatedSpriteDirective}.
	 */
	frameRate?: number;

}

/**
 * Директива для анимации спрайтов.
 */
@Directive({
	selector: '[appAnimatedSprite]'
})
export class AnimatedSpriteDirective implements  OnChanges {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Ширина кадра анимации.
	 */
	@Input()
	frameWidth: number;

	/**
	 * Высота кадра анимации.
	 */
	@Input()
	frameHeight: number;

	/**
	 * Количество кадров анимации в спрайте.
	 */
	@Input()
	frameCount: number;

	/**
	 * Стартовый кадр
	 */
	@Input()
	startFrame = 0;

	/**
	 * Направление, 1 - вперед, -1 - назад
	 */
	@Input()
	direction: 1 | -1 = 1;

	/**
	 * Скорость анимации спрайта в миллисекундах.
	 */
	@Input()
	frameRate = 20;

	/**
	 * Количество повторов
	 */
	@Input()
	repeatCount = 2;

	/**
	 * Активировать ли анимацию
	 */
	@Input()
	activate: boolean | unknown;

	/**
	 * Параметр, инициирующий и описывающий циклическую анимацию.
	 */
	@Input()
	cycleAnimationSpriteData: ICycleAnimationSpriteData;

	// -----------------------------
	//  Private properties
	// -----------------------------

	/**
	 * Подписка на выполнение анимации
	 * @private
	 */
	private _cycleAnimationSubscription: Subscription;

	/**
	 * Текущий кадр анимации
	 * @private
	 */
	private _currentFrame = 0;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор директивы.
	 *
	 * @param {ElementRef} el Ссылка на элемент, к которому применяется анимация
	 */
	constructor(
		private readonly el: ElementRef
	) {}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------

	/**
	 * Обработчик события изменений каких-либо переменных в директиве
	 * @param changes Изменения
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if (!!changes.cycleAnimationSpriteData && !!changes.cycleAnimationSpriteData.currentValue) {
			if (this.cycleAnimationSpriteData?.run) {
				this.playCycleAnimation();
			} else {
				this.el.nativeElement.style[`background-position-x`] = `0px`;
				this._cycleAnimationSubscription?.unsubscribe();
				this._cycleAnimationSubscription = undefined;
			}

			return;
		}

		if (!!changes.frameCount) {
			this._currentFrame = 0;
			this.updateSprite();
		}

		if (!!changes.activate) {
			if (changes.activate.currentValue) {
				this.el.nativeElement.style.opacity = 1;
				this.play()
					.subscribe();
			} else {
				this.el.nativeElement.style.opacity = 0;
				if (this._currentFrame !== this.startFrame) {
					this._currentFrame = this.startFrame;
					this.updateSprite();
				}
			}
		}

		if (!!changes.startFrame) {
			if (this._currentFrame !== changes.startFrame.currentValue) {
				this._currentFrame = changes.startFrame.currentValue;
				this.updateSprite();
			}
		}
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Обновить текущий кадр анимации.
	 */
	private updateSprite(): void {
		this.el.nativeElement.style[`background-position-x`] = `${-this._currentFrame * this.frameWidth * 0.5}px`;
	}

	/**
	 * Проиграть анимацию
	 * @private
	 */
	private play(): Observable<number> {
		return interval(this.frameRate)
			.pipe(
				take(this.repeatCount ? this.frameCount * this.repeatCount : this.frameCount - 1),
				tap(() => {
					let nextFrame = this._currentFrame + this.direction;
					if (nextFrame < 0) {
						nextFrame = this.frameCount - 1;
					}

					this._currentFrame = nextFrame % this.frameCount;
					this.updateSprite();
				})
			);
	}

	/**
	 * Запустить циклическую анимацию при получении соответствующего параметра {@link cycleAnimationSpriteData}.
	 */
	private playCycleAnimation(): void {
		this._currentFrame = 0;

		this._cycleAnimationSubscription = timer(0, this.cycleAnimationSpriteData?.frameRate ?? this.frameRate)
			.subscribe(() => {
				this.el.nativeElement.style[`background-position-x`] = `${-this._currentFrame * this.frameWidth}px`;
				this._currentFrame = (this._currentFrame + 1) % this.frameCount;
			});
	}

}
