import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CommunicationService } from '@app/core/services/network/communication.service';
import { WinFeedType } from '@app/core/services/network/enums/win-feed-type';
import { IGetPromoProgressResponse } from '@app/core/services/network/interfaces/responses/iget-promo-progress-response';
import { CustomGameDataService } from '@app/core/services/store/custom-game-data.service';
import { StoreService } from '@app/core/services/store/store.service';
import { environment } from '@app/environments/environment';
import { DEFAULT_WIN_FEED } from '@winTable/constants/win-table-constants';
import { WinTableService } from '@winTable/services/win-table.service';
import { interval, Subscription } from 'rxjs';

/**
 * Компонент переключения между таблицами ставок
 */
@Component({
	selector: 'app-win-table-button-bar',
	templateUrl: './win-table-button-bar.component.html',
	styleUrls: ['./win-table-button-bar.component.scss']
})
export class WinTableButtonBarComponent implements OnInit {

	/**
	 * Прогресс по коду промо-акции
	 * @private
	 */
	private progressTaken = {};

	/**
	 * Подписка на таймер
	 * @private
	 */
	private timeSubscription: Subscription = undefined;

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Признак, указывающий на необходимость скрыть кнопку закрытия таблицы рекордов
	 * независимо от текущего размера экрана.
	 * Например, для отдельной страницы кнопка будет скрыта в любом размере.
	 * По умолчанию кнопка будет видна в мобильном разрешении и не будет принудительно скрываться.
	 */
	@Input()
	hideCloseButton = false;

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Список типов выигрышных каналов
	 */
	readonly WinTableFeedButton: typeof WinFeedType = WinFeedType;

	/**
	 * Ссылка на страницу с перечнем ставок
	 */
	readonly cab = environment.bets;

	/**
	 * Оставшееся время до конца турнира (из последней промо-акции)
	 */
	timeRemaining = '';

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента
	 *
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 * @param {CustomGameDataService} customGameDataService Сервис хранения данных типа {@link IStoreData IStoreData} по играм
	 * @param cdr Детектор обнаружения изменений
	 * @param communicationService Сервис взаимодействия с бекендом
	 * @param winTableService Сервис таблиц выигрыша
	 */
	constructor(
		readonly storeService: StoreService,
		private readonly customGameDataService: CustomGameDataService,
		private readonly cdr: ChangeDetectorRef,
		private readonly communicationService: CommunicationService,
		readonly winTableService: WinTableService
	) {}

	/**
	 * Обработчик переключения таблиц ставок по клику на соответствующие кнопки
	 * @param winFeedType Тип выигрышного канала
	 */
	onWinFeedButtonClick(winFeedType: WinFeedType): void {
		this.storeService.activeWinFeed$$.next(winFeedType);
		this.customGameDataService.updateCustomApplicationData({ winFeed: winFeedType });
		if (winFeedType === WinFeedType.Promo) {
			this.winTableService.showPromo();
		}
	}

	/**
	 * Скрыть таблицу на мобильном разрешении
	 */
	onCloseTable(): void {
		this.storeService.isWinTableVisible$$.next(false);
	}

	/**
	 * Сменить лигу игроков на другую (для последней промо-акции)
	 * @param league Лига, на которую меняем
	 */
	onLeagueChange(league: string): void {
		this.storeService.activeWinFeed$$.next(WinFeedType.Promo);
		this.customGameDataService.updateCustomApplicationData({ winFeed: WinFeedType.Promo });
		this.winTableService.leadersType$$.next(league);
		this.winTableService.showPromo();
	}

	/**
	 * Обновить строку времени. Истина - когда 0:00:00
	 * @param endDate Конечная дата и время
	 * @private
	 */
	private updateTimerString(endDate: string): boolean {
		const endPromoDate = (new Date(endDate)).getTime();
		const delta = Math.floor((endPromoDate - Date.now()) / 1000);
		const hours = Math.floor(delta / 3600);
		const minutes = Math.floor((delta % 3600) / 60)
			.toString()
			.padStart(2, '0');
		let seconds: string;

		if ((hours === 0) && (+minutes === 0)) {
			seconds = (delta % 60)
				.toString()
				.padStart(2, '0');
			this.timeRemaining = ` (${hours}<span class="blink">:</span>${minutes}<span class="blink">:</span>${seconds})`;
			if (+seconds === 0) {
				return true;
			}
		} else {
			this.timeRemaining = ` (${hours}<span class="blink">:</span>${minutes})`;
		}

		return false;
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.winTableService.firstTime = true;
		this.timeSubscription = interval(500)
			.subscribe(() => {
				// this.hasPromo = !!this.activePromo;
				if (this.storeService.activePromo) {
					if (!this.progressTaken[this.storeService.activePromo.code]) {
						const progress = this.storeService.getPromoProgress$$.value as IGetPromoProgressResponse;
						if (progress && !this.winTableService.firstTime) {
							progress.blueLeaders = [];
							progress.orangeOutsiders = [];
							progress.orangeLeaders = [];
							if (progress.self) {
								progress.self.stars = 0;
								progress.self.league = 'blue';
							}
							this.storeService.getPromoProgress$$.next(progress);
						}
						this.winTableService.firstTime = false;
						this.progressTaken[this.storeService.activePromo.code] = true;
					}
					this.updateTimerString(this.storeService.activePromo.end);
				} else {
					if (this.storeService.activeWinFeed$$.value === WinFeedType.Promo) {
						this.storeService.activeWinFeed$$.next(DEFAULT_WIN_FEED);
					}
				}
				this.cdr.detectChanges();
			});

		const customAppData = this.customGameDataService.getCustomApplicationData();
		if (customAppData && customAppData.winFeed) {
			this.storeService.activeWinFeed$$.next(customAppData.winFeed);
		} else {
			this.storeService.activeWinFeed$$.next(DEFAULT_WIN_FEED);
		}

		this.cdr.detectChanges();
	}
}
