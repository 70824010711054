import { Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Список возможных типов кнопки
 */
export enum PlusMinusIconType {
	/**
	 * Кнопка "-"
	 */
	Minus = 'minus',
	/**
	 * Кнопка "+"
	 */
	Plus = 'plus'
}

/**
 * Компонент, представляющий из себя обычную кнопку с иконкой "+" или "-".
 * Тип кнопки задается параметром {@link iconType}
 */
@Component({
	selector: 'app-plus-minus-button',
	templateUrl: './plus-minus-button.component.html',
	styleUrls: ['./plus-minus-button.component.scss']
})
export class PlusMinusButtonComponent  {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Тип кнопки (иконки).
	 */
	@Input()
	iconType: PlusMinusIconType;

	/**
	 * Признак запрета ввода.
	 */
	@Input()
	disabled: boolean;

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие клика по кнопке +/-.
	 */
	@Output()
	readonly plusMinusButtonClicked: EventEmitter<PlusMinusIconType> = new EventEmitter<PlusMinusIconType>();

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Список возможных типов кнопки для использования в шаблоне
	 */
	readonly PlusMinusIconType: typeof PlusMinusIconType = PlusMinusIconType;

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события клика по кнопке +/-
	 */
	onClickHandler(): void {
		this.plusMinusButtonClicked.emit(this.iconType);
	}
}
