import { MiniGamesCodes, MiniGamesUrls } from '@app/core/enums/mini-games-list.enum';

/**
 * Модель, используемая для идентификации модуля-страницы сторонними приложениями.
 */
export interface ModuleRouteId {
	/**
	 * URL модуля
	 */
	moduleUrl: string;
	/**
	 * Код игры
	 */
	gameCode: MiniGamesCodes;
}

// -----------------------------
//  URL path
// -----------------------------

/**
 * Путь к странице с лобби (списком игр).
 */
export const URL_GAME_LIST				= '';

/**
 * Путь к странице с ошибкой по ненайденному ресурсу (404).
 */
export const URL_NOT_FOUND              = 'not-found';

/**
 * Путь к странице, на которой отображены: "Мои ставки", Live, "горячие моменты".
 */
export const URL_GAME_LIVE              = 'live';

/**
 * Модуль переадресации игр на основе различных исходных данных.
 */
export const URL_GAME_REDIRECT		    = 'redirect';

/**
 * Внутренний маршрут игры "Сапер"
 */
export const URL_GAME_MINES				= MiniGamesUrls.Mines;

/**
 * Внутренний маршрут игры "Башня"
 */
export const URL_GAME_TOWER				= MiniGamesUrls.Tower;

/**
 * Внутренний маршрут игры "Смайл"
 */
export const URL_GAME_SMILE				= MiniGamesUrls.Smile;

/**
 * Внутренний маршрут игры "Креш"
 */
export const URL_GAME_CRASH             = MiniGamesUrls.Crash;

/**
 * Внутренний маршрут игры "Сокровища судьбы"
 */
export const URL_GAME_TOD               = MiniGamesUrls.Tod;

/**
 * Внутренний маршрут игры "Бухта"
 */
export const URL_GAME_BUHTA             = MiniGamesUrls.Buhta;

/**
 * Внутренний маршрут игры "Кальмар"
 */
export const URL_GAME_SQUID             = MiniGamesUrls.Squid;

/**
 * Внутренний маршрут игры "Четыре цвета"
 */
export const URL_GAME_COLOR             = MiniGamesUrls.Color;

/**
 * Старый внутренний маршрут игры "Сапер"
 */
export const URL_GAME_MINES_OLD				= `game-${MiniGamesUrls.Mines}`;

/**
 * Старый внутренний маршрут игры "Башня"
 */
export const URL_GAME_TOWER_OLD				= `game-${MiniGamesUrls.Tower}`;

/**
 * Старый внутренний маршрут игры "Смайл"
 */
export const URL_GAME_SMILE_OLD				= `game-${MiniGamesUrls.Smile}`;

/**
 * Старый внутренний маршрут игры "Креш"
 */
export const URL_GAME_CRASH_OLD             = `game-${MiniGamesUrls.Crash}`;

/**
 * Старый внутренний маршрут игры "Сокровища судьбы"
 */
export const URL_GAME_TOD_OLD               = `game-${MiniGamesUrls.Tod}`;

/**
 * Старый внутренний маршрут игры "Бухта"
 */
export const URL_GAME_BUHTA_OLD             = `game-${MiniGamesUrls.Buhta}`;

/**
 * Маршрут, по которому показывается страница с диалогом не разыгранного билета
 */
export const FRAGMENT_TO_SKIP_DIALOG    = 'skipUnfinishedDialog';

/**
 * Параметр активной вкладки
 */
export const PARAM_ACTIVE_TAB           = 'activeTab';

/**
 * Параметр кода игры
 */
export const PARAM_GAME_CODE            = 'gameCode';

// -----------------------------
//  Public functions
// -----------------------------

/**
 * Преобразовывает код игры в часть маршрута, соответствующей игры.
 *
 * @param {number} gameCode Код игры.
 * @returns {string} Часть маршрута к игре, соответствующего коду игры.
 */
export const convertGameCodeToPath = (gameCode: number): string | undefined => {
	switch (gameCode) {
		case MiniGamesCodes.Mines:
			return MiniGamesUrls.Mines;
		case MiniGamesCodes.Tower:
			return MiniGamesUrls.Tower;
		case MiniGamesCodes.Smile:
			return MiniGamesUrls.Smile;
		case MiniGamesCodes.Crash:
			return MiniGamesUrls.Crash;
		case MiniGamesCodes.Tod:
			return MiniGamesUrls.Tod;
		case MiniGamesCodes.Buhta:
			return MiniGamesUrls.Buhta;
		case MiniGamesCodes.Squid:
			return MiniGamesUrls.Squid;
		case MiniGamesCodes.Color:
			return MiniGamesUrls.Color;

		// ...
		// ... новые игры
		// ...

		default:
			return undefined;
	}
};

/**
 * Преобразовывает код игры в ссылку к ресурсу, представляющему иконку игры.
 *
 * @param {number} gameCode Код игры.
 * @returns {string} Ссылка на ресурс с иконкой, соответствующего коду игры.
 */
export const convertGameCodeToIconSrc = (gameCode: number): string => {
	switch (gameCode) {
		case MiniGamesCodes.Mines:
			return 'assets/img/mines/bomb-nav.png';
		case MiniGamesCodes.Tower:
			return 'assets/img/tower/tower-nav.png';
		case MiniGamesCodes.Smile:
			return 'assets/img/smile/nav-icon.png';
		case MiniGamesCodes.Crash:
			return 'assets/img/crash/crash-nav.png';
		case MiniGamesCodes.Tod:
			return 'assets/img/tod/tod-nav.png';
		case MiniGamesCodes.Buhta:
			return 'assets/img/buhta/bay-nav.png';
		case MiniGamesCodes.Squid:
			return 'assets/img/squid/squid-nav.svg';
		case MiniGamesCodes.Color:
			return 'assets/img/color/color-nav.svg';

		// ...
		// ... новые игры
		// ...

		default:
			return '';
	}
};
