<button (click)="closeButtonClicked.emit()"
		data-cy="close-button"
>
	<svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg" data-cy="close-button-icon">
		<path
			d="M7.62811 6.03251L11.8148 1.85495C11.9981 1.67159 12.1011 1.42289 12.1011
				1.16356C12.1011 0.904243 11.9981 0.655542 11.8148 0.472174C11.6315 0.288806
				11.3828 0.185791 11.1235 0.185791C10.8642 0.185791 10.6156 0.288806 10.4322 0.472174L6.25526
				4.65947L2.0783 0.472174C1.89496 0.288806 1.6463 0.185791 1.38701 0.185791C1.12773 0.185791 0.879061
				0.288806 0.695719 0.472174C0.512377 0.655542 0.409376 0.904243 0.409376 1.16356C0.409376 1.42289 0.512377
				1.67159 0.695719 1.85495L4.88242 6.03251L0.695719 10.2101C0.60446 10.3006 0.532026 10.4083 0.482596
				10.527C0.433165 10.6456 0.407715 10.7729 0.407715 10.9015C0.407715 11.03 0.433165 11.1573 0.482596
				11.2759C0.532026 11.3946 0.60446 11.5023 0.695719 11.5928C0.786232 11.6841 0.893919 11.7566 1.01257
				11.806C1.13122 11.8554 1.25848 11.8809 1.38701 11.8809C1.51554 11.8809 1.64281 11.8554 1.76145 11.806C1.8801
				11.7566 1.98779 11.6841 2.0783 11.5928L6.25526 7.40555L10.4322 11.5928C10.5227 11.6841 10.6304 11.7566 10.7491
				11.806C10.8677 11.8554 10.995 11.8809 11.1235 11.8809C11.252 11.8809 11.3793 11.8554 11.498 11.806C11.6166
				11.7566 11.7243 11.6841 11.8148 11.5928C11.9061 11.5023 11.9785 11.3946 12.0279 11.2759C12.0774 11.1573 12.1028
				11.03 12.1028 10.9015C12.1028 10.7729 12.0774 10.6456 12.0279 10.527C11.9785 10.4083 11.9061 10.3006 11.8148
				10.2101L7.62811 6.03251Z"
			fill="#4C526A"
		/>
	</svg>
</button>
