import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ScrollerComponent } from '@scroller/components/scroller/scroller.component';
import { ScrollerDirective } from '@scroller/directives/scroller/scroller.directive';

/**
 * Модуль пользовательской прокрутки контента.
 */
@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		ScrollerDirective,
		ScrollerComponent
	],
	exports: [
		ScrollerComponent
	]
})
export class ScrollerModule {}
