/**
 * Список состояний приложения:
 * - {@link BuyTicket} - необходимо купить билет для начала игры
 * - {@link GameTime} - процесс игры
 * - {@link GamePaused} - игра на паузе (в случае использование игр со потоком данных)
 * - {@link ShowLosing} - показать результат игры (проигрыш)
 * - {@link ShowWin} - показать результат игры (выигрыш с автозачислением денег на счет игрока)
 * - {@link ShowBigWin} - показать результат игры (большой выигрыш с получением денег через форму на сайте)
 * - {@link ChangedSettingsAfterGame} - отобразить состояние игры после выигрыша или проигрыша после смены одного из параметров ставки
 * - {@link RequestIsActive} - состояние выполнения запроса на сервер (после выполнения запроса вернется предыдущее состояние)
 * - {@link ShowBonusError} - показать ошибку выплаты выигрыша после 5 спецсимволов
 */
export enum GameState {

	BuyTicket                   = 'state-buy-ticket',
	GameTime                    = 'state-game-time',
	GamePaused                  = 'state-game-paused',
	ShowLosing      	        = 'state-show-losing',
	ShowWin                     = 'state-show-win',
	ShowBigWin                  = 'state-show-big-win',
	ChangedSettingsAfterGame    = 'bet-changed-after-game',

	RequestIsActive             = 'request-is-active',
	ShowBonusError              = 'state-show-bonus-error'
}
