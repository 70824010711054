import { ErrorHandler, Injectable, Injector } from '@angular/core';

/**
 * Глобальный перехватчик ошибок.
 */
@Injectable()
export class AppErrorHandler implements ErrorHandler {

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор.
	 *
	 * @param {Injector} injector
	 */
	constructor(
		private readonly injector: Injector
	) {}

	/**
	 * Перехватчик глобальных ошибок.
	 *
	 * @param {Error} error Ошибка в процессе выполнения кода.
	 */
	handleError(error: Error): void {
		/*
		if (error.stack) {
			Logger.Log.e('AppErrorHandler', error.stack)
				.console();
		} else {
			Logger.Log.e('AppErrorHandler', error.message)
				.console();
		}
		*/
		console.error('\n\n\n~~~~~~~~~~~~~~ ERROR | AppErrorHandler', error, '\n\n\n');
		// let s = ''; try {s = JSON.stringify(error);} catch (e) { s = error.message;}
		// alert(`error!!!!!: ${s}`);
	}
}
