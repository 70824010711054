<div class="game-list"
	 data-cy="game-list"
	 (click)="onGameClickHandler($event)"
>

	<button id="lobby"
			type="button"
			class="game-list__item"
			data-cy="game-list-item"
	>
		<svg class="game-list__icon game-list__icon_lobby" width="30" height="30" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M14.9998 6.70519C14.9994 6.70483 14.9991 6.70448 14.9987
			6.70413L8.71024 0.415884C8.4422 0.147723 8.08583 0 7.70676 0C7.32769
			0 6.97131 0.147606 6.70315 0.415766L0.417968 6.70084C0.415851 6.70295
			0.413734 6.70519 0.411617 6.7073C-0.138817 7.26091 -0.137876 8.15913
			0.414322 8.71133C0.666605 8.96373 0.999806 9.10993 1.35606 9.12522C1.37053
			9.12663 1.38511 9.12733 1.39981 9.12733H1.65045V13.7551C1.65045 14.6708
			2.39553 15.4159 3.31151 15.4159H5.77177C6.02111 15.4159 6.22341 15.2138 
			6.22341 14.9643V11.3361C6.22341 10.9182 6.56331 10.5783 6.98119 10.5783H8.43232C8.8502 
			10.5783 9.19011 10.9182 9.19011 11.3361V14.9643C9.19011 15.2138 9.39229 15.4159 
			9.64174 15.4159H12.102C13.018 15.4159 13.7631 14.6708 13.7631 13.7551V9.12733H13.9955C14.3744 
			9.12733 14.7308 8.97973 14.9991 8.71157C15.5519 8.15843 15.5521 7.25868 14.9998 6.70519Z" fill="#4D516A"/>
		</svg>
		<span class="game-list__title"
			  data-cy="game-list-title"
		>{{ 'common.all-games' | translate }}</span>
	</button>
	
	<ng-container *ngFor="let game of gameList; trackBy: trackByGameFn">
		<button class="game-list__item"
				[class.game-list__item_green]="storeService.gameCode === game.code"
				[id]="game.id"
				data-cy="game-list-item"
		>
			<img class="game-list__icon game-list__icon_{{ game.code }}"
				[src]="game.iconSrc"
				alt=""
				data-cy="game-list-icon" 
			/>
			<span class="game-list__title game-list__title_{{ game.code }}"
				  *ngIf="alwaysShowLabels || (storeService.isMobileScreen$ | async) === false"
				  data-cy="game-list-title"
			>
				{{ 'common.game-' + game.code | translate }}
			</span>
		</button>
	</ng-container>
</div>
