<div
	class="grf-wrapper"
	(click)="onClickRowHandler($event)"
	data-cy="grf-wrapper"
	>
	
	<ng-container *ngFor="let row of parsedRows; trackBy: trackByRowsFn; let i = index">
		<div class="grf-row" id="grf-row"
			 [id]="row.rowIndex"
			 data-cy="grf-row"
		>
			<!-- ODDS + ШАГ -->
			<div
				appToolTip
				class="grf-odds"
				id="grf-odds"
				[ngClass]="row.styleClass"
				[styleClass]="currentStep > 0 && i === currentStep - 1 ? 'active' : undefined"
				[text]="currentStep > 0 && i === currentStep - 1 && gameState !== GameState.ShowLosing
		 			? ('common.you-won' | translate)
		 			: (i === currentStep && gameState === GameState.GameTime ? ('common.you-can-win' | translate) : undefined)"
				[class.pass-step]="(i < currentStep - 1) && (gameState !== GameState.ShowLosing) && (gameState !== GameState.RequestIsActive)"
				[class.current-step]="(currentStep > 0) && (i === currentStep - 1) &&
				(gameState !== GameState.ShowLosing) && (gameState !== GameState.RequestIsActive)"
				[class.next-step]="rowControl.active && i === currentStep && gameState === GameState.GameTime"
				data-cy="grf-odds"
				>
				<span
					data-cy="grf-odds-span"
				>
					<label
						data-cy="grf-odds-label"
					>x{{
						row?.oddsItem?.odds | odds: {
								isMobile: isMobile,
								lastOddsWithOneDigits: lastOddsWithOneDigits,
								lastOddsWithTwoDigits: lastOddsWithTwoDigits
							}
						}}
					</label>
					<label
						data-cy="grf-odds-label"
					>{{ stepLocalizationKey | translate: { step: row?.oddsItem?.step } }}</label>
				</span>
			</div>

			<!-- КНОПКИ -->
			<div class="grf-buttons"
				 data-cy="grf-buttons"
				 [ngClass]="gameCode === MiniGamesCodes.Smile && rowControl.step === i - 1 && i - 1 > 0
				 	? 'anim-appear'
				 	: rowControl.step === i ? 'anim-grow'
				 	: rowControl.step === i + 1 ? 'anim-reduce'
				 	: rowControl.step === i + 2 ? 'anim-hide'
				 	: ''
				">
				<ng-container *ngFor="let button of row?.rowButtons; trackBy: trackByButtonsFn">
					<ng-container [ngSwitch]="gameCode">
						<!-- ЭЛЕМЕНТ БАШНИ -->
						<ng-container *ngSwitchCase="MiniGamesCodes.Tower">
							<app-tower-button
								[button]="button"
								data-cy="tower-button"
							></app-tower-button>
						</ng-container>
	
						<!-- ЭЛЕМЕНТ СМАЙЛА -->
						<ng-container *ngSwitchCase="MiniGamesCodes.Smile">
							<app-smile-button
								[button]="button"
								[rowControl]="rowControl"
								[smileAnimationIconColumn]="rowControl.active && button.isActiveRow ? (this.smileAnimationEvent$ | async) : undefined"
								data-cy="smile-button"
							></app-smile-button>
						</ng-container>
						
						<!-- ПО УМОЛЧАНИЮ -->
						<ng-container *ngSwitchDefault>
							<label style="color: red"
								   data-cy="default-label"
							>Game code {{ gameCode }} isn't implemented!</label>
						</ng-container>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</ng-container>

</div>
