import {Action} from '@app/core/services/network/enums/action.enum';
import {NextGameStepState} from '@app/core/services/network/enums/next-game-step-state';
import {ResultCode} from '@app/core/services/network/enums/result-code.enum';
import {IGameDataSeries} from '@app/core/services/network/interfaces/responses/iget-game-data-response';
import {seq} from '@app/core/services/network/mocks/constants';
import {interval, Subscription} from 'rxjs';

/**
 * Сумма в грн, начиная с которой выигрыш считается большим
 */
const BIG_WIN = 10 * 100;

/**
 * Mock-класс для веб-сокета
 */
export class MockWebSocket {

	/**
	 * Конструктор Одиночки всегда должен быть скрытым, чтобы предотвратить
	 * создание объекта через оператор new.
	 */
	private constructor() { }
	/**
	 * Экземпляр текущего класса
	 * @private
	 */
	private static instance: MockWebSocket;

	/**
	 * Наконец, любой одиночка должен содержать некоторую бизнес-логику, которая
	 * может быть выполнена на его экземпляре.
	 */
	private crashInterval: Subscription;

	/**
	 * Текущий шаг в игре Креш
	 */
	step = 0;

	/**
	 * Состояние готовности веб-сокета
	 */
	readyState = WebSocket.OPEN;

	/**
	 * Callback-функция приема сообщения от веб-сокета
	 */
	onmessage: (event: MessageEvent) => void;

	/**
	 * Статический метод, управляющий доступом к экземпляру одиночки.
	 *
	 * Эта реализация позволяет вам расширять класс Одиночки, сохраняя повсюду
	 * только один экземпляр каждого подкласса.
	 */
	static getInstance(): MockWebSocket {
		if (!MockWebSocket.instance) {
			MockWebSocket.instance = new MockWebSocket();
		}

		return MockWebSocket.instance;
	}

	/**
	 * Метод для инициализации игры Креш и имитации получения ответов от сервера
	 * @param betAmount Сумма ставки
	 * @param autoStop Коэффициент авто-стопа
	 * @param series Серии игры Креш
	 */
	init(betAmount: number, autoStop: number, series: IGameDataSeries): void {
		this.step = 0;
		
		this.crashInterval = interval(200)
			.subscribe(() => {
				this.step++;
				const isWinStep = Math.random() * 1000 < 1000;
				const odds = this.step * 0.01 + 1;
				const sum = isWinStep ? odds * betAmount * 100 : 0
				const bigWin = isWinStep && sum >= BIG_WIN;
				// Бигвин - это или лимит выплаты или макс. вин
				// У нас произошел лимит выплаты или MAX_WIN ?
				const isPayLimit = Math.random() * 1000 < 500;
				const endOfGame = isWinStep && (odds + 0.01 > autoStop) && !bigWin;
				const state = 	bigWin ? NextGameStepState.BigWin :
								endOfGame ? NextGameStepState.EndOfGame :
								isWinStep ? NextGameStepState.Win : NextGameStepState.Lose;
				const objPart: any = {
					state,
					step: this.step,
					sum,
					odds,
					time: this.step * 200,
					seq
				};
				if (endOfGame || bigWin) {
					objPart.ticketStop = sum + 10;
				}
				const R = bigWin ? ResultCode.BigWin : ResultCode.Ok; 
				const action = (bigWin && isPayLimit) || endOfGame ? Action.FinishGame : Action.Stream;
				const data = bigWin ? { action, R, ...objPart, extra: {...objPart, macCode: '4035900055030389344269480'}, seq} :
					{action, R, ...objPart, seq};
				const ansObj = { event: 'response', data };
				const rawAns = JSON.stringify(ansObj);
				this.onmessage(JSON.parse(rawAns));
				if (!isWinStep || endOfGame || bigWin) {
					if (this.crashInterval) {
						this.crashInterval.unsubscribe();
						this.crashInterval = undefined;
					}
				}
			});
	}

	/**
	 * Метод для остановки игры Креш
	 */
	stop(): void {
		if (this.crashInterval) {
			this.crashInterval.unsubscribe();
			this.crashInterval = undefined;
		}
	}
}
