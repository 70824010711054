import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { merge, Observable, Subject, timer } from 'rxjs';
import { mapTo } from 'rxjs/operators';

import { PRELOADER_DATA_TOKEN } from '@preloader/constants/preloader-constants';
import { IPreloaderConfig } from '@preloader/interfaces/ipreloader-config';

/**
 * Задержки появления для первого и второго прелоадера.
 */
enum TimersDelay {
	/**
	 * Для первого
	 */
	First = 200,
	/**
	 * Для второго
	 */
	Second = 1000
}

/**
 * Компонент прелоадера
 */
@Component({
	selector: 'app-preloader',
	templateUrl: './preloader.component.html',
	styleUrls: ['./preloader.component.scss']
})
export class PreloaderComponent implements OnInit, OnDestroy {

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Задержки появления для первого и второго прелоадера
	 */
	readonly TimersDelay = TimersDelay;

	/**
	 * Подписка на совместную работу таймеров
	 */
	activeTimer$: Observable<TimersDelay>;

	// preloaderService: PreloaderService;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная для уничтожения всех подписок
	 */
	private readonly unsubscribe$$ = new Subject<never>();

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор компонента
	 *
	 * @param {IPreloaderConfig} preloaderConfig Конфигурация прелоадера
	 */
	constructor(
		@Inject(PRELOADER_DATA_TOKEN)
		readonly preloaderConfig: IPreloaderConfig
	) {}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.activeTimer$ = merge(
			timer(TimersDelay.First)
				.pipe(mapTo(TimersDelay.First)),
			timer(TimersDelay.Second)
				.pipe(mapTo(TimersDelay.Second))
		)
			.pipe(
				// tap(v => {
				// 	console.log(`+++++++++ startTimer #${v}`);
				// })
			);
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

}
