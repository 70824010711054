import { Action } from '@app/core/services/network/enums/action.enum';
import { seq } from '@app/core/services/network/mocks/constants';

/**
 * Mock запроса и ответа на получение промо-акций
 */
export const GetPromos = {
	request: {
		action: Action.GetPromos,
			seq
	},
	response: {
		action: Action.GetPromos,
			R: 0,
			promos: [{
				code: 1,
				start: (new Date(Date.now() - 24 * 60 * 60 * 1000)).toISOString(),
				end: (new Date(Date.now() + 96 * 60 * 60 * 1000)).toISOString(),
				results: (new Date()).toISOString()
			}],
			seq
	}
};
