<ng-container *ngIf="button">
	<button
		[id]="button.id"
		[attr.bomb]="button.hasBomb"
		[attr.symbol]="button.hasSymbol"
		[attr.open]="button.isOpen"
		[attr.active-row]="button.isActiveRow"
		[attr.disabled]="button.isOpen || button.hasBomb || button.hasSymbol || undefined"
		data-cy="tower-button-inner"
		>
		<label *ngIf="showLabel"
			   data-cy="tower-button-label"
		>{{ button.label }}</label>
		<span class="mgf-symbol"
			  data-cy="mgf-symbol"
		></span>
		<span class="mgf-bomb"
			  data-cy="mgf-bomb"
		></span>
	</button>
</ng-container>
