import {
	ConnectedPosition,
	FlexibleConnectedPositionStrategy,
	Overlay,
	OverlayConfig,
	OverlayRef
} from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, ElementRef, HostListener, Injector, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';

import { ToolTipComponent } from '@toolTip/components/tool-tip/tool-tip.component';
import { TooltipOverlayRef, TOOLTIP_DATA_TOKEN } from '@toolTip/constants/tool-tip-constants';
import { TooltipPosition } from '@toolTip/enums/tool-tip-position';
import { ITooltipConfig } from '@toolTip/interfaces/itool-tip-config';
import { IToolTipTriggerData } from '@toolTip/interfaces/itool-tip-trigger-data';

/**
 * Директива для отображения сообщения с текстом (tooltip) поверх компонента
 * при наведении курсора мыши на данный компонент.
 * Для отображения tooltip используется компонент {@link ToolTipComponent}.
 */
@Directive({
	selector: '[appToolTip]'
})
export class ToolTipDirective implements  OnDestroy, OnChanges {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Текст, отображаемый внутри tooltip.
	 */
	@Input()
	text: string;

	/**
	 * Позиция относительно компонента, в которой будет отображен tooltip.
	 * По умолчанию будет показано справа.
	 */
	@Input()
	tooltipPosition: TooltipPosition = TooltipPosition.Right;

	/**
	 * Смещение по оси X.
	 */
	@Input()
	offsetX = 0;

	/**
	 * Смещение по оси Y.
	 */
	@Input()
	offsetY = 0;

	/**
	 * Класс для стилизации всплывающей подсказки
	 */
	@Input()
	styleClass: string;

	/**
	 * Показывать tooltip по триггеру {@link trigger}.
	 * Если задано false, то tooltip будет показан по событию мыши.
	 */
	@Input()
	showTooltipByTrigger = false;

	/**
	 * Объект-триггер, по которому будет определено, когда нужно показать tooltip, если
	 * задан параметр {@link showTooltipByTrigger}.
	 */
	@Input()
	trigger: IToolTipTriggerData;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Ссылка на оверлей всплывающей подсказки
	 * @private
	 */
	private tooltipOverlayRef: TooltipOverlayRef;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор директивы.
	 *
	 * @param {ElementRef} el Ссылка на элемент, к которому применяется директива
	 * @param {Injector} injector Инжектор для подключения конфигурации подсказки
	 * @param {Overlay} overlay Оверлей подсказки
	 */
	constructor(
		private readonly el: ElementRef,
		private readonly injector: Injector,
		private readonly overlay: Overlay
	) {}

	/**
	 * Слушатель события наведения мыши на элемент с подсказкой
	 */
	@HostListener('mouseenter', ['$event'])
	onMouseEnter(): void {
		if (!this.text || this.showTooltipByTrigger) {
			return;
		}

		this.showTooltip();
	}

	/**
	 * Слушатель события отведения мыши от элемента с подсказкой
	 */
	@HostListener('mouseleave', ['$event'])
	onMouseLeave(): void {
		if (this.showTooltipByTrigger) {
			return;
		}

		this.hideTooltip();
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------

	/**
	 * Обработчик события изменений каких-либо переменных в директиве
	 * @param changes Изменения
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if (Boolean(changes.trigger)) {
			if (this.trigger?.show) {
				this.showTooltip();
			} else {
				this.hideTooltip();
			}
		}
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		this.hideTooltip();
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Метод для показа подсказки
	 * @private
	 */
	private showTooltip(): void {
		if (!this.tooltipOverlayRef) {
			this.tooltipOverlayRef = this.createTooltipOverlayRef();
		}
	}

	/**
	 * Метод для скрытия подсказки
	 * @private
	 */
	private hideTooltip(): void {
		this.tooltipOverlayRef?.close();
		this.tooltipOverlayRef = undefined;
	}

	/**
	 * Метод для создания всплывающей подсказки
	 * @private
	 */
	private createTooltipOverlayRef(): TooltipOverlayRef {
		const tooltipConfig: ITooltipConfig = {
			text: this.text,
			parentElement: this.el.nativeElement,
			tooltipPosition: this.tooltipPosition,
			styleClass: this.styleClass
		};

		const overlayConfig: OverlayConfig = this.getOverlayConfig();
		const overlayRef: OverlayRef = this.overlay.create(overlayConfig);
		const tooltipRef: TooltipOverlayRef = new TooltipOverlayRef(overlayRef);

		const injector = Injector.create({providers: [{provide: TOOLTIP_DATA_TOKEN, useValue: tooltipConfig}]});
		const containerPortal: ComponentPortal<ToolTipComponent> = new ComponentPortal(ToolTipComponent, undefined, injector);
		overlayRef.attach(containerPortal);

		// overlayRef
		// 	.backdropClick()
		// 	.subscribe(() => dialogRef.close());

		return tooltipRef;
	}

	/**
	 * Метод для получения конфигурации оверлея подсказки
	 * @private
	 */
	private getOverlayConfig(): OverlayConfig {
		const scrollStrategy = this.overlay.scrollStrategies.reposition();
		const positions = this.positionsFactory();
		const positionStrategy: FlexibleConnectedPositionStrategy = this.overlay
			.position()
			.flexibleConnectedTo(this.el)
			.withPositions(positions);

		return new OverlayConfig({
			hasBackdrop: false,
			scrollStrategy,
			positionStrategy
		});
	}

	/**
	 * Метод для возврата одного из вариантов позиций всплывающей подсказки
	 * @private
	 */
	private positionsFactory(): ConnectedPosition[] {
		switch (this.tooltipPosition) {
			case TooltipPosition.Left:
				return [{
					originX: 'start',
					originY: 'center',
					overlayX: 'end',
					overlayY: 'center',
					offsetX: -this.offsetX - 7
				}];

			case TooltipPosition.Right:
				return [{
					originX: 'end',
					originY: 'center',
					overlayX: 'start',
					overlayY: 'center',
					offsetX: this.offsetX + 7
				}];

			case TooltipPosition.Top:
				return [{
					originX: 'center',
					originY: 'top',
					overlayX: 'center',
					overlayY: 'bottom',
					offsetY: -this.offsetY - 7
				}];

			case TooltipPosition.Bottom:
				return [{
					originX: 'center',
					originY: 'bottom',
					overlayX: 'center',
					overlayY: 'top',
					offsetY: 8
				}];

			default: break;
		}

		return [];
	}

}
