import { Injectable } from '@angular/core';
import { Logger } from '@app/core/services/log/log.service';
import { environment } from '@app/environments/environment';

/**
 * Список кастомных событий от фрейма игры, обрабатываемых WEB сайтом.
 */
export enum IFrameEvent {
	/**
	 * Событие входа
	 */
	SignIn          = 'signIn',
	/**
	 * Событие обновления баланса
	 */
	UpdateBalance   = 'updateBalance'
	// BigwinRequest   = 'bigwinRequest'
}

/**
 * Интерфейс данных, передаваемых на сайт из IFrame'а
 */
export interface IFrameData {
	/**
	 * ID билета для больших выигрышей (не используется)
	 */
	id: string;
}

/**
 * Функция отправки сообщения на сайт.
 *
 * @param {IFrameEvent} frameEvent Сообщение
 * @param data Передаваемые данные в сообщении
 */
const sendEvent = (frameEvent: IFrameEvent, data?: IFrameData): void => {
	const event = new CustomEvent(frameEvent, { detail: data });
	Logger.Log.d(TAG, `sendEvent => will send custom event [${frameEvent}] to site with detail:`, event.detail)
		.console();

	window?.parent?.document.dispatchEvent(event);
};

/**
 * Тег для логирования
 */
const TAG = 'IFrameEventsService';

/**
 * Сервис взаимодействия с сайтом посредством {@link CustomEvent}.
 */
@Injectable({
	providedIn: 'root'
})
export class IFrameEventsService {

	/**
	 * Вызвать окно авторизации на сайте.
	 */
	signIn(): void {
		Logger.Log.i(TAG, `signIn`)
			.console();

		sendEvent(IFrameEvent.SignIn);

		// окно ввода токена для теста локально
		if (!environment.production) {
			// window.open(`${location.origin}?lang=ru&token=${prompt('Токен', '')}`, '_self');
		}
	}

	/**
	 * Обновить баланс на сайте.
	 */
	updateBalance(): void {
		Logger.Log.i(TAG, `updateBalance`)
			.console();

		sendEvent(IFrameEvent.UpdateBalance);
	}

	// /**
	//  * Вызвать окно для больших выигрышей.
	//  *
	//  * @param {string} macCode Маккод из ответа.
	//  */
	// bigwinRequest(macCode: string): void {
	// 	Logger.Log.i(TAG, `bigwinRequest`)
	// 		.console();
	//
	// 	sendEvent(IFrameEvent.BigwinRequest, { macCode });
	// }

}
