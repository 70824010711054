import { AfterViewInit, Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { StoreService } from '@app/core/services/store/store.service';
import { ISelectorItem } from '@app/shared/components/base-selector/base-selector.component';

/**
 * Директива отображения и прокрутки элементов списка.
 */
@Directive({
	selector: '[appBaseSelectorScroll]'
})
export class BaseSelectorScrollDirective implements OnInit, OnDestroy, OnChanges, AfterViewInit {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Количество видимых элементов списка с полной шириной.
	 */
	@Input()
	fullWidthCount = 4;

	/**
	 * Признак видимости стрелок прокрутки.
	 * Если false, то вместо стрелок будут видны половинки элементов списка.
	 */
	@Input()
	isVisibleArrows = false;

	/**
	 * Позиция скрола в единицах.
	 */
	@Input()
	currentPosition = 0;

	/**
	 * Список элементов списка.
	 */
	@Input()
	selectors: Array<ISelectorItem>;

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная для уничтожения всех подписок
	 */
	private readonly unsubscribe$$ = new Subject<never>();

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор директивы.
	 *
	 * @param {ElementRef} el Ссылка на элемент, к которому применяется директива
	 * @param storeService Сервис-хранилище приложения
	 */
	constructor(
		private readonly el: ElementRef,
		private readonly storeService: StoreService
	) {}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события изменений каких-либо переменных в директиве
	 * @param changes Изменения
	 */
	ngOnChanges(changes: SimpleChanges): void {
		// console.log('=================ngOnChanges dir', changes, this.currentPosition);
		if (Boolean(changes.currentPosition)
			&& !changes.currentPosition.firstChange
			&& Number.isInteger(changes.currentPosition.currentValue)
		) {
			this.updateElementWidth();
			this.calculateLeftPosition(changes.currentPosition.previousValue);
		}

		if (Boolean(changes.selectors)) {
			this.updateElementWidth();
		}
	}

	/**
	 * Обработчик события инициализации директивы
	 */
	ngOnInit(): void {
		fromEvent(window, 'resize')
			.pipe(
				debounceTime(50),
				tap(this.updateElementWidth.bind(this)),
				tap(this.calculateLeftPosition.bind(this)),
				takeUntil(this.unsubscribe$$)
			)
			.subscribe();
	}

	/**
	 * Обработчик события уничтожения директивы
	 */
	ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}

	/**
	 * Обработчик события, настающего после инициализации директивы
	 */
	ngAfterViewInit(): void {
		this.updateElementWidth();
		this.calculateLeftPosition();
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Расчет ширины элементов списка.
	 * Изменяет параметр {@link ISelectorItem.selectorWidth}.
	 */
	private updateElementWidth(): void {
		if (Array.isArray(this.selectors) && this.selectors.length > 0) {
			const cnt: number = this.isVisibleArrows ? this.fullWidthCount : this.fullWidthCount + 1;
			this.selectors
				.forEach((selector: ISelectorItem) => {
					selector.selectorWidth = `calc(100% / ${cnt} - 4px)`;
				});
		}
	}

	/**
	 * Рассчитать левое смещение для прокрутки элементов к выбранной позиции.
	 * Позиция берется из параметра {@link currentPosition}.
	 *
	 * @param {number} prevPosition Предыдущее значение позиции.
	 */
	private calculateLeftPosition(prevPosition?: number): void {
		const ulEl: HTMLElement = this.el.nativeElement.children[0] as HTMLElement;
		const liEl: HTMLElement = ulEl.children[0] as HTMLElement;

		if (!ulEl || !liEl) {
			return;
		}

		const mr = Number.parseInt(window.getComputedStyle(liEl).marginRight, 10);
		const liElWidth: number = liEl.getBoundingClientRect().width + (isNaN(mr) ? 0 : mr);
		const liCount: number = ulEl.children.length;
		const position: number = this.currentPosition;

		// для варианта со стрелками позиция определяется простым смещением (ширина ячейки * позиция)
		// для варианта без стрелок в случае, когда позиция расположена внутри, необходимо показать половину ячейки
		if (this.isVisibleArrows) {
			ulEl.style.left = liCount <= this.fullWidthCount
				? '0px'
				: `${-position * liElWidth}px`;
		} else {
			// рассчитать смещение позиции, если это не первая и не последняя позиция
			let halfOffset = 0;
			if (position > 0 && position < liCount - this.fullWidthCount - 1) {
				const dir = (prevPosition === undefined || position - prevPosition > 0 ? 1 : -1);
				halfOffset = dir * 0.5;
			}

			const lft = `calc(${-(position + halfOffset) * 100 / (this.fullWidthCount + 1)}% - ${position}px)`;

			ulEl.style.left = (lft === 'calc(-160% - 8px)') && (window.innerWidth <= 480)
			&& (this.storeService.gameCode === 405)  ? 'calc(-160% + 28px)' : `calc(${-(position + halfOffset) * 100 / (this.fullWidthCount + 1)}% - ${position}px)`;
		}
	}

}
