import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';

import { ActionPanelComponent } from '@actionPanel/components/action-panel/action-panel.component';

/**
 * Модуль панели акций.
 */
@NgModule({
	imports: [
		CommonModule,
		SharedModule
	],
	exports: [
		ActionPanelComponent
	],
	declarations: [
		ActionPanelComponent
	]
})
export class ActionPanelModule {}
