/**
 * Список каналов событий:
 * - {@link WinFeedAll} - Канал с данными о всех ставках и выигрышах игроков, используется для отрисовки в таблице выигрышей.
 * - {@link WinFeedHot} - Канал с данными о больших выигрышах или высоких коэффициентах, используется для отрисовки в таблице выигрышей.
 * - {@link WinFeedMy} - Канал с данными о собственных ставках, используется для отрисовки в таблице выигрышей.
 * - {@link WinFeedTournament} - фейковый канал, с турнирами.
 */
export enum Channel {
	WinFeedAll      = 'winFeedAll',
	WinFeedHot      = 'winFeedHot',
	WinFeedMy       = 'WinFeedMy',
}

/**
 * Тип канала событий:
 * - {@link BroadCast} - Широковещательный, все подписавшиеся клиенты получают копию сообщения (терминируется на уровне WebSocket).
 * - {@link MultiCast} - Много подписчиков - один эмиттер, все подписавшиеся клиенты получают копию сообщения.
 * - {@link UniCast} - Один подписчик - один эмиттер.
 */
export enum ChannelType {
	BroadCast       = 1,
	MultiCast       = 2,
	UniCast         = 3
}

/**
 * Список типов операций с каналом событий:
 * - {@link Subscribe} - Подписаться на уведомления в канале событий.
 * - {@link Unsubscribe} Отписаться от уведомлений канале событий.
 */
export enum ChannelOperation {
	Subscribe 		= 'sub',
	Unsubscribe     = 'unsub'
}
