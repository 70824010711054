import { Component } from '@angular/core';
import { BuyTicketMessageService } from '@app/core/services/game/buy-ticket-message.service';
import { AbstractRowFieldButtonDirective } from '@app/shared/components/game-row-field/abstract-row-field-button.directive';

/**
 * Элемент кнопки в игровой строке игры "Башня"
 */
@Component({
	selector: 'app-tower-button',
	templateUrl: './tower-button.component.html',
	styleUrls: ['./tower-button.component.scss']
})
export class TowerButtonComponent extends AbstractRowFieldButtonDirective {
	/**
	 * Конструктор компонента
	 * @param buyTicketMessageService Сервис показа сообщения о покупке билета
	 */
	constructor(readonly buyTicketMessageService: BuyTicketMessageService) {
		super();
	}
}
