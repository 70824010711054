import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

/**
 * Список состояний кнопки "ЗАБРАТЬ ВЫИГРЫШ".
 */
export enum TakeWinState {
	TakeWin         = 'common.take-win',
	WinningsTaken   = 'common.winnings-taken'
}

/**
 * Компонент, отображающий кнопку "ЗАБРАТЬ ВЫИГРЫШ" для прекращения игры.
 */
@Component({
	selector: 'app-take-win',
	templateUrl: './take-win.component.html',
	styleUrls: ['./take-win.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TakeWinComponent  {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Текущий размер выигрыша, отображаемый на экране.
	 */
	@Input()
	currentWin = 0;

	/**
	 * Текущий статус кнопки, от которого зависит надпись на самой кнопке.
	 */
	@Input()
	buttonState: TakeWinState = TakeWinState.TakeWin;

	/**
	 * Признак отключенной кнопки "ЗАБРАТЬ ВЫИГРЫШ".
	 */
	@Input()
	disabled = false;

	/**
	 * Имя класса, соответствующее разрешению.
	 */
	@Input()
	screenClassName: string;

	// -----------------------------
	//  Output properties
	// -----------------------------

	/**
	 * Событие клика по кнопке "ЗАБРАТЬ ВЫИГРЫШ".
	 */
	@Output()
	readonly takeWinClicked = new EventEmitter<never>();

}
