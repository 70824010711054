import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MiniGamesCodes } from '@app/core/enums/mini-games-list.enum';

import { IGameListItem } from '@app/core/interfaces/igame-list-item';
import { Logger } from '@app/core/services/log/log.service';
import { convertGameCodeToPath, URL_GAME_LIST } from '@app/utils/route-utils';

/**
 * Тег для логирования
 */
const TAG = 'NavigationService';

/**
 * Сервис навигации по внутренним маршрутам приложения
 */
@Injectable({
	providedIn: 'root'
})
export class NavigationService {

	/**
	 * Конструктор сервиса
	 *
	 * @param {Router} router Объект роутера для навигации
	 */
	constructor(
		private readonly router: Router
	) {}

	/**
	 * Выполнить навигацию к выбранной пользователем игре из списка.
	 *
	 * @param {IGameListItem} game
	 */
	navigateToSelectedGame(game: IGameListItem): void {
		Logger.Log.i(TAG, `navigateToSelectedGame => game selected`, game)
			.console();

		if (!!game && !!game.path) {
			this.router.navigate([game.path])
				.catch(error => {
					Logger.Log.e(TAG, `navigateToSelectedGame => can't navigate to [${game.path}]:`, error)
						.console();
				});
		}
	}

	/**
	 * Выполнить навигацию к игре по коду игры.
	 *
	 * @param {MiniGamesCodes} gameCode Код игры.
	 */
	navigateToSelectedGameByCode(gameCode: MiniGamesCodes): void {
		const gamePath: string = convertGameCodeToPath(gameCode);
		this.router.navigate([gamePath])
			.catch(error => {
				Logger.Log.e(TAG, `navigateToSelectedGame => can't navigate to [${gamePath}]:`, error)
					.console();
			});
	}

	/**
	 * Функция навигации к списку игр.
	 */
	navigateToLobby(): void {
		Logger.Log.i(TAG, `navigateToLobby`)
			.console();
		this.router.navigate([URL_GAME_LIST])
			.catch(error => {
				Logger.Log.e(TAG, `navigateToLobby => can't navigate to lobby:`, error)
					.console();
			});
	}

}
