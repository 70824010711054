import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';

import { InfoPanelModule } from '@infoPanel/info-panel.module';
import { ScrollerModule } from '@scroller/scroller.module';
import { WinTableButtonBarComponent } from '@winTable/components/win-table-button-bar/win-table-button-bar.component';
import { WinTableDesktopWrapperComponent } from '@winTable/components/win-table-desktop-wrapper/win-table-desktop-wrapper.component';
import { WinTableLegendComponent } from '@winTable/components/win-table-legend/win-table-legend.component';
import { WinTableMobileWrapperComponent } from '@winTable/components/win-table-mobile-wrapper/win-table-mobile-wrapper.component';
import { WinTablesComponent } from '@winTable/components/win-tables/win-tables.component';
import { WinTableComponent } from './components/win-table/win-table.component';

/**
 * Модуль таблицы выигрышей.
 */
@NgModule({
	imports: [
		CommonModule,
		SharedModule,
		InfoPanelModule,
		ScrollerModule
	],
	declarations: [
		WinTablesComponent,
		WinTableDesktopWrapperComponent,
		WinTableMobileWrapperComponent,
		WinTableButtonBarComponent,
		WinTableLegendComponent,
		WinTableComponent
	],
	exports: [
		WinTablesComponent,
		WinTableDesktopWrapperComponent,
		WinTableMobileWrapperComponent,
		WinTableComponent
	],
	providers: []
})
export class WinTableModule {

	static forRoot(): ModuleWithProviders<WinTableModule> {
		return {
			ngModule: WinTableModule,
			providers: []
		};
	}

	static forChild(): ModuleWithProviders<WinTableModule> {
		return {
			ngModule: WinTableModule,
			providers: []
		};
	}

}
