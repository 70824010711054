<div
	#tooltipWrapper
	class="tooltip-wrapper"
	[ngClass]="styleClass"
	[attr.tooltip-position]="tooltipPosition"
	>
	<ng-container *ngIf="styleClass === 'active'; else grayArrow">
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10px" height="10px" viewBox="0 0 10 10">
			<line fill="none" stroke="#ffffff" stroke-width="2" x1="7.8" y1="0.8" x2="7.8" y2="9.1"/>
			<path fill="none" stroke="#01b400" stroke-linecap="square" d="M7.8,9.3V9.1L7.6,8.9L2.2,5l5.4-3.9l0.2-0.1V0.7"/>
		</svg>
	</ng-container>
	<ng-template #grayArrow>
		<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="10px" height="10px" viewBox="0 0 10 10">
			<line fill="none" stroke="#ffffff" stroke-width="2" x1="7.8" y1="0.8" x2="7.8" y2="9.1"/>
			<path fill="none" stroke="#a29fa6" stroke-linecap="square" d="M7.8,9.3V9.1L7.6,8.9L2.2,5l5.4-3.9l0.2-0.1V0.7"/>
		</svg>
	</ng-template>
	
	<label [innerHTML]="text"></label>
</div>
