import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

import { Logger } from '@app/core/services/log/log.service';
import { CommunicationService } from '@app/core/services/network/communication.service';
import { StoreService } from '@app/core/services/store/store.service';
import { environment } from '@app/environments/environment';

/**
 * Тег для логирования
 */
const TAG = 'CheckServiceConnectionGuard';

/**
 * Гард, проверяющий соединение с сервером
 */
@Injectable({
	providedIn: 'root'
})
export class CheckServiceConnectionGuard implements CanActivate {

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор гарда
	 *
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 * @param {CommunicationService} communicationService Сервис взаимодействия с бекендом
	 */
	constructor(
		private readonly storeService: StoreService,
		private readonly communicationService: CommunicationService
	) {}

	/**
	 * Метод для определения, может ли быть активирован маршрут
	 * @param next Маршрут, который проверяется
	 * @param state Текущий маршрут
	 */
	canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
		Logger.Log.i(TAG, `canActivate => will check server connection state...`)
			.console();

		if (!this.storeService.hasServiceConnection$$.value) {
			this.communicationService.connect();
		}

		return this.storeService.hasServiceConnection$$
			.pipe(
				filter(f => !!f || environment.mocks),
				tap(() => {
					Logger.Log.i(TAG, `canActivate => server is CONNECTED`)
						.console();
				}),
				switchMap(() => this.getServerVersion())
			);
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Метод для проверки соединения с помощью запроса версии серверной части
	 * @private
	 */
	private getServerVersion(): Observable<boolean> {
		return this.communicationService.version()
			.pipe(map(version => {
				Logger.Log.i(TAG, `getServerVersion => version:`, version)
					.console();

				return true;
			}));
	}

}
