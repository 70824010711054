import {
	ChangeDetectorRef,
	Component,
	HostListener,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { IGameListItem } from '@app/core/interfaces/igame-list-item';
import { IGetPromosResponse, IPromos } from '@app/core/services/network/interfaces/responses/iget-promos-response';
import { StoreService } from '@app/core/services/store/store.service';
import { BuyTicketMessageService } from '@app/core/services/game/buy-ticket-message.service';
import { GameState } from '@app/core/services/state/enums/game-state.enum';
import { environment } from '@app/environments/environment';
import { GameListOrientation } from '@gameListShort/components/abstract-game-list.directive';
import { MenuHamburgerService } from '@hamburger/services/menu-hamburger.service';

/**
 * Компонент верхней информационной панели
 */
@Component({
	selector: 'app-info-panel',
	templateUrl: './info-panel.component.html',
	styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent implements OnInit, OnDestroy {

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Наблюдаемая переменная для уничтожения всех подписок
	 */
	private readonly unsubscribe$$ = new Subject<never>();

	/**
	 * В мобильном ли мы разрешении?
	 */
	private isMobile = false;

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Перечень ориентаций для списка игр
	 */
	readonly GameListOrientation: typeof GameListOrientation = GameListOrientation;

	/**
	 * Список состояний приложения
	 */
	readonly GameState = GameState;

	/**
	 * Видимая ли панель акций
	 */
	isVisibleActionPanel$: Observable<boolean>;

	/**
	 * Наблюдаемая переменная с флагом мобильного разрешения?
	 */
	isMobile$: Observable<boolean>;

	/**
	 * Строковое значение демо-баланса
	 */
	demoBalance = '0';

	/**
	 * Флаг демо-режима
	 */
	demoMode = false;

	/**
	 * Показана ли всплывающая подсказка?
	 */
	hintShown = false;

	/**
	 * Флаг недостаточности баланса
	 */
	@Input() notEnoughBalance = false;

	// -----------------------------
	//  Public functions
	// -----------------------------
	/**
	 * Слушатель клика по документу
	 * @param event Передаваемое событие
	 */
	@HostListener('document:click', ['$event'])
	onDocumentClick(event: MouseEvent): void {
		const clickedElem = event.target as HTMLElement;
		if (!clickedElem.closest('.stars-action')) {
			this.hintShown = false;
		}
	}

	/**
	 * Конструктор компонента
	 *
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 * @param {MenuHamburgerService} menuHamburgerService Сервис гамбургер-меню
	 * @param cdr Детектор обнаружения изменений
	 * @param buyTicketMessageService Сервис показа сообщения о покупке билета
	 */
	constructor(
		readonly storeService: StoreService,
		private readonly menuHamburgerService: MenuHamburgerService,
		private readonly cdr: ChangeDetectorRef,
		private readonly buyTicketMessageService: BuyTicketMessageService
	) {}

	/**
	 * Обработчик клика по кнопке "Меню".
	 */
	onClickMenuHandler(): void {
		this.menuHamburgerService.openHamburgerMenu();
	}

	/**
	 * Обработчик клика по кнопке "Лайв".
	 */
	onClickLiveHandler(): void {
		if (this.isMobile) {
			this.storeService.isWinTableVisible$$.next(!this.storeService.isWinTableVisible$$.value);
		} else {
			this.storeService.scrollToWinTable();
		}
	}

	/**
	 * Обработчик выборы игры из меню
	 * @param game Элемент меню (игра)
	 */
	onSelectedGameHandler(game: IGameListItem): void {
		this.menuHamburgerService.closeHamburgerMenu();
	}

	/**
	 * Обработчик клика по кнопке "Пополнить демо-баланс"
	 */
	onFillBalanceClick(): void {
		const newBalance = this.storeService.demoBalance$$.value + environment.demoBalance;
		this.storeService.demoBalance$$.next(newBalance);
		localStorage.setItem('FG_DATA_DEMO_BALANCE', newBalance.toString());
	}

	/**
	 * Обработчик показа всплывающей подсказки
	 */
	onShowHintClick(): void {
		this.hintShown = true;
	}

	/**
	 * Обработчик скрытия всплывающей подсказки
	 */
	onCloseHintClick(): void {
		this.hintShown = !this.hintShown;
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------
	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.isMobile$ = this.storeService.isMobileScreen$;

		this.isVisibleActionPanel$ = this.storeService.getPromos$$
			.pipe(
				map((promosResponse: IGetPromosResponse) => {
					if (!!promosResponse && Array.isArray(promosResponse.promos) && promosResponse.promos.length > 0) {
						return promosResponse.promos
							.reduce((p: boolean, c: IPromos) => p || Date.now() < new Date(c.results).getTime(), false);
					}

					return false;
				})
			);

		this.storeService.isMobileScreen$
			.pipe(takeUntil(this.unsubscribe$$))
			.subscribe(value => {
				this.isMobile = value;
			});

		this.storeService.demoBalance$$.subscribe(balance => {
			this.demoBalance = (balance / 100).toFixed(2);
			this.cdr.detectChanges();
		});

		this.storeService.demoMode$$.subscribe(dm => {
			this.demoMode = !this.storeService.sid && dm[this.storeService.gameCode];
			this.cdr.detectChanges();
		});
	}

	/**
	 * Обработчик события уничтожения компонента
	 */
	ngOnDestroy(): void {
		this.unsubscribe$$.next();
		this.unsubscribe$$.complete();
	}
}
