import { BehaviorSubject, Observable, of, Subject, timer } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';

import { Logger } from '@app/core/services/log/log.service';
import { Channel } from '@app/core/services/network/enums/channel.enum';
import { IAbstractEvent } from '@app/core/services/network/interfaces/events/iabstract-event';
import { IAbstractRequest } from '@app/core/services/network/interfaces/requests/iabstract-request';
import { IAbstractResponse } from '@app/core/services/network/interfaces/responses/iabstract-response';
import { StoreService } from '@app/core/services/store/store.service';

import { ExternalCommunicationService } from '@app/core/services/external/external-communication.service';
import { NavigationService } from '@app/core/services/game/navigation.service';
import { Action } from '@app/core/services/network/enums/action.enum';
import { IPingTimeStamp, WsMessage } from '@app/core/services/network/interfaces/abstract-ws-message';
import { GameState } from '@app/core/services/state/enums/game-state.enum';
import { GameStateService } from '@app/core/services/state/game-state.service';

import { Mocks } from '@app/core/services/network/mocks/mocks';
import { environment } from '@app/environments/environment';
import { GameDialogsService } from '@dialogs/services/game-dialogs.service';
import { PreloaderService } from '@preloader/services/preloader.service';

/**
 * Функция, определяющая, пришел ли ответ на пинг
 * @param message Сообщение из ответа сервера
 */
const isPong = (message: WsMessage<IAbstractResponse | IAbstractEvent | IPingTimeStamp>): message is WsMessage<IPingTimeStamp> =>
	message.event === 'pong';

/**
 * Функция, определяющая, пришло ли сообщение с типом "Событие"
 * @param message Сообщение из ответа сервера
 */
const isEvent = (message: IAbstractResponse | IAbstractEvent | IPingTimeStamp): message is IAbstractEvent =>
	(message as IAbstractEvent).channel !== undefined;

/**
 * Функция, определяющая, пришло ли сообщение с типом "Ответ на действие пользователя"
 * @param message Сообщение из ответа сервера
 */
const isResponse = (message: IAbstractResponse | IAbstractEvent | IPingTimeStamp): message is IAbstractResponse =>
	(message as IAbstractResponse).action !== undefined;

/**
 * Генератор ID запроса, нужен для обратной маршрутизации ответов.
 */
const RequestSequenceId = () => {
	let cycleCounter = 0;

	return () => {
		cycleCounter++;
		if (cycleCounter > 99) {
			cycleCounter = (cycleCounter - 99);
		}

		return Date.now() * 100 + cycleCounter;
	};
};

/**
 * ID запроса, нужен для обратной маршрутизации ответов
 */
export const getRequestSequenceId = RequestSequenceId();

/**
 * Тег для логирования
 */
const TAG = 'AbstractCommunication';

/**
 * Абстрактный сервис коммуникаций.
 * Содержит базовый функционал по подключению и обслуживанию соединения через WebSocket.
 */
export abstract class AbstractCommunication<
		R extends IAbstractRequest,
		A extends IAbstractResponse,
		E extends IAbstractEvent
	> {

	// -----------------------------
	//  Public properties
	// -----------------------------

	/**
	 * Возвращает ссылку на subject потока данных.
	 *
	 * @returns {Observable<A>}
	 */
	get streamData$(): Observable<A> {
		return this._streamData$$.asObservable();
	}

	/**
	 * Возвращает текущие данные из ответа сервера
	 */
	get currentStreamData(): A { return this._currentStreamData; }

	/**
	 * Возвращает предыдущие данные из ответа сервера
	 */
	get previousStreamData(): A { return this._previousStreamData; }

	// -----------------------------
	//  Protected properties
	// -----------------------------

	/**
	 * URL для соединения с сервером
	 * @protected
	 */
	protected abstract connectUrl: string;

	/**
	 * Сервис-хранилище приложения
	 * @protected
	 */
	protected abstract readonly storeService: StoreService;
	
	/**
	 * Сервис для работы с состоянием приложения
	 * @protected
	 */
	protected abstract readonly gameStateService: GameStateService;

	/**
	 * Сервис диалоговых окон
	 * @protected
	 */
	protected abstract readonly gameDialogsService: GameDialogsService;

	/**
	 * Сервис взаимодействия с внешним окном или WebView
	 * @protected
	 */
	protected abstract readonly externalCommunicationService: ExternalCommunicationService;

	/**
	 * Сервис для показа анимации во время загрузки приложения
	 * @protected
	 */
	protected abstract readonly preloaderService: PreloaderService;

	/**
	 * Сервис навигации по внутренним маршрутам приложения
	 * @protected
	 */
	protected abstract readonly navigationService: NavigationService;

	/**
	 * Ссылка на веб-сокет
	 * @protected
	 */
	protected _socket: WebSocket;

	/**
	 * Наблюдаемая переменная для получения ответов на пинг-запросы
	 * @protected
	 */
	protected readonly _pong$$ = new Subject<number>();
	
	/**
	 * Наблюдаемая переменная для получения рассылки с игровыми событиями
	 * @protected
	 */
	protected readonly _event$$ = new Subject<E>();

	/**
	 * Наблюдаемая переменная для получения ответов на запросы пользователя
	 * @protected
	 */
	protected readonly _response$$ = new Subject<A>();

	/**
	 * Наблюдаемая переменная для получения ответов из потока данных в игре Креш
	 * @protected
	 */
	protected readonly _streamData$$ = new BehaviorSubject<A>(undefined);

	// -----------------------------
	//  Private properties
	// -----------------------------
	/**
	 * Текущие данные из потока данных
	 * @private
	 */
	private _currentStreamData: A;

	/**
	 * Предыдущие данные из потока данных
	 * @private
	 */
	private _previousStreamData: A;

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Выполняет подключение к сервису.
	 */
	connect(): void {
		Logger.Log.i(TAG, `connect => server connection to [${this.connectUrl}]...`)
			.console();

		if (!environment.mocks) {
			this.preloaderService.showPreloader({ message: 'preloader.connecting' });
		}

		this._socket = new WebSocket(this.connectUrl);
		this._socket.onopen = this.onOpenWS.bind(this);
		this._socket.onclose = this.onCloseWS.bind(this);
		this._socket.onmessage = this.onMessageWS.bind(this);
		this._socket.onerror = (error: Event) => {
			Logger.Log.e(TAG, `connect => onerror`, error)
				.console();
		};
	}

	/**
	 * Выполняет подключение к сервису.
	 */
	disconnect(): void {
		Logger.Log.i(TAG, `disconnect`)
			.console();

		// TODO доделать логику
	}

	/**
	 * Отправка запроса без сайд-эффекта.
	 *
	 * @param request Запрос
	 */
	sendMessageInBackground(request: R): Observable<A> {
		request.seq = getRequestSequenceId();

		Logger.Log.d(TAG, `sendMessageInBackground =>`, request)
			.console();

		const serializedRequest = this.serializer({ event: 'request', data: request });
		if (environment.mocks) {
			const mockResponse = Mocks.getInstance().getData(request);
			if (!!mockResponse) {
				mockResponse.seq = request.seq;
				const tmr = timer(100)
					.subscribe(() => {
						const rawResponse = JSON.stringify({ event: 'response', data: mockResponse });
						this.messageParser(JSON.parse(rawResponse));
						tmr.unsubscribe();
					});
			} else {
				this._socket?.send(serializedRequest);
			}
		} else {
			this._socket?.send(serializedRequest);
		}

		return this._response$$
			.asObservable()
			.pipe(
				filter(f => !!f && f.action === request.action && f.seq === request.seq),
				take(1)
			);
	}

	/**
	 * Отправка запроса
	 * @param request Запрос
	 * @param preloader Показывать ли прелоадер
	 */
	sendMessage(request: R, preloader = true): Observable<A> {
		request.seq = getRequestSequenceId();

		Logger.Log.d(TAG, `sendMessage =>`, request)
			.console();

		this.gameStateService.setDataRequestState();
		if (preloader) {
			this.preloaderService.showPreloader({ message: 'preloader.load-data' });
		}

		const serializedRequest = this.serializer({ event: 'request', data: request });
		if (environment.mocks) {
			const mockResponse = Mocks.getInstance().getData(request);
			if (!!mockResponse) {
				mockResponse.seq = request.seq;
				const tmr = timer(100)
					.subscribe(() => {
						const rawResponse = JSON.stringify({ event: 'response', data: mockResponse });
						this.messageParser(JSON.parse(rawResponse));
						tmr.unsubscribe();
					});
			} else {
				this._socket.send(serializedRequest);
			}
		} else {
			this._socket.send(serializedRequest);
		}

		return this._response$$
			.asObservable()
			.pipe(
				// delay(2000), // test long request
				filter(f =>  !!f && f.action === request.action && f.seq === request.seq),
				tap(() => {
					this.gameStateService.recoverStateAfterRequest();
					this.preloaderService.hidePreloader();
				}),
				take(1)
			);
	}

	/***
	 * Прослушивание событий с сервера.
	 *
	 * @param channel Канал событий.
	 */
	listenEvents(channel: Channel): Observable<E> {
		Logger.Log.d(TAG, `listenEvents =>`, channel)
			.console();

		return this._event$$
			.asObservable()
			.pipe(
				filter(f => !!f && f.channel === channel)
			);
	}

	/**
	 * Отправка 'ping' на сервер (для замера латентности).
	 */
	sendPing(): Observable<IPingTimeStamp> {
		if (environment.mocks || this._socket.readyState !== WebSocket.OPEN) {
			return of(undefined);
		}

		const data = Date.now();
		this._socket?.send(this.serializer({ event: 'ping', data }));

		return this._pong$$
			.asObservable()
			.pipe(
				take(1)
			);
	}

	/**
	 * Отправить в subject данные в ручном режиме.
	 *
	 * @param {A} data Данные
	 * @param {boolean} keepNotEmptyData Сохранять непустые данные, переданные в ручном режиме. По умолчанию сохраняются.
	 */
	pushDataToStream(data: A, keepNotEmptyData: boolean = true): void {
		if (!!data && keepNotEmptyData) {
			this._previousStreamData = this._streamData$$.value;
			this._currentStreamData = data;
			if (!this._currentStreamData.timestamp) {
				this._currentStreamData.timestamp = Date.now();
			}
		}

		this._streamData$$.next(data);
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Событие при открытии сокета
	 * @private
	 */
	private onOpenWS(): void {
		Logger.Log.i(TAG, `onOpenWS => server is CONNECTED`)
			.console();

		this.preloaderService.hidePreloader();
		this.storeService.hasServiceConnection$$.next(true);
	}

	/**
	 * Событие при закрытии сокета
	 * @param event Передаваемый объект события
	 * @private
	 */
	private onCloseWS(event: CloseEvent): void {
		if (event.wasClean) {
			Logger.Log.d(TAG, `onCloseWS => clean disconnect`)
				.console();
		} else {
			Logger.Log.d(TAG, `onCloseWS => unclean disconnect`)
				.console();
		}

		Logger.Log.d(TAG, `onCloseWS => server is DISCONNECTED, code: ${event.code}, reason: ${event.reason}`)
			.console();

		this.storeService.hasServiceConnection$$.next(false);

		// проверить наличие данных в стриме ???
		if (!!this._currentStreamData) {
			Logger.Log.d(TAG, `onCloseWS => there is ACTIVE STREAM, will change game state:`, this._currentStreamData)
				.console();
			this.storeService.gameState$$.next(GameState.BuyTicket); // TODO ????????????????????????????????
		}

		this.repeatConnection();
	}

	/**
	 * Событие при получении сообщения от сокета
	 * @param event Передаваемый объект события с сообщением
	 * @private
	 */
	private onMessageWS(event: MessageEvent): void {
		this.messageParser(this.deserializer(event));
	}

	/**
	 * Процедура переподключения к серверу.
	 */
	private repeatConnection(): void {
		Logger.Log.i(TAG, `repeatConnection...`)
			.console();

		const timeout = 10000;
		timer(timeout)
			.subscribe(this.connect.bind(this));
	}

	/**
	 * Парсер сообщений, полученных от сервера.
	 *
	 * @param {IAbstractResponse} message Модель сообщения.
	 */
	private readonly messageParser = (message: WsMessage<A | E | IPingTimeStamp>): void => {
		if (message.event !== 'pong') {
			Logger.Log.i(TAG, `messageParser =>`, message)
				.console();
		}

		// timer(1800).subscribe(() => this._response$$.next(message)); // emulate delay
		if (isPong(message)) {
			this._pong$$.next(message.data);
		}

		if (isEvent(message.data)) {
			this._event$$.next(message.data);
		}

		if (isResponse(message.data)) {
			if (message.data.action === Action.Stream) {
				// сохранить текущие и предыдущие данные, переданные непосредственно самим сервером
				this._previousStreamData = this._streamData$$.value;
				this._currentStreamData = message.data;
				if (!this._currentStreamData.timestamp) {
					this._currentStreamData.timestamp = Date.now();
				}
				// отправить в subject данные из потока
				this._streamData$$.next(this._currentStreamData);
			} else {
				this._response$$.next(message.data);
			}
		}
	}

	/**
	 * Функция десериализации данных.
	 *
	 * @param {MessageEvent} event Передаваемое сообщение
	 * @returns {IAbstractResponse}
	 */
	private readonly deserializer = (event: MessageEvent): WsMessage<A | E | IPingTimeStamp> => {
		let response;
		try {
			response = JSON.parse(event.data);
		} catch (e) {
			return undefined;
		}

		return response;
	}

	/**
	 * Функция сериализации данных.
	 *
	 * @param {IAbstractRequest} data Данные
	 * @returns {string}
	 */
	private readonly serializer = (data: WsMessage<R | IPingTimeStamp>): string => JSON.stringify(data);

}
