import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges
} from '@angular/core';

import { ArrowButtonOrientation } from '@app/shared/components/arrow-button/arrow-button.component';
import { PlusMinusIconType } from '@app/shared/components/plus-minus-button/plus-minus-button.component';
import { timer } from 'rxjs';

/**
 * Модель элемента из списка, выбираемого пользователем.
 */
export interface ISelectorItem {

	/**
	 * Идентификатор элемента.
	 */
	id: string;

	/**
	 * Метка элемента списка, отображаемая на экране.
	 */
	label: string;

	/**
	 * Значение элемента.
	 */
	value: string | number | [number, number];

	/**
	 * Признак выбора.
	 */
	selected?: boolean;

	/**
	 * Расчетная минимальная ширина элемента.
	 */
	selectorWidth?: string;

}

/**
 * Базовый компонент выбора элементов из заданного списка.
 */
@Component({
	selector: 'app-base-selector',
	templateUrl: './base-selector.component.html',
	styleUrls: ['./base-selector.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BaseSelectorComponent implements OnInit, OnChanges {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Заголовок списка.
	 */
	@Input()
	title: string;

	/**
	 * Возможные значения списка, доступные к выбору.
	 */
	@Input()
	selectors: Array<ISelectorItem>;

	/**
	 * Признак, указывающий на необходимость отображения поля для ручного ввода.
	 */
	@Input()
	hasManualInput = false;

	/**
	 * Признак видимости стрелок прокрутки.
	 * Если false, то вместо стрелок будут видны половинки элементов списка.
	 */
	@Input()
	isVisibleArrows = false;

	/**
	 * Признак видимости кнопок (-)/(+) (инкремент или декремент значения) в строке ручного ввода.
	 */
	@Input()
	isVisibleInputButtons = false;

	/**
	 * Значение, на которое будет увеличена или уменьшена сумма ставки, когда пользователь использует кнопки (-)/(+).
	 */
	@Input()
	isInputButtonsDiff = 1;

	/**
	 * Признак запрета ввода.
	 */
	@Input()
	disabled: boolean;

	/**
	 * Текущее выбранное значение из списка.
	 * На основе данного значения будет выполнена подкраска и позиционирование.
	 */
	@Input()
	currentValue: number;

	/**
	 * Максимальное значение, которое можно ввести.
	 */
	@Input()
	maxValue = NaN;

	/**
	 * Минимальное значение, которое можно ввести.
	 */
	@Input()
	minValue = 1;

	/**
	 * Количество видимых элементов списка с полной шириной.
	 */
	@Input()
	fullWidthCount = 4;

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Событие выбора элемента из списка.
	 */
	@Output()
	readonly selected = new EventEmitter<ISelectorItem>();

	/**
	 * Событие ввода значения вручную
	 */
	@Output()
	readonly manualValue = new EventEmitter<number>();

	// -----------------------------
	//  Public properties
	// -----------------------------
	/**
	 * Список ориентаций иконки со стрелкой.
	 */
	readonly ArrowButtonOrientation: typeof ArrowButtonOrientation = ArrowButtonOrientation;

	/**
	 * Список возможных типов кнопки
	 */
	readonly PlusMinusIconType: typeof PlusMinusIconType = PlusMinusIconType;

	/**
	 * Актуальная позиция скрола от 0 до N, где N = кол-во элементов в списке - 1.
	 */
	currentPosition = 0;

	/**
	 * Признак недоступности кнопки "-"
	 */
	minusDisabled = false;

	/**
	 * Признак недоступности кнопки "+"
	 */
	plusDisabled = false;

	/**
	 * Показано ли сообщение "Сумма ставки не может быть 0"?
	 */
	warnHint1 = false;

	/**
	 * Показано ли сообщение о превышении максимальной суммы ставки?
	 */
	warnHint2 = false;

	// -----------------------------
	//  Public functions
	// -----------------------------
	/**
	 * Конструктор компонента
	 * @param cdr Детектор обнаружения изменений
	 */
	constructor(private readonly cdr: ChangeDetectorRef) {
	}

	/**
	 * Вспомогательная функция для ускорения вывода информации в шаблон
	 * @param index Индекс элемента
	 * @param item Элемент
	 */
	readonly trackByItemFn = (index, item: ISelectorItem) => `${item.id}`;

	/**
	 * Обработчик клика по контейнеру элементов.
	 * Генерирует событие {@link selected}, включающее в себя выбранный элемент.
	 */
	onItemClickHandler(event: MouseEvent): void {
		if (!this.disabled && event.target instanceof HTMLLIElement) {
			const id: string = event.target.id;
			const el = this.selectors.find((p: ISelectorItem) => p.id === id);
			if (!!el) {
				this.selectors.forEach((p: ISelectorItem) => p.selected = !(p !== el));
				this.minusDisabled = el.value === this.minValue;
				this.plusDisabled = el.value === this.maxValue;
				this.selected.emit(el);
			}
		}
	}

	/**
	 * Обработчик события ввода в текстовое поле
	 * @param {Event} event Передаваемое событие
	 */
	onInputHandler(event: Event): void {
		if (event.target instanceof HTMLInputElement) {
			const newValueStr = event.target.value.replace(/\D+/g, '');
			const newValue = parseInt(newValueStr, 10);
			if (isNaN(newValue)) {
				event.target.value = '';
			} else {
				const newValue2 = newValue < this.minValue ? this.minValue : newValue > this.maxValue ? this.maxValue : newValue;

				if (newValue < 1) {
					this.warnHint1 = true;
					const tmr = timer(2000)
						.subscribe(() => {
							this.warnHint1 = false;
							this.cdr.detectChanges();
							tmr.unsubscribe();
						});
				}
				if (newValue > this.maxValue) {
					this.warnHint2 = true;
					const tmr = timer(2000)
						.subscribe(() => {
							this.warnHint2 = false;
							this.cdr.detectChanges();
							tmr.unsubscribe();
						});
				}
				event.target.value = newValue2.toString();
				this.minusDisabled = newValue2 === this.minValue;
				this.plusDisabled = newValue2 === this.maxValue;
				this.manualValue.emit(newValue2);
			}
		}
	}

	/**
	 * Обработчик события нажатия клавиши в текстовом поле
	 * @param {KeyboardEvent} event Передаваемое событие
	 */
	onKeyDownHandler(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	/**
	 * Обработчик события отжатия клавиши в текстовом поле
	 * @param {KeyboardEvent} event Передаваемое событие
	 */
	onKeyUpHandler(event: KeyboardEvent): void {
		if (event.key === 'Enter') {
			event.preventDefault();
		}
	}

	/**
	 * При потере фокуса восстановить значение поля ввода.
	 *
	 * @param {FocusEvent} event
	 */
	onBlurInputHandler(event: FocusEvent): void {
		(event.target as HTMLInputElement).value = `${isNaN(this.currentValue) ? 0 : this.currentValue}`;
	}

	/**
	 * Обработчик события вставки текста из буффера обмена
	 * @param {ClipboardEvent} event Передаваемое событие
	 */
	onPasteHandler(event: ClipboardEvent): void {
		let newValue: number = Number.parseInt(event.clipboardData.getData('text'), 10);
		if (isNaN(newValue)) {
			newValue = 0;
		}
		// TODO доделать
		event.preventDefault();
	}

	/**
	 * Слушатель клика на кнопке смены активной позиции.
	 *
	 * @param {number} diff Смещение
	 */
	onArrowButtonClickedHandler(diff: number): void {
		this.moveSelectorPosition(diff);
	}

	/**
	 * Слушатель прокрутки колеса мышки над компонентом.
	 *
	 * @param {WheelEvent} event Передаваемое событие
	 */
	onWheelHandler(event: WheelEvent): void {
		event.preventDefault();
		this.moveSelectorPosition(event.deltaY > 0 ? 1 : -1);
	}

	/**
	 * Слушатель свайпа над компонентом.
	 *
	 * @param event Передаваемое событие
	 */
	onSwipeHandler(event): void {
		this.moveSelectorPosition(event.offsetDirection === 2 ? 4 : -4);
	}

	/**
	 * Слушатель клика по кнопке "+" или "-".
	 *
	 * @param {PlusMinusIconType} iconType Тип иконки: "+" или "-"
	 */
	onPlusMinusButtonClickedHandler(iconType: PlusMinusIconType): void {
		const newValue = this.currentValue + (iconType === PlusMinusIconType.Plus ? this.isInputButtonsDiff : -this.isInputButtonsDiff);
		this.minusDisabled = newValue === this.minValue;
		this.plusDisabled = newValue === this.maxValue;
		if (newValue < this.minValue || (!isNaN(this.maxValue) && newValue > this.maxValue)) {
			return;
		}

		this.manualValue.emit(newValue);
	}

	// -----------------------------
	//  Lifecycle functions
	// -----------------------------

	/**
	 * Обработчик события инициализации компонента
	 */
	ngOnInit(): void {
		this.minusDisabled = this.currentValue === this.minValue;
		this.plusDisabled = this.currentValue === this.maxValue;
	}

	/**
	 * Обработчик событий любых изменений в компоненте
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if (Boolean(changes.currentValue)) {
			this.selectors = this.selectors.map((m: ISelectorItem) => ({
				...m,
				selected: m.value === this.currentValue
			}));
			this.updateCurrentPositionByNewSelectors();
		}

		if (Boolean(changes.selectors)) {
			this.updateCurrentPositionByNewSelectors();
		}
	}

	// -----------------------------
	//  Private functions
	// -----------------------------

	/**
	 * Перейти к указанной позиции, задаваемой как сумма текущей позиции плюс смещение.
	 *
	 * @param {number} offset Смещение от текущей позиции.
	 */
	private moveSelectorPosition(offset: number): void {
		const maxPos: number = this.maxPosition;
		if (this.currentPosition + offset < 0) {
			this.currentPosition = 0;
		} else if (this.currentPosition + offset > maxPos) {
			this.currentPosition = maxPos;
		} else {
			this.currentPosition += offset;
		}
	}

	/**
	 * Обновить текущую позицию селектора на основании выбранной суммы.
	 */
	private updateCurrentPositionByNewSelectors(): void {
		if (Array.isArray(this.selectors) && this.selectors.length > this.fullWidthCount) {
			const selected: ISelectorItem = this.selectors.find(f => f.selected);
			const selectedPosition: number = !!selected
				? this.selectors.indexOf(selected)
				: this.nearestSelectedPosition;

			const maxPos: number = this.maxPosition;
			let newPosition = selectedPosition - Math.ceil(this.fullWidthCount / 2);
			if (newPosition < 0) {
				newPosition = 0;
			}
			if (newPosition > maxPos) {
				newPosition = maxPos;
			}
			this.currentPosition = newPosition;
		} else {
			this.currentPosition = 0;
		}
	}

	/**
	 * Получить наибольшую позицию селектора
	 * @private
	 */
	private get maxPosition(): number {
		return this.isVisibleArrows
			? this.selectors.length - this.fullWidthCount
			: this.selectors.length - this.fullWidthCount - 1;
	}

	/**
	 * Найти ближайшую позицию к выбранному значению (вручную).
	 *
	 * @returns {number}
	 */
	private get nearestSelectedPosition(): number {
		let result = 0;
		for (let i = 0; i < this.selectors.length - 1; i++) {
			if (this.currentValue > this.selectors[i].value && this.currentValue < this.selectors[i + 1].value) {
				result = i;
				break;
			}
		}

		return result;
	}

}
