import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Logger } from '@app/core/services/log/log.service';
import { StoreService } from '@app/core/services/store/store.service';
import { environment } from '@app/environments/environment';
import { ModuleRouteId } from '@app/utils/route-utils';
import { ANDROID, EmptyAndroid, IAndroid, MobileAction } from '@app/utils/utils';
import { GameDialogsService } from '@dialogs/services/game-dialogs.service';

/**
 * Тег для логирования
 */
const TAG = 'MobileService';

/**
 * Сервис по взаимодействию с мобильным приложением
 */
@Injectable({
	providedIn: 'root'
})
export class MobileService {

	// -----------------------------
	//  Public functions
	// -----------------------------

	/**
	 * Конструктор сервиса
	 *
	 * @param {StoreService} storeService Сервис-хранилище приложения
	 * @param {GameDialogsService} gameDialogsService Сервис диалоговых окон
	 */
	constructor(
		private readonly storeService: StoreService,
		private readonly gameDialogsService: GameDialogsService
	) {}

	/**
	 * Метод для получения токена авторизации из МП
	 */
	getAuthToken(): string | undefined {
		Logger.Log.i(TAG, `getAuthToken => ${MobileAction.GetAuthToken}`)
			.console();

		return this.checkAndroid()
			.getData(MobileAction.GetAuthToken);
	}

	/**
	 * Метод для показа окна входа в мобильном приложении
	 */
	refreshAuthToken(): void {
		Logger.Log.i(TAG, `refreshAuthToken => ${MobileAction.RefreshAuthToken}`)
			.console();

		this.checkAndroid()
			.doAction(MobileAction.RefreshAuthToken, '');
	}

	/**
	 * Метод, указывающий МП, что в веб-приложении перешли на другой маршрут
	 * @param moduleIdFrom С какого маршрута
	 * @param moduleIdTo На какой
	 */
	changeLocation(moduleIdFrom: ModuleRouteId, moduleIdTo: ModuleRouteId): void {
		const data = JSON.stringify({
			from: moduleIdFrom,
			to: moduleIdTo
		});
		Logger.Log.i(TAG, `changeLocation => ${MobileAction.ChangeLocation}, data: ${data}`)
			.console();

		this.checkAndroid(false)
			.doAction(MobileAction.ChangeLocation, data);
	}

	// -----------------------------
	//  Private functions
	// -----------------------------
	/**
	 * Проверка того, что работаем под ОС Андроид в МП
	 * @param showError Показывать ли ошибку?
	 * @private
	 */
	private checkAndroid(showError: boolean = true): IAndroid {
		if (!environment.isMobile) {
			return EmptyAndroid;
		}

		if (!ANDROID) {
			if (showError) {
				this.showError();
			}

			return EmptyAndroid;
		}

		return ANDROID;
	}

	/**
	 * Показывает ошибку отсутствия доступа к андроид-приложению
	 * @private
	 */
	private showError(): void {
		Logger.Log.w(TAG, `showError => can't get access to ANDROID application`)
			.console();

		this.gameDialogsService.showInfoDialog('Can\'t get access to ANDROID application')
			.pipe(take(1))
			.subscribe();
	}

}
