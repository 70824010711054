import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Компонент надписи для компонента ссылок на правила и условия игры
 */
@Component({
	selector: 'app-info-label',
	templateUrl: './info-label.component.html',
	styleUrls: ['./info-label.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class InfoLabelComponent {

	// -----------------------------
	//  Input properties
	// -----------------------------

	/**
	 * Текст метки.
	 */
	@Input()
	infoText: string;

	/**
	 * Ссылка на иконку.
	 */
	@Input()
	iconUrl: string;

	/**
	 * Признак видимости иконки.
	 */
	@Input()
	isVisibleIcons = true;
}
