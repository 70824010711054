/**
 * Список кодов ошибок:
 * - {@link Ok} - выполнено без ошибок
 * - {@link Undefined} - неопределенная ошибка, что-то в коде пошло не так
 * - {@link SID_Required} - отсутствует параметр SID (токен сайта)
 * - {@link CantFindPromos} - акция неактивна или отсутствует
 * - {@link SID_OutOfDate} - сессия устарела
 * - {@link SID_SessionError}
 * - {@link SessionByTokenNotFound} - сайт отвечает что авторизационный токен неверен
 * - {@link UserHasOneTicket} - у игрока уже есть активный билет
 * - {@link TicketStatusNonOperational} - билет имеет "вне игровой" статус, т.е не "куплен" или не "в розыгрыше"
 * - {@link AllStepsAreExhausted} - все шаги по билету исчерпаны
 * - {@link NoEnoughMoney} - недостаточно денег для совершения покупки билета
 * - {@link BigWin} - превышение допустимого лимита по выигрышу
 * - {@link MaxBetLimit} - превышена допустимая сумма ставок
 * - {@link MaxLoseLimit} - превышена допустимая сумма проигрыша
 * - {@link MaxFillSumLimit} - превышена доступная сумма для пополнения за период
 *
 * @see https://confluence.emict.net/pages/viewpage.action?pageId=208371788
 */
export enum ResultCode {
	Ok					        = 0,

	Undefined                   = 1000,

	SID_Required		        = 1001,
	CantFindPromos				= 1110,
	SID_OutOfDate		        = 4313,
	SID_SessionError	        = 4390,

	SessionByTokenNotFound      = 1007,
	UserHasOneTicket            = 1063,
	TicketStatusNonOperational  = 1201,
	AllStepsAreExhausted        = 1202,

	NoEnoughMoney	    	    = 4347,
	BigWin      		        = 4348,

	MaxBetLimit 				= 4377,
	MaxLoseLimit 				= 4378,
	MaxFillSumLimit				= 4389
}
