/**
 * Mock-серии игры "Яркие шары"
 */
export const COLOR_SERIES = [
	{
		"code": 901,
		"letters": "А",
		"default": true,
		"params": "{\"w\":10,\"h\":1,\"m\":[0,4,3,2,1],\"o\":[1,2,3,4],\"t\":0}",
		"koefs": [
			"1.75",
			"5.25",
			"21.00",
			"147.00"
		],
		"is_demo": false
	},
	{
		"code": 902,
		"letters": "Б",
		"default": false,
		"params": "{\"w\":12,\"h\":1,\"m\":[2,4,3,2,1],\"o\":[1,2,3,4],\"t\":0}",
		"koefs": [
			"1.40",
			"3.49",
			"11.67",
			"62.25"
		],
		"is_demo": false
	},
	{
		"code": 903,
		"letters": "В",
		"default": false,
		"params": "{\"w\":14,\"h\":1,\"m\":[4,4,3,2,1],\"o\":[1,2,3,4],\"t\":0}",
		"koefs": [
			"1.23",
			"2.64",
			"7.41",
			"31.28"
		],
		"is_demo": false
	}
];
