/**
 * Состояние сделанного шага:
 * - {@link Lose} - проигрыш
 * - {@link Win} - ячейка выигрышная, можно продолжать играть
 * - {@link EndOfGame} - конец игры (закончились ходы или достигли максимальной суммы выигрыша)
 * - {@link BigWin} - крупный выигрыш
 */
export enum NextGameStepState {
	Lose = 0,
	Win = 1,
	EndOfGame = 2,
	BigWin = 3,
	UserStopRequest = 4
}
