import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { SharedModule } from '@app/shared/shared.module';
import { GameListShortModule } from '@gameListShort/game-list-short.module';
import { InfoLinksModule } from '@infoLinks/info-links.module';

import { MenuHamburgerComponent } from '@hamburger/components/menu-hamburger/menu-hamburger.component';
import { MenuHamburgerService } from '@hamburger/services/menu-hamburger.service';

/**
 * Модуль меню-гамбургер.
 */
@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        InfoLinksModule,
        TranslateModule,
        GameListShortModule
    ],
    exports: [],
    declarations: [
        MenuHamburgerComponent
    ],
    providers: [
        MenuHamburgerService
    ]
})
export class MenuHamburgerModule {}
