import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { TopPanelComponent } from '@topPanel/components/top-panel/top-panel.component';

@NgModule({
	imports: [
		CommonModule,
		SharedModule
	],
	exports: [
		TopPanelComponent
	],
	declarations: [
		TopPanelComponent
	]
})
export class TopPanelModule {}
