import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';

import { InfoLabelComponent } from '@infoLinks/components/info-label/info-label.component';
import { InfoLinksComponent } from '@infoLinks/components/info-links/info-links.component';

/**
 * Модуль для отображения различных ссылок на информационные ресурсы.
 */
@NgModule({
	imports: [
		CommonModule,
		SharedModule
	],
	exports: [
		InfoLinksComponent
	],
	declarations: [
		InfoLinksComponent,
		InfoLabelComponent
	]
})
export class InfoLinksModule {}
